import React from "react";
import $ from 'jquery'
// import {
//   Route,
//   Switch,
//   useHistory,
//   withRouter,
// } from "react-router-dom";
import SlideRoutes from 'react-slide-routes';
import { Route, NavLink, useLocation } from 'react-router-dom';
import { TransitionGroup } from "react-transition-group";
// import Push from "./push";
// import Pop from "./pop";
import { CSSTransition } from "react-transition-group";
import { Routing, Routing2 } from "../Utils";
import NotFoundPage from "../Scenes/Components/NotFoundPage";
import HomeScreen from '../Scenes/Screens/Home';
import { useSelector } from "react-redux";

const Dashboard = () => {
  const location = useLocation();
  const slideDirection = useSelector((state)=> state.commonReducer.direction)
  return(
    <>
      <SlideRoutes location={location} animation={slideDirection==='vertical-slide'?'vertical-slide':'slide'} duration={500}  >
        {[...Routing, Routing2].map((route,i) => (
          <Route  key={i}  path={route.path} element={route.component} />
        ))}
        <Route component={NotFoundPage} />
        </SlideRoutes>

        {/* <SlideRoutes >
        {[...Routing2, Routing2].map((route,i) => (
          <Route  key={i}  path={route.path} element={route.component} />
        ))}
        <Route component={NotFoundPage} />
        </SlideRoutes> */}

</>
)};
export default Dashboard;

