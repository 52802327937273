import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { logoWhtOrdrr, nextArrow, calendarSmall, timeIcon } from "../../Assets";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";

import Header from "../Components/Header";
import DividerCom from "../Components/DividerCom";
import SlideDrawer from "../Components/SlideDrawer";
import Chat from "./Chat";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { loadingToggleStatus } from "../../Redux/actions";
import io from "socket.io-client";
import { actions } from "../../Redux";
import { connect } from "../../services/socket";
import SocketIOClient from "socket.io-client";

// export const socket = socketio.connect('https://uatsocket.gogmgo.com:2020');

const Help = () => {
  const socketRef = SocketIOClient(`https://uatsocket.gogmgo.com:5000`, {
    reconnectionDelay: 1000,
    reconnection: true,
    jsonp: false,
    transports: ["websocket"],
  });
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    document.body.classList.add("white");
    dispatch(loadingToggleStatus(true));
    setTimeout(() => {
      dispatch(loadingToggleStatus(false));
    }, 1000);
  }, []);

  const { orderDetails } = useSelector((state) => state.cartReducer);
  const { chatSession } = useSelector((state) => state.chatReducer);
  const { whereIsMyOrder } = useSelector((state) => state.paymentReducer);
  const { storeDetails, outletDetails } = useSelector(
    (state) => state.storeReducer
  );

  const { pos_order_id } = orderDetails?.order ?? {};

  const [socket, setSocket] = useState(false);

  useEffect(() => {
    console.log("socket", socket);
    socket && setShowInnerChatDrawer(true);
  }, [socket]);

  useEffect(() => {
    chatSession && toggleChatDrawer(true);
    // eslint-disable-next-line no-use-before-define
  }, [chatSession, toggleChatDrawer]);

  const [showCancelDrawer, setShowCancelDrawer] = useState(false);
  const [showOrderDrawer, setShowOrderDrawer] = useState(false);
  const [showChatDrawer, setShowChatDrawer] = useState(false);
  const [showInnerChatDrawer, setShowInnerChatDrawer] = useState(false);

  const toggleCancelDrawer = useCallback((value) => {
    setShowCancelDrawer(value);
  }, []);

  const toggleOrderDrawer = useCallback((value) => {
    if (value) {
      dispatch(
        actions.whereIsMyOrder({
          pos_order_id,
          order_offline_id: orderDetails.order.order_offline_id,
        })
      );
    }
    setShowOrderDrawer(value);
  }, []);

  const toggleChatDrawer = useCallback((value) => {
    setShowChatDrawer(value);
    if (value) {
      setTimeout(() => {
        setShowInnerChatDrawer(true);
      }, 3000);
    } else setShowInnerChatDrawer(false);
  }, []);

  const btnStyle = {
    minWidth: "35px",
    justifyContent: "end",
    borderRadius: "40px",
    transform: "rotate(360deg)",
  };

  const listItemStyle = {
    paddingTop: "13px",
    paddingBottom: "13px",
    cursor: "pointer",
  };

  const cancelScreen = () => (
    <SlideDrawer
      showDrawer={showCancelDrawer}
      toggleDrawer={toggleCancelDrawer}
    >
      <div className="wrapper colorGreyBg confirm-width">
        <Header />

        <section className="user-section Help row mx-0">
          <div className="col-12">
            <h1 className="title">Cancel Order</h1>
          </div>
          <div className="col-12 px-0">
            <List>
              <ListItem
                sx={listItemStyle}
                onClick={() => {
                  // toggleCancelDrawer(false);
                  // toggleChatDrawer(true);
                  dispatch(
                    actions.startChatSession({
                      order_offline_id: orderDetails.order.order_offline_id,
                    })
                  );
                }}
              >
                <ListItemText primary={"Chat with support"} />
                <ListItemIcon>
                  <Button sx={btnStyle}>
                    <figure style={{ transform: "rotate(180deg)" }}>
                      <span
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                        className="icon-Component-1--7"
                      ></span>
                    </figure>
                  </Button>
                </ListItemIcon>
              </ListItem>
            </List>
            <DividerCom />
            <List>
              <ListItem
                sx={listItemStyle}
                onClick={() => {
                  toggleCancelDrawer(false);
                }}
              >
                <ListItemText primary={"Nevermind, back to live updates"} />
                <ListItemIcon>
                  <Button sx={btnStyle}>
                    <figure style={{ transform: "rotate(180deg)" }}>
                      <span
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                        className="icon-Component-1--7"
                      ></span>
                    </figure>
                  </Button>
                </ListItemIcon>
              </ListItem>
            </List>
          </div>
        </section>
      </div>
    </SlideDrawer>
  );

  const orderScreen = () => (
    <SlideDrawer showDrawer={showOrderDrawer} toggleDrawer={toggleOrderDrawer}>
      <div className="wrapper colorGreyBg confirm-width">
        <Header />

        <section className="user-section Help orderScreen row mx-0">
          <div className="col-12">
            <h1 className="title">Where's my order?</h1>
            <p className="discription">
              {whereIsMyOrder?.message}
              {/* Your order is being prepared by restaurant but it talking slightly
              longer than expected. */}
            </p>
          </div>
          <div className="col-12 px-0">
            <List>
              <ListItem
                sx={listItemStyle}
                onClick={() => {
                  toggleOrderDrawer(false);
                }}
              >
                <ListItemText primary={"Back to live updates"} />
                <ListItemIcon>
                  <Button sx={btnStyle}>
                    <figure style={{ transform: "rotate(180deg)" }}>
                      <span
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                        className="icon-Component-1--7"
                      ></span>
                    </figure>
                  </Button>
                </ListItemIcon>
              </ListItem>
            </List>
          </div>
        </section>
      </div>
    </SlideDrawer>
  );

  const chatScreen = () => (
    <SlideDrawer showDrawer={showChatDrawer} toggleDrawer={toggleChatDrawer}>
      {showInnerChatDrawer ? (
        <Chat
          socketRef={socketRef}
          toggleChatDrawer={toggleChatDrawer}
          order_offline_id={orderDetails.order.order_offline_id}
        />
      ) : (
        <div className="wrapper colorGreyBg confirm-width">
          <Header />

          <section className="user-section Help orderScreen row mx-0">
            <div className="col-12 wht-bg pt-0">
              <h1 className="title">Support Chat</h1>
              <p className="discription">
                Please wait while we connect you with our support staff.
              </p>
            </div>
            <div className="col-12 wht-bg px-0">
              <List>
                <ListItem
                  sx={listItemStyle}
                  onClick={() => {
                    toggleChatDrawer(false);
                  }}
                >
                  <ListItemText primary={"Back to live updates"} />
                  <ListItemIcon>
                    <Button sx={btnStyle}>
                      <figure style={{ transform: "rotate(180deg)" }}>
                        <span
                          style={{
                            color:
                              outletDetails?.settings?.brand_highlight_color,
                          }}
                          className="icon-Component-1--7"
                        ></span>
                      </figure>
                    </Button>
                  </ListItemIcon>
                </ListItem>
              </List>
            </div>
          </section>
        </div>
      )}
    </SlideDrawer>
  );

  return (
    <>
      {cancelScreen()}
      {orderScreen()}
      {chatScreen()}
      <div className="wrapper colorGreyBg confirm-width">
        <Header />

        <section className="user-section Help row mx-0">
          <div className="col-12">
            <h1 className="title">Order help</h1>
          </div>
          <div className="col-12 px-0">
            <List>
              <ListItem
                sx={listItemStyle}
                onClick={() => {
                  toggleCancelDrawer(true);
                }}
              >
                <ListItemText primary={"Cancel Order"} />
                <ListItemIcon>
                  <Button sx={btnStyle}>
                    <figure style={{ transform: "rotate(180deg)" }}>
                      <span
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                        className="icon-Component-1--7"
                      ></span>
                    </figure>
                  </Button>
                </ListItemIcon>
              </ListItem>
            </List>
            <DividerCom />
            <List>
              <ListItem
                sx={listItemStyle}
                onClick={() => {
                  toggleOrderDrawer(true);
                }}
              >
                <ListItemText primary={"Where is my order?"} />
                <ListItemIcon>
                  <Button sx={btnStyle}>
                    <figure style={{ transform: "rotate(180deg)" }}>
                      <span
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                        className="icon-Component-1--7"
                      ></span>
                    </figure>
                  </Button>
                </ListItemIcon>
              </ListItem>
            </List>
            <DividerCom />
            <List>
              <ListItem
                sx={listItemStyle}
                onClick={() => {
                  navigate(-1);
                }}
              >
                <ListItemText primary={"Back to live updates"} />
                <ListItemIcon>
                  <Button sx={btnStyle}>
                    <figure style={{ transform: "rotate(180deg)" }}>
                      <span
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                        className="icon-Component-1--7"
                      ></span>
                    </figure>
                  </Button>
                </ListItemIcon>
              </ListItem>
            </List>
          </div>
        </section>
      </div>
    </>
  );
};
export default Help;
