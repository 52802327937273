export const LOADING_STATUS = "LOADING_STATUS";
export const APPLY_VOUCHER_DETAIL_RECEIVED = "APPLY_VOUCHER_DETAIL_RECEIVED";
export const TOGGLE_CART_PAGE = "TOGGLE_CART_PAGE";
export const START_LOADING = "START_LOADING";
export const STOP_LOADING = "STOP_LOADING";
export const OUTLET_MODAL = "OUTLET_MODAL";
export const SAVE_SELECTED_STORE_DATA_SUCCEEDED =
  "SAVE_SELECTED_STORE_DATA_SUCCEEDED";
export const TOGGLE_DISABLE_BTN =
  "TOGGLE_DISABLE_BTN";
export const TOGGLE_CONFIRM_BTN =
  "TOGGLE_CONFIRM_BTN";
export const SAVE_SELECTED_LOCATION = "SAVE_SELECTED_LOCATION";
export const DELIVERY_TYPE = "DELIVERY_TYPE";
export const CLEAR_DATA = "CLEAR_DATA";
export const SET_SHOW_MAP = "SET_SHOW_MAP";

export const SELECTED_ITEM = "SELECTED_ITEM";
export const EDIT_ITEM_DETAILS = "EDIT_ITEM_DETAILS";

export const UPDATE_PROMOTION_LIST = "UPDATE_PROMOTION_LIST";
export const ORDER_STATUS = "ORDER_STATUS";

// stores and delivery option
export const GET_LOCATIONS_REQUESTED = "GET_LOCATIONS_REQUESTED";
export const GET_LOCATIONS_SUCCEEDED = "GET_LOCATIONS_SUCCEEDED";
export const GET_LOCATIONS_FAILED = "GET_LOCATIONS_FAILED";

export const ADD_LOCATIONS_REQUESTED = "ADD_LOCATIONS_REQUESTED";
export const ADD_LOCATIONS_SUCCEEDED = "ADD_LOCATIONS_SUCCEEDED";
export const ADD_LOCATIONS_FAILED = "ADD_LOCATIONS_FAILED";

// Geocoding
export const GEOCOADING_REQUESTED = "GEOCOADING_REQUESTED";
export const GEOCOADING_SUCCEEDED = "GEOCOADING_SUCCEEDED";
export const GEOCOADING_FAILED = "GEOCOADING_FAILED";

export const GET_STORES_REQUESTED = "GET_STORES_REQUESTED";
export const GET_STORES_SUCCEEDED = "GET_STORES_SUCCEEDED";
export const GET_STORES_FAILED = "GET_STORES_FAILED";

export const GET_MENU_ITEMS_REQUESTED = "GET_MENU_ITEMS_REQUESTED";
export const GET_MENU_ITEMS_SUCCEEDED = "GET_MENU_ITEMS_SUCCEEDED";
export const GET_MENU_ITEMS_FAILED = "GET_MENU_ITEMS_FAILED";
export const GET_TAGS_REQUESTED = "GET_TAGS_REQUESTED";
export const GET_TAGS_SUCCEEDED = "GET_TAGS_SUCCEEDED";
export const GET_TAGS_FAILED = "GET_TAGS_FAILED";
// Outlet
export const GET_OUTLET_DETAILS_REQUESTED = "GET_OUTLET_DETAILS_REQUESTED";
export const GET_OUTLET_DETAILS_SUCCEEDED = "GET_OUTLET_DETAILS_SUCCEEDED";
export const GET_OUTLET_DETAILS_FAILED = "GET_OUTLET_DETAILS_FAILED";

export const GET_STORE_DETAILS_REQUESTED = "GET_STORE_DETAILS_REQUESTED";
export const GET_STORE_DETAILS_SUCCEEDED = "GET_STORE_DETAILS_SUCCEEDED";
export const GET_STORE_DETAILS_FAILED = "GET_STORE_DETAILS_FAILED";
export const ADD_SEARCH_TEXT = "ADD_SEARCH_TEXT";
export const ADD_FILTER_TAG = "ADD_FILTER_TAG";
export const FILTER_TAG_REQUESTED = "FILTER_TAG_REQUESTED";
export const FILTER_TAG_SUCCEEDED = "FILTER_TAG_SUCCEEDED";
export const FILTER_TAG_FAILED = "FILTER_TAG_FAILED";

// Cart
export const ADD_CART_ITEM = "ADD_CART_ITEM";
export const UPDATE_CART_ITEM = "UPDATE_CART_ITEM";
export const REMOVE_CART_ITEM = "REMOVE_CART_ITEM";
export const FINAL_CART_SAVE = "FINAL_CART_SAVE";

// Orders
export const PLACE_ORDER_REQUESTED = "PLACE_ORDER_REQUESTED";
export const PLACE_ORDER_SUCCEEDED = "PLACE_ORDER_SUCCEEDED";
export const PLACE_ORDER_FAILED = "PLACE_ORDER_FAILED";

// Payment
export const PAYMENT_INTENT_REQUESTED = "PAYMENT_INTENT_REQUESTED";
export const PAYMENT_INTENT_SUCCEEDED = "PAYMENT_INTENT_SUCCEEDED";
export const PAYMENT_INTENT_FAILED = "PAYMENT_INTENT_FAILED";

export const PAYMENT_TOKEN_REQUESTED = "PAYMENT_TOKEN_REQUESTED";
export const PAYMENT_TOKEN_SUCCEEDED = "PAYMENT_TOKEN_SUCCEEDED";
export const PAYMENT_TOKEN_FAILED = "PAYMENT_TOKEN_FAILED";

export const CONFIRM_PAYMENT_REQUESTED = "CONFIRM_PAYMENT_REQUESTED";
export const CONFIRM_PAYMENT_SUCCEEDED = "CONFIRM_PAYMENT_SUCCEEDED";
export const CONFIRM_PAYMENT_FAILED = "CONFIRM_PAYMENT_FAILED";

export const WHERE_IS_MY_ORDER_REQUESTED = "WHERE_IS_MY_ORDER_REQUESTED";
export const WHERE_IS_MY_ORDER_SUCCEEDED = "WHERE_IS_MY_ORDER_SUCCEEDED";
export const WHERE_IS_MY_ORDER_FAILED = "WHERE_IS_MY_ORDER_FAILED";

export const CHECK_QUANTITY_REQUESTED = "CHECK_QUANTITY_REQUESTED";
export const CHECK_QUANTITY_SUCCEEDED = "CHECK_QUANTITY_SUCCEEDED";
export const CHECK_QUANTITY_FAILED = "CHECK_QUANTITY_FAILED";
export const CHECK_QUANTITY_MODAL_CLOSE = "CHECK_QUANTITY_MODAL_CLOSE";

//Track order
export const TRACK_ORDER_DETAILS_REQUESTED = "TRACK_ORDER_DETAILS_REQUESTED";
export const TRACK_ORDER_DETAILS_SUCCEEDED = "TRACK_ORDER_DETAILS_SUCCEEDED";
export const TRACK_ORDER_DETAILS_FAILED = "TRACK_ORDER_DETAILS_FAILED";

//Chat
export const START_CHAT_SESSION_REQUESTED = "START_CHAT_SESSION_REQUESTED";
export const START_CHAT_SESSION_SUCCEEDED = "START_CHAT_SESSION_SUCCEEDED";
export const START_CHAT_SESSION_FAILED = "START_CHAT_SESSION_FAILED";

export const STORE_MESSAGE = "STORE_MESSAGE";

// BY ABDUS
export const UPDATE_USER_TIP = "UPDATE_USER_TIP";
export const UPDATE_USER_DISCOUNT = "UPDATE_USER_DISCOUNT";
export const EDIT_ITEM_QUANTITY = "EDIT_ITEM_QUANTITY";
export const REPLACE_CART_ITEM = "REPLACE_CART_ITEM";
export const UPDATE_BASIC_CART_DETAILS = "UPDATE_BASIC_CART_DETAILS";
export const GET_QUOTATIONS = "GET_QUOTATIONS";
export const GET_QUOTATIONS_SUCCESS = "GET_QUOTATIONS_SUCCESS";
export const GET_QUOTATIONS_INITIAL = "GET_QUOTATIONS_INITIAL";
export const ADD_QUOTATION_ID = "ADD_QUOTATION_ID";
export const SET_HIDE_MAP = "SET_HIDE_MAP";
export const SLIDE_DIRECTION = "SLIDE_DIRECTION";
export const RIDER_NOTE = "RIDER_NOTE";
export const DELIVERY_TIME = "DELIVERY_TIME";
export const DELIVERY_DATE = "DELIVERY_DATE";
export const UPDATE_ADDRESS = "UPDATE_ADDRESS";
export const SET_ADDRESS_ERROR = "SET_ADDRESS_ERROR";
export const NO_OUTLET_MODAL = "NO_OUTLET_MODAL";
// export const SET_DEFAULT_COLORS = "SET_DEFAULT_COLORS"
