import { toast } from 'react-toastify';
import { call, put, takeLatest } from 'redux-saga/effects';
import { quotationsServices } from '../../services';
import * as actionTypes from '../actionTypes';


function* getQuotations(action){
    const {data} = yield call(quotationsServices.getQuotations, action.payload)
    console.log('====================================');
    console.log('quotationSaga',data);
    console.log('====================================');
    if(data.status){
        toast.success(data.message);
        yield put({
            type: actionTypes.GET_QUOTATIONS_SUCCESS,
            payload: data.result.data
        })
        yield put({
            type: actionTypes.ADD_QUOTATION_ID,
            payload: data.result.data.quotationId

        })
    }
}



function* quotationSaga() {
yield takeLatest(actionTypes.GET_QUOTATIONS, getQuotations)
}

export default quotationSaga