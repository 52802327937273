import { toast } from "react-toastify";
import {
  GET_PDF_MENU,
  EMPTY_TOAST_MSG,
  TOAST_MSG,
  HIDE_TOAST_MSG,
  SHOW_NOTIFICATION_WITH_TIMEOUT,
  GET_LOGIN,
  GET_TABLE_DETAIL,
  LOADING_STATUS,
  SELECTED_ITEM_DETAIL_RECEIVED,
  ADD_ITEM_CART_DETAIL,
  UPDATE_ITEM_CART_DETAIL,
  REMOVE_ITEM_CART_DETAIL,
  CART_DETAIL_RECEIVED,
  EMPTY_CART_DETAIL,
  EMPTY_ORDER_PLACE_DETAIL,
  DELETE_RECENT_AND_CART_ORDER,
  CART_TAX_DETAIL_RECEIVED,
  EMPTY_CART_TAX_DETAIL,
  ORDER_PLACE_DETAIL,
  CHANGE_ORDER_SOCKET_EMIT,
  ADD_FILTER_TAG,
  EDITED_ITEM_DETAIL_RECEIVED,
  EMPTY_EDITED_ITEM_DETAIL,
  CHANGE_TABLE_NOT_FOUND_MODAL,
  CHANGE_TABLE_ALREADY_INUSE_MODAL,
} from "./types";

export const getLogin = () => ({
  type: GET_LOGIN,
});

// pdf menu

export const getPdfMenu = (id) => {
  return { type: GET_PDF_MENU, id };
};

// Loading
export const loadingToggleStatus = (status) => ({
  type: LOADING_STATUS,
  status,
});

// Notifications
export const emptyToastMsg = () => ({
  type: EMPTY_TOAST_MSG,
});

export const showNotification = (id, text, msgtype) => {
  return { type: TOAST_MSG, id, text, msgtype };
};

export const hideNotification = (id) => {
  return { type: HIDE_TOAST_MSG, id };
};

export const showNotificationWithTimeout = (text, msgtype) => {
  if (msgtype == "success") {
    toast.success(text);
  } else if (msgtype == "error") {
    toast.error(text);
  } else {
    toast(text);
  }
  return { type: SHOW_NOTIFICATION_WITH_TIMEOUT, text, msgtype };
};

// Table Detail

export const getTableDetail = (id, deviceId) => ({
  type: GET_TABLE_DETAIL,
  id,
  deviceId,
});

export const getSelectedItemDetail = (item) => ({
  type: SELECTED_ITEM_DETAIL_RECEIVED,
  payload: item,
});

export const getTableDetailByFilter = (table_id, ids, input) => ({
  type: ADD_FILTER_TAG,
  table_id,
  ids,
  input,
});
export const getEditedItemDetail = (item) => ({
  type: EDITED_ITEM_DETAIL_RECEIVED,
  payload: item,
});

export const emptyEditedItemDetail = (item) => ({
  type: EMPTY_EDITED_ITEM_DETAIL,
});

export const emptyChangeTableNotFound = () => ({
  type: CHANGE_TABLE_NOT_FOUND_MODAL,
  payload: false,
});
export const emptyChangeTableAlreadyInuse = () => ({
  type: CHANGE_TABLE_ALREADY_INUSE_MODAL,
  payload: false,
});
export const changeTableNotFound = () => ({
  type: CHANGE_TABLE_NOT_FOUND_MODAL,
  payload: true,
});

// Cart Detail
export const addItemCartDetail = (item) => ({
  type: ADD_ITEM_CART_DETAIL,
  item,
});
export const updateItemCartDetail = (index, item) => ({
  type: UPDATE_ITEM_CART_DETAIL,
  index,
  item,
});
export const removeItemCartDetail = (index) => ({
  type: REMOVE_ITEM_CART_DETAIL,
  index,
});

export const changeStatusCartDetail = (cartDetail) => ({
  type: CART_DETAIL_RECEIVED,
  cart_detail: cartDetail,
});

export const emptyCartDetail = () => ({
  type: EMPTY_CART_DETAIL,
});

export const emptyOrderPlaceDetail = () => ({
  type: EMPTY_ORDER_PLACE_DETAIL,
});
export const deletRecentAndCartOrder = () => ({
  type: DELETE_RECENT_AND_CART_ORDER,
});

export const saveCartTaxDetail = (
  totalCartAmount,
  totalServiceCharge,
  totalSaleTax,
  totalDiscount,
  totalTip
) => ({
  type: CART_TAX_DETAIL_RECEIVED,
  total_cart_amount: totalCartAmount,
  total_service_charge: totalServiceCharge,
  total_sale_tax: totalSaleTax,
  total_discount: totalDiscount,
  total_tip: totalTip,
});

export const emptyCartTaxDetail = () => ({
  type: EMPTY_CART_TAX_DETAIL,
});

export const orderPlaceDetail = (body) => ({
  type: ORDER_PLACE_DETAIL,
  body: body,
});

export const changeOrderSocketEmit = (status) => ({
  type: CHANGE_ORDER_SOCKET_EMIT,
  payload: status,
});
