import React, { Suspense, useEffect } from "react";

// Components
// Screens
// CSS
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./CSS/style.css";
import "./CSS/responsive.css";

import Main from "./Routing";
import { Provider } from "react-redux";
import { store, persistor } from "./Redux/store";
import { DBConfig } from "./indexed-db/config";
import { PersistGate } from "redux-persist/integration/react";
import { useClearCache } from "react-clear-cache";

function App() {
  const { isLatestVersion, emptyCacheStorage } = useClearCache();

  const checkLatestVersion = () => {
    console.log("isLatestVersion:", isLatestVersion);
    if (!isLatestVersion) {
      emptyCacheStorage();
    }
  };

  useEffect(() => {
    checkLatestVersion();
  }, []);

  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <meta name="viewport" content="initial-scale=1, maximum-scale=1" />
        <Main />
      </PersistGate>
    </Provider>
  );
}

export default App;
