import { toast } from "react-toastify";
import * as actionTypes from "../actionTypes";

const initialState = {
    "data": {
        "scheduleAt": "2022-05-11T14:30:00.00Z",
        "serviceType": "MOTORCYCLE",
        "specialRequests": [
            "PURCHASE_SERVICE_1"
        ],
        "language": "en_SG",
        "stops": [
            {
                "coordinates": {
                    "lat": "1.283320",
                    "lng": "103.836014"
                },
                "address": "Outram Rd, Singapore 169608"
            },
            {
                "coordinates": {
                    "lat": "1.278080",
                    "lng": "103.834940"
                },
                "address": "10 Hospital Blvd, OCH Level 4, Singapore 168582"
            }
        ],
        "item": {
            "quantity": "3",
            "weight": "LESS_THAN_3KG",
            "categories": [
                "FOOD_DELIVERY",
                "OFFICE_ITEM"
            ],
            "handlingInstructions": [
                "KEEP_UPRIGHT",
                "FRAGILE"
            ]
        },
        "isRouteOptimized": true,
        "cashOnDelivery": {
            "amount": "100"
        }
    }
}

 const quotationReducer = (state = initialState, action) => {
    const date = new Date();
    switch (action.type) {
        case actionTypes.GET_QUOTATIONS_INITIAL:
            console.log('====================================');
            console.log("time",date.getTime());
            console.log('====================================');
            return initialState
        case actionTypes.GET_QUOTATIONS_SUCCESS:
            console.log('====================================');
            console.log("time",date.getTime());
            console.log('====================================');
            return {
                ...action.payload, startTime: Math.floor( (new Date()).getTime() / 1000 )  
                 , endTime: Math.floor( (new Date()).getTime() / 1000 )   + 300 
              }

        default: return state
    }
}

export default quotationReducer