import { combineReducers } from "redux";
import loaderReducer from './loaderReducer'
import deliveryOptionsReducer from './deliveryOption'
import storeReducer from './store'
import cartReducer from './cart'
import paymentReducer from './payment'
import chatReducer from './chat'
import commonReducer from './common'
import quotationReducer from './quotationReducer'
const appReducer = combineReducers({
  loaderReducer,
  deliveryOptionsReducer,
  storeReducer,
  cartReducer,
  paymentReducer,
  chatReducer,
  commonReducer,
  quotationReducer
});

const rootReducer = (state, action) => {
  return appReducer(state, action);
};
export default rootReducer;
