import axios from "axios";
import { NOMINATIM_URL } from "../config";

export const getLatLngFromAddrs = (query = "") =>
  axios
    .get(NOMINATIM_URL + query + "&returnGeom=Y&getAddrDetails=Y")
    .catch((ex) => ({
      data: { message: "Some error occured, Please try after sometime." },
    }));

// eslint-disable-next-line import/no-anonymous-default-export
export default { getLatLngFromAddrs };
