import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
import FormControl from "@mui/material/FormControl";
let FloatingGroupInput = ({ labelTop, beforeColor, afterColor, ...props }) => {
  const labelInputTop = labelTop ?? "-20px";
  const beforeInputColor = beforeColor ?? "#ddd";
  const afterInputColor = afterColor ?? "#3e38a1";
  const CssTextField = {
    "& label": {
      top: "-10px",
      left: "15px",
    },
    "& label.Mui-focused": {
      color: "#333",
      top: labelInputTop,
      fontSize: 20,
    },
    "& label.MuiFormLabel-filled": {
      color: "#333",
      top: labelInputTop,
      fontSize: 20,
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${beforeInputColor}`,
    },
    "& .MuiInput-underline:after": {
      borderBottom: `1px solid ${beforeInputColor}`,
    },
    "& label.MuiFormLabel-filled ~ .row .MuiInput-underline:before": {
      borderBottom: `1px solid ${afterInputColor}`,
    },
    "& label.MuiFormLabel-filled ~ .row .MuiInput-underline:after": {
      borderBottom: `1px solid ${afterInputColor}`,
    },
  };
  return (
    <FormControl
      sx={CssTextField}
      {...props}
      variant="standard"
      autoComplete="new-password"
      fullWidth
    >
      {props.children}
    </FormControl>
  );
};
export default FloatingGroupInput;
