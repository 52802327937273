import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { commonServices } from "../../services";
import { startLoader, stopLoader } from "../actions";
import * as actionTypes from "../actionTypes";

function* getLatLngFromAddrs(action) {
  yield put(startLoader());
  console.log("getLatLngFromAddrs");
  const { data } = yield call(commonServices.getLatLngFromAddrs, action?.query);
  // const data = yield call(commonServices.getLatLngFromAddrs, action?.query);
  console.log(data);
  // return;
  yield put(stopLoader());
  if (data?.found) {
    yield put({
      type: actionTypes.GEOCOADING_SUCCEEDED,
      payload: {
        availableLocations: data,
        error: false,
      },
    });

    yield put({
      type: actionTypes.SET_SHOW_MAP,
      payload: {
        displayMap: true,
      },
    });
  } else {
    toast.error("Please provide valid address.");
    yield put({
      type: actionTypes.GEOCOADING_FAILED,
      payload: { error: true },
    });
  }
}

function* commonSaga() {
  yield takeLatest(actionTypes.GEOCOADING_REQUESTED, getLatLngFromAddrs);
}

export default commonSaga;
