export const BASE_URL = process.env.REACT_APP_API_URL;
export const BASE_URL_ORDRR = `${BASE_URL}`;
export const NOMINATIM_URL =
  "https://www.onemap.gov.sg/api/common/elastic/search?searchVal=";
 
export const API_URLS = {
  addUpdateLocations: "ordrr_online_apis/delivery_locations",
  getLocations: "ordrr_online_apis/get_delivery_locations",
  getStores: "ordrr_online_apis/get_outlets",
  getMenuItems: "ordrr_online_apis/get_menu_items",
  getOutletDetails: "ordrr_online_apis/get_outlet_details",
  getStoreDetails: "ordrr_online_apis/get_store_details",
  getFilterDetails: "qr_code_apis/get_tags",
  placeOrder: "ordrr_online_apis/submit_order",
  paymentIntent: "ordrr_online_apis/payment_intents",
  paymentToken: "ordrr_online_apis/payment_tokens",
  confirmPayment: "ordrr_online_apis/confirm_payment",
  trackStatus: "ordrr_online_apis/track_status",
  startChatSession: "ordrr_online_apis/start_chat_session",
  whereIsMyOrder: "ordrr_online_apis/where_ismy_order",
  quantityCheckedDetails: "ordrr_online_apis/get_menuitem_quotas",
  quotations: "lalamove_apis/quotations",
};
