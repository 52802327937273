import React, { useEffect, useState } from "react";

const ProgressBar = ({ endValue = 100, color = "#000000", ...props }) => {
  const [counter, setCounter] = useState(0);

  // useEffect(() => {
  //   let timeout;
  //   if (counter < endValue) {
  //     timeout = setTimeout(() => {
  //       setCounter(counter + 1);
  //     }, 10);
  //   } else {
  //     clearTimeout(timeout);
  //   }

  //   return () => {
  //     clearTimeout(timeout);
  //   };
  // }, [counter]);

  return (
    <div class="progress d-flex">
      <div
        class="progress-bar"
        role="progressbar"
        style={{ width: `${endValue}%`, backgroundColor: color }}
      ></div>
    </div>
  );
};
export default ProgressBar;
