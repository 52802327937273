import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useHistory } from "react-router-dom";
import { logoWhtOrdrr, nextArrow, calendarSmall, timeIcon } from "../../Assets";
import sendSvg from "../../Assets/images/send.svg";
import { StyledSVG } from "../../Utils/style";
import { useSelector, useDispatch } from "react-redux";
import { Button } from "@mui/material";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import SocketIOClient from "socket.io-client";

import Header from "../Components/Header";
import moment from "moment";
import { actions } from "../../Redux";
import { DataArray } from "@mui/icons-material";
import PageVisibility from "react-page-visibility";
const btnStyle = {
  minWidth: "35px",
  justifyContent: "end",
  borderRadius: "40px",
};

let timeout = null;
const time_zone = "Asia/Kolkata";

const Chat = ({ toggleChatDrawer, order_offline_id }) => {
  const [receiverState, setRecieverState] = useState([]);
  const [emitState, setEmitState] = useState([]);
  const [socketRef, setSocket] = useState(null);
  const [isPageVisible, setIsPageVisible] = useState(true);

  useEffect(() => {
    document.body.classList.add("white");
    const newSocket = SocketIOClient(`https://uatsocket.gogmgo.com:5000`, {
      reconnectionDelay: 1000,
      reconnection: true,
      jsonp: false,
      transports: ["websocket"],
    });
    setSocket(newSocket);

    return () => {
      if (socketRef !== null) {
        socketRef.close();
      }
    };
  }, []);

  useEffect(() => {
    if (isPageVisible) {
      if (socketRef === null) {
        const newSocket = SocketIOClient(`https://uatsocket.gogmgo.com:5000`, {
          reconnectionDelay: 1000,
          reconnection: true,
          jsonp: false,
          transports: ["websocket"],
        });
        setSocket(newSocket);
      }
    } else {
      if (socketRef !== null) {
        setSocket(null);
        socketRef.close();
      }
    }
  }, [isPageVisible]);

  // console.log("socketRef==>", socketRef);

  const dispatch = useDispatch();
  const messagesEndRef = useRef(null);

  const { orderDetails } = useSelector((state) => state.cartReducer);
  // const { pos_order_id } = orderDetails?.order ?? {};
  const { chatSession, chatList } = useSelector((state) => state.chatReducer);
  const { outletDetails } = useSelector((state) => state.storeReducer);

  const [message, setMessage] = useState("");
  const [typing, setTyping] = useState(false);
  const [outletTyping, setOutletTyping] = useState("false");

  const [page, setPage] = useState(1);

  useEffect(() => {
    if (socketRef && socketRef !== null) {
      socketRef.on("connect", (res) => {
        console.log("connect in socket", res);
      });

      socketRef.emit("joinRoom", {
        order_offline_id,
      });

      console.log("socket ref===>", socketRef);

      socketRef.emit("joinSocketSingleUser", {
        user_type: "customer",
        id: orderDetails?.order?.online_customer_id ?? "",
      });
      socketRef.on("joinSuccess", (res) => {
        console.log("joinSocket", res);
      });

      socketRef.on("receiveMessage", ({ message }) => {
        console.log("receiver socket==>1", message);
        setRecieverState([...receiverState, message]);
        dispatch(
          actions.storeMessage({
            type: "push",
            message,
          })
        );
      });

      socketRef.on("onTyping", (data) => {
        console.log(data, "outlet typing");
        if (data.sent_by === "outlet") setOutletTyping(data.is_typing);
      });
      socketRef.on("connect_error", (err) => {
        console.log("connect_error due to", err);
        setRecieverState([...receiverState, err]);
        // console.log(`connect_error due to ${err.message}`);
      });
    }

    // return () => {
    //   if (socketRef && socketRef !== null) {
    //     socketRef.emit("leaveSocketSingleUser", {
    //       user_type: "customer",
    //       id: orderDetails?.order?.online_customer_id ?? "",
    //     });
    //     socketRef.disconnect();
    //   }
    // };
  }, [socketRef]);

  const emitMessage = () => {
    // if (!socket.connected) socket.connect();
    // console.log(socket);
    if (message === "") return;
    const emitMessage = {
      order_offline_id: order_offline_id,
      ordrr_chat_session_id: chatSession?.ordrr_chat_session_id,
      online_customer_id: orderDetails?.order?.online_customer_id ?? "",
      outlet_id: outletDetails?.settings?.merchant_id,
      sent_by: "customer",
      message,
      time_zone,
    };
    console.log("emitMessage========>now", emitMessage, socketRef);

    setEmitState([...emitState, message]);
    socketRef.emit("newMessage", emitMessage);
    console.log("emitMessage========>after", emitMessage);

    setMessage("");
  };
  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };
  useEffect(() => {
    scrollToBottom();
  }, [chatList]);

  const emitTyping = (is_typing) => {
    console.log({
      order_offline_id: order_offline_id,
      ordrr_chat_session_id: chatSession?.ordrr_chat_session_id,
      online_customer_id: orderDetails?.order?.online_customer_id ?? "",
      outlet_id: outletDetails?.settings?.merchant_id,
      sent_by: "customer",
      message: orderDetails.order.customer_name,
      time_zone,
      is_typing: is_typing + "",
    });
    socketRef.emit("emitTyping", {
      order_offline_id: order_offline_id,
      ordrr_chat_session_id: chatSession?.ordrr_chat_session_id,
      online_customer_id: orderDetails?.order?.online_customer_id ?? "",
      outlet_id: outletDetails?.settings?.merchant_id,
      sent_by: "customer",
      message: orderDetails.order.customer_name,
      time_zone,
      is_typing: is_typing + "",
    });
  };

  useEffect(() => {
    console.log("start typing", typing);
    if (socketRef && socketRef !== null) {
      emitTyping(typing);
    }
  }, [typing]);

  const listItemStyle = { paddingTop: "13px", paddingBottom: "13px" };

  const OutletMessage = ({ data }) => (
    <div className="recived-msg-sec">
      <div className="recived-box">{data.message}</div>
      <div className="name-time">
        <span>Support,</span> {moment(data?.created).format("HH:mm A")}
      </div>
    </div>
  );

  const MyMessage = ({ data }) => (
    <div className="send-msg-sec">
      <div className="send-box">{data.message}</div>
      <div className="name-time">
        <span>You,</span> {moment(data?.created).format("HH:mm A")}
      </div>
    </div>
  );

  const MessageList = () =>
    chatList?.map((eachChat, idx) => {
      if (eachChat?.sent_by === "outlet") {
        return <OutletMessage key={idx} data={eachChat} />;
      } else {
        return <MyMessage key={idx} data={eachChat} />;
      }
    });

  function callback() {
    setTyping(false);
  }

  function debounce(callback, delay) {
    return function () {
      clearTimeout(timeout);
      timeout = setTimeout(callback, delay);
    };
  }

  const _onChangeText = debounce(callback, 3000);

  const handleVisibilityChange = (isVisible) => {
    if (isVisible) {
      setIsPageVisible(isVisible);
    } else {
      setIsPageVisible(false);
    }
  };

  return (
    <PageVisibility onChange={handleVisibilityChange}>
      <div className="wrapper colorGreyBg fullHeight confirm-width">
        <Header />

        <section className="chat-container Help orderScreen row mx-0">
          <div className="col-12 wht-bg">
            <h2 className="title mb-2">Support Chat</h2>
            <p className="discription m-0">
              Connected with {chatSession?.outlet_user_name ?? ""}
              {/* <br />
              visible: {isPageVisible === true ? "yes" : "no"}
              <br />
              socketRef: {socketRef !== null ? "connected" : "N/A"}
              <br />
              emitState:{" "}
              {emitState?.map((el) => (
                <span>{JSON.stringify(el)}</span>
              ))}
              <br />
              ReciverState:{" "}
              {receiverState?.map((el) => (
                <span>{JSON.stringify(el)}</span>
              ))} */}
            </p>
          </div>

          <div className="chat-section">
            <div className="chat-sec">
              <MessageList />
              {/* <div className="recived-msg-sec">
              <div className="recived-box"> Hi, How can I help you today? </div>
              <div className="name-time">
                <span>Leona,</span> 4:59pm
              </div>
            </div>

            <div className="send-msg-sec">
              <div className="send-box">
                {" "}
                Hi, all I want to cancel my order and get a refund.{" "}
              </div>
              <div className="name-time">
                <span>Leona,</span> 4:59pm
              </div>
            </div> */}
              <div className="bottom-div" ref={messagesEndRef} />
            </div>

            <div className="chat-box d-flex flex-column">
              {outletTyping === "true" && (
                <p> {outletDetails.account_name} is typing...</p>
              )}
              <div className="d-flex">
                <textarea
                  className="chat-text"
                  // onChange={_onChangeText}
                  onChange={(e) => {
                    if (typing === false) setTyping(true);
                    setMessage(e.target.value);
                    _onChangeText();
                  }}
                  value={message}
                ></textarea>
                <button className="send-btn" onClick={emitMessage}>
                  <StyledSVG
                    color={outletDetails?.settings?.brand_highlight_color}
                    src={sendSvg}
                    width="35"
                    height="35"
                  />
                </button>
              </div>
            </div>
          </div>
          <div className="col-12 wht-bg px-1 pt-0 bckLive_Updates">
            <List>
              <ListItem
                onClick={() => {
                  toggleChatDrawer(false);
                  if (socketRef !== null) {
                    setSocket(null);
                    socketRef.close();
                  }
                  // disconnectSocket();
                }}
                sx={listItemStyle}
              >
                <ListItem primary={"Back to live updates"} />
                <ListItemIcon>
                  <Button sx={btnStyle}>
                    <figure style={{ transform: "rotate(180deg)" }}>
                      <span
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                        className="icon-Component-1--7"
                      ></span>
                    </figure>
                  </Button>
                </ListItemIcon>
              </ListItem>
            </List>
          </div>
        </section>
      </div>
    </PageVisibility>
  );
};
export default Chat;
