import { toast } from "react-toastify";
import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  cartItems: [],
  promotionCode: "",
  cart_promocode: [],
  cart_promo_discount: 0,
  finalCart: null,
  cartBasicDetails: {
    totalCartAmount: 0,
    tip: 0,
    totalSaleTax: 0,
    totalServiceCharge: 0,
    totalDiscount: 0,
    totalAmount: 0,
  },
  orderDetails: null,
  orderPlaced: false,
  deliveryType: { id: 1, type: "Delivery", key: "delivery" },
};

const cartReducer = (state = initialState, action) => {
  const { type, item, index, payload } = action;
  let cloneCart = state.cartItems;
  switch (type) {
    case actionTypes.CLEAR_DATA:
      return initialState;
    case actionTypes.ADD_CART_ITEM:
      console.log("add reducer start", cloneCart);
      cloneCart.push(item);
      toast.success("Item added to cart.");
      console.log(cloneCart);
      return {
        ...state,
        cartItems: cloneCart,
        isError: false,
      };
    case actionTypes.UPDATE_CART_ITEM:
      if(action?.isToast){
        toast.success("Item updated successfully.");
      }
      return {
        ...state,
        cartItems: [
          ...state.cartItems.slice(0, index),
          item,
          ...state.cartItems.slice(index + 1),
        ],
      };
    case actionTypes.REMOVE_CART_ITEM:
      cloneCart.splice(index, 1);
      let newState = { ...state, cartItems: cloneCart };
      return newState;

    case actionTypes.DELIVERY_TYPE:
    case actionTypes.PLACE_ORDER_SUCCEEDED:
    case actionTypes.ORDER_STATUS:
    case actionTypes.FINAL_CART_SAVE:
      return {
        ...state,
        ...payload,
      };
    // by ABDUS
    case actionTypes.UPDATE_USER_TIP:
      return {
        ...state,
        cartBasicDetails: { ...state.cartBasicDetails, tip: payload },
      };
    case actionTypes.UPDATE_USER_DISCOUNT:
      return {
        ...state,
        cartBasicDetails: { ...state.cartBasicDetails, totalDiscount: payload },
      };

    case actionTypes.EDIT_ITEM_QUANTITY:
      cloneCart[payload.existOffId].quantity = payload.item.quantity;
      return {
        ...state,
        cartItems: cloneCart,
      };

    case actionTypes.REPLACE_CART_ITEM:
      console.log(action.payload, "payload in reducer");
      const targetItemIndex = state.cartItems.findIndex(
        (item) =>
          item.offline_id === action.payload.selected_item_detail.offline_id
      );
      cloneCart.splice(targetItemIndex, 1, action.payload.item);
      console.log("====================================");
      console.log("targetItemIndex", targetItemIndex, action.payload, state);
      console.log("====================================");
      return { ...state, cartItems: cloneCart };

    case actionTypes.UPDATE_BASIC_CART_DETAILS:
      return {
        ...state,
        cartBasicDetails: action.payload,
        finalCart: {
          ...state.finalCart,
          // order_total: action.payload.totalCartAmount,
          sales_tax: action.payload.totalSaleTax,
          service_charge: action.payload.totalServiceCharge,
          tips: action.payload.tip,
          finalTotalAmt: action.payload.totalAmount,
          paid_amount: action.payload.totalAmount,
        },
      };

    case actionTypes.ADD_QUOTATION_ID:
      return { ...state, quotationId: action.payload };

    default:
      return state;
  }
};
export default cartReducer;
