import React from "react";
import { bigLogo } from "../../Assets";

const UnableToLoadData = ({
  title = "404",
  text = "No record found",
}) => {
  return (
    <div className="wrapper error_container">
      <div className="container">
        <div className="row">
          <div className="col-md-12">
            <div className="not-found-menu">
              <figure>
                <img src={bigLogo} />
              </figure>
              <h1>{title}</h1>
              <div className="error-details">{text}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnableToLoadData;
