import React from "react";
import image from "../../Assets/images/spinner.gif";
// import Loader from "react-loader-spinner";
let Loading = ({ show }) =>
  show ? (
    <div className="loading-overlay">
      <img src={image} style={{ maxWidth: "85px" }}></img>
      {/* <Loader
        type="MutatingDots"
        color="#007bff"
        secondaryColor="#1ebdc3"
        height={100}
        width={100}
        // timeout={3000} //3 secs
      /> */}
    </div>
  ) : null;
export default Loading;
