import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  paymentTokenData: {},
  paymentTokengenerated: false,
  paymentIntent: null,
  quantityChecked: false,
  showQuantityErr: false,
  showQuantityErrMsg: "",
  trackOrder: null,
  whereIsMyOrder: null,
};

const paymentReducer = (state = initialState, action) => {
  const defaultErrMsg = "Something went wrong!";
  const { type, payload = {} } = action;
  switch (type) {
    case actionTypes.CLEAR_DATA:
      return initialState;
    case actionTypes.PAYMENT_INTENT_SUCCEEDED:
    case actionTypes.PAYMENT_TOKEN_SUCCEEDED:
    case actionTypes.TRACK_ORDER_DETAILS_SUCCEEDED:
    case actionTypes.WHERE_IS_MY_ORDER_SUCCEEDED:
      return {
        ...state,
        ...payload,
        loading: false,
      };
    case actionTypes.CHECK_QUANTITY_SUCCEEDED:
      return {
        ...state,
        quantityChecked: true,
        loading: false,
      };
    case actionTypes.CHECK_QUANTITY_FAILED:
      return {
        ...state,
        quantityChecked: false,
        showQuantityErr: payload?.showError ?? false,
        showQuantityErrMsg: payload?.errorMsg ?? defaultErrMsg,
        loading: false,
      };
    case actionTypes.CHECK_QUANTITY_MODAL_CLOSE:
      return {
        ...state,
        quantityChecked: false,
        showQuantityErr: false,
        showQuantityErrMsg: "",
      };
    default:
      return state;
  }
};
export default paymentReducer;
