/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import ImageTag from "./ImageTag";
import { useSelector, useDispatch } from "react-redux";
import Slide from "react-reveal/Slide";
import { useSwipeable, config } from "react-swipeable";

import {
  getSelectedItemDetail,
  getEditedItemDetail,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import {
  getModifierActionSign,
  getModifierActionName,
  checkNegNum,
  makeNegToPosNum,
  getServerImgUrl,
  deepCopy,
  checkArray,
  checkIfRepricePromo,
  sumOfPromos,
} from "../../helpers";
import { userIcon, removeIcon } from "../../Assets";
import { TOGGLE_CART_PAGE } from "../../Redux/actionTypes";
import { actions } from "../../Redux";
import { encode } from "../../Utils/Decrypt";
import { StyledSVG } from "../../Utils/style";
import { plusIcon, minusIcon } from "../../Assets";

const arr = window.location.href.split("/");

const DraggableItem = ({
  item,
  defaultimage,
  itemIndex,
  removeItemHandler,
  isLaptopScreen,
  increaseItem,
  decreaseItem,
  toggleAddItemPagePopUp,
  toggleBundleItemPagePopUp,
  ...props
}) => {
  const dispatch = useDispatch();
  // const history = useHistory();
  const navigate = useNavigate();

  const [isSlide, setIsSlide] = useState(false);
  let myStore = useSelector((state) => state);
  const { outletDetails, menuItems, promotionArr } = useSelector(
    (state) => state.storeReducer
  );

  const currency = outletDetails?.currency ? outletDetails?.currency + " " : "";
  const currencySymbol = outletDetails?.currency_symbol
    ? outletDetails?.currency_symbol + " "
    : "";

  const [wrapperClass, setWrapperClass] = useState("");
  const [position, setPosition] = useState({ x: 0, y: 0 });

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("ITEM Swiped!", eventData.dir);
      // if(!isLaptopScreen){
      if (!item?.is_added || (item?.is_added && item?.is_added == false)) {
        if (eventData.dir == "Left") {
          setIsSlide(true);
        } else if (eventData.dir == "Right") {
          setIsSlide(false);
        }
      }
      // }
    },
    // ...config,
    onTouchStartOrOnMouseDown: ({ event }) => event.preventDefault(),
    touchEventOptions: { passive: false },
    preventScrollOnSwipe: true,
    trackMouse: true,
  });

  function DraggableEventHandler(event, data) {
    if (event.type === "touchend") {
      setPosition({ x: data.lastX < -50 ? -185 : 0, y: 0 });
      setWrapperClass("");
      // if (data.lastX <= -185) {
      //   removeItemHandler(itemIndex);
      // }
    }

    // else {
    //   setPosition({ x: data.deltaX, y: 0 });
    //   if (event.type === "touchmove") {
    //     if (data.deltaX < 0) {
    //       setWrapperClass("red");
    //     }
    //   }
    // }
  }

  function getSameBundleItemIndex(bundle) {
    let res = -1;
    if (item?.BundledMenuItem?.length > 0) {
      for (let index = 0; index < item?.BundledMenuItem?.length; index++) {
        const element = item.BundledMenuItem[index];
        if (
          element.id != bundle.id &&
          element.option_series_id == bundle.option_series_id
        ) {
          res = index;
          break;
        }
      }
    }
    return res;
  }

  function getModifierItems(item) {
    let res = null;
    if (item?.menu_item_type == "standard" && item?.modifierArray?.length > 0) {
      res = item.modifierArray
        .filter((p) => p.action != "discount")
        .map((modifier, modifierIndex) => {
          const priceTag = `(${getModifierActionSign(modifier.action)}${
            currencySymbol.trim() ?? ""
          }${parseFloat(modifier.unit_price).toFixed(2)})`;
          console.log(modifier, priceTag, "action==");
          return (
            <ul className="other-options" key={modifierIndex}>
              {modifier.unit_price !== 0 && (
                <li>
                  {`${getModifierActionName(modifier.action)} ${modifier.name}`}{" "}
                  {priceTag}
                </li>
              )}
              {modifier.unit_price === 0 && (
                <li>{` ${getModifierActionName(modifier.action)}  ${
                  modifier.name
                }`}</li>
              )}
            </ul>
          );
        });
    } else if (
      item?.menu_item_type == "bundled" &&
      item?.BundledMenuItem?.length > 0
    ) {
      res = item.BundledMenuItem.sort(
        (a, b) => parseInt(a.option_series_id) - parseInt(b.option_series_id)
      ).map((bundle, bundleIndex) => {
        let modiferArr = null;
        if (bundle?.bundledMenuitemModifers?.length > 0) {
          modiferArr = bundle.bundledMenuitemModifers
            .filter((p) => p.action != "discount")
            .map((modifier, modifierIndex) => {
              const priceTag =
                modifier.unit_price > 0
                  ? `(@${parseFloat(modifier.unit_price).toFixed(2)})`
                  : "";

              return (
                <ul className="other-options" key={modifierIndex}>
                  <li>
                    {modifier.unit_price !== 0 && (
                      <>
                        {`${getModifierActionName(modifier.action)} ${
                          modifier.name
                        }`}{" "}
                        ({checkNegNum(modifier.unit_price) ? "- " : ""}
                        {`${currency ?? ""}`}
                        {parseFloat(
                          checkNegNum(modifier.unit_price)
                            ? makeNegToPosNum(modifier.unit_price)
                            : modifier.unit_price
                        ).toFixed(2)}
                        )
                      </>
                    )}
                    {modifier.unit_price === 0 && <>{`${modifier.name}`}</>}
                    {`${
                      modifierIndex + 1 != bundle.bundledMenuitemModifers.length
                        ? ","
                        : ""
                    }`}
                  </li>
                </ul>
              );
            });
        }
        const bundlePriceTag =
          bundle.amount > 0 ? `(@${parseFloat(bundle.amount).toFixed(2)})` : "";
        const bundleQuantityTag =
          bundle.quantity > 1 ? ` x ${parseInt(bundle.quantity)} ` : " ";
        let alreadyExist = getSameBundleItemIndex(bundle);
        const showComma =
          alreadyExist > -1 && alreadyExist < bundleIndex ? true : false;
        return (
          <ul
            className={`other-options ${
              item.hide_fixed_items_qr === "yes" &&
              bundle.option_series_name === "Fixed Items" &&
              "d-none"
            }`}
            key={bundleIndex}
          >
            {console.log(
              "bundle :",
              bundle.option_series_name,
              "item :",
              item,
              item.hide_fixed_items_qr === "yes",
              bundle.option_series_name === "Fixed Items",
              "AAAAAAA"
            )}
            <li>
              {showComma && alreadyExist > -1 ? "," : ""}
              {!showComma ? `${bundle?.option_series_name}:` : ""}{" "}
              {bundle?.bundle_menu_item_name}
              {`${bundleQuantityTag}`}
              {`${bundlePriceTag}`}
              {modiferArr}
            </li>
          </ul>
        );
      });
    }
    return res;
  }
  const checkValidImg = /\.(jpe?g|png|gif|bmp)$/i.test(item?.image);
  const itemPrice =
    checkArray(item?.promotions) && !checkIfRepricePromo(item?.promotions)
      ? item?.promotions?.[0]?.acutalPrice
      : item?.item_amount;
  const discountedTotalPrice =
    parseFloat(
      checkArray(item?.promotions) && !checkIfRepricePromo(item?.promotions)
        ? item?.promotions?.[0]?.acutalPrice <
          sumOfPromos(item?.promotions, "discountPerItem")
          ? item?.promotions?.[0]?.acutalPrice
          : sumOfPromos(item?.promotions, "discountPerItem")
        : 0
    ) * item.quantity;
  return (
    <div className="draggable_bg">
      <div>
        <div className={` new_items ${wrapperClass}`} {...handlers}>
          <div
            className={`menu-box${item?.is_added == true ? " added" : ""}${
              isSlide == true ? " slide" : ""
            }`}
          >
            <div className="d-flex orderMenu manage-wh cd-px-md-4 r-flex-issue">
              {checkValidImg && (
                <figure className="menu-img">
                  <ImageTag
                    src={getServerImgUrl(item.image)}
                    defaultimage={defaultimage}
                    removeImage={true}
                    removeCol={true}
                    replacecolname={isLaptopScreen ? "col-8" : "col-9"}
                    removecolname={isLaptopScreen ? "col-5" : "col-6"}
                  />
                </figure>
              )}
              <div
                className={`${
                  checkValidImg
                    ? isLaptopScreen
                      ? "col-5"
                      : "col-6"
                    : isLaptopScreen
                    ? "col-8"
                    : "col-9"
                }  menu-content pl-2`}
              >
                <h4 className="mw-100">
                  <span
                    className="txt-green"
                    style={{
                      color: outletDetails?.settings?.brand_highlight_color,
                    }}
                  >
                    {item.quantity} x
                  </span>{" "}
                  {item.pos_name} @{parseFloat(itemPrice).toFixed(2)}{" "}
                </h4>
                {getModifierItems(item)}
              </div>

              <div
                className={`${
                  isLaptopScreen ? "col-4" : "col-3"
                } px-0 text-right price-right`}
              >
                {isLaptopScreen && (
                  <div className="items-sec items-sec-end">
                    <button
                      type={"button"}
                      className="remove-btn new-add-remove"
                      disabled={!isLaptopScreen && item.quantity <= 1}  
                      onClick={() => decreaseItem(item.offline_id)}
                      style={{ border: `1px solid ${outletDetails?.settings?.brand_main_color??"#bababa"}` }}
                    >
                      <StyledSVG
                        color={outletDetails?.settings?.brand_main_color}
                        src={minusIcon}
                        width="14"
                      />{" "}
                    </button>
                    <input
                      type="text"
                      className="items-num new-items-num"
                      value={item.quantity}
                      style={{ color: outletDetails?.settings?.brand_main_color }}
                      readOnly
                    />
                    <button
                      type={"button"}
                      className="add-btn new-add-add"
                      onClick={() => increaseItem(item.offline_id)}
                      style={{ border: `1px solid ${outletDetails?.settings?.brand_main_color??"#bababa"}` }}
                    >
                      <StyledSVG
                        color={outletDetails?.settings?.brand_main_color}
                        src={plusIcon}
                        width="14"
                      />{" "}
                    </button>
                  </div>
                )}
                <p className="pricing-lg">
                  <small>{currencySymbol.trim()}</small>
                  {parseFloat(item.total + discountedTotalPrice).toFixed(2)}
                </p>
              </div>
            </div>
            <Slide duration={300} right when={isSlide}>
              <div class="col-6 edit_delete">
                <a
                  href="javascript:void(0);"
                  onClick={() => {
                    console.log(myStore, "mystore");
                    setPosition({ x: 0, y: 0 });
                    removeItemHandler(itemIndex);
                  }}
                  class="square-img delete-btn"
                >
                  <h5>Delete</h5>
                </a>
                <a
                  // href="javascript:void(0);"
                  role="button"
                  onClick={() => {
                    console.log("dragg>0", item, menuItems);
                    const itemObj = deepCopy(item);
                    const categorizationType =
                      outletDetails?.settings?.categorisation_preference == "2"
                        ? "sub_class_id"
                        : "sub_category_id";
                    const selectedSubCatItemIndex =
                      menuItems?.MenuItems?.findIndex(
                        (p) => p.id == itemObj?.[categorizationType]
                      );

                    const selectedSubCatItem =
                      menuItems?.MenuItems?.[selectedSubCatItemIndex] ??
                      undefined;

                    const selectedItemIndex = selectedSubCatItem
                      ? selectedSubCatItem?.MenuItem?.findIndex(
                          (p) => p.id == itemObj.id
                        )
                      : -1;

                    const selectedItem =
                      selectedItemIndex > -1
                        ? deepCopy(
                            selectedSubCatItem?.MenuItem?.[selectedItemIndex]
                          )
                        : undefined;

                    console.log(
                      "selectedItem",
                      selectedItem,
                      selectedItemIndex
                    );
                    if (selectedItem) {
                      // const _index = promotionArr.findIndex(el => el.id === selectedItem.id);
                      // const isPromotion = _index !== -1;
                      // let updatedItem = isPromotion ? promotionArr[_index] : selectedItem;
                      const menuType = selectedItem.menu_item_type;
                      const acutalPrice = parseFloat(
                        selectedItem?.menu_price?.item_price ?? 0
                      );
                      let promoPrice = 0;
                      let discountPrice = 0;
                      if (checkArray(itemObj?.promotions)) {
                        itemObj.promotions = itemObj?.promotions[0]
                        if (itemObj?.promotions.type == "Amount") {
                          discountPrice = parseFloat(itemObj?.promotions.amount);
                          promoPrice = acutalPrice - discountPrice;
                        } else if (itemObj?.promotions.type == "Percentage") {
                          discountPrice =
                            (acutalPrice *
                              parseFloat(itemObj?.promotions.amount)) /
                            100;
                          promoPrice = acutalPrice - discountPrice;
                        } else if (itemObj?.promotions.type == "Re-Price") {
                          discountPrice = parseFloat(itemObj?.promotions.amount);
                          promoPrice = discountPrice;
                        }
                        selectedItem.menu_price.item_price = promoPrice;
                        selectedItem.promotions = itemObj?.promotions;
                      }
                      console.log("testttt", selectedItem, itemObj, item);

                      if (menuType === "bundled") {
                        dispatch(
                          actions.selectedItem({
                            selectedItem: {
                              ...selectedItem,
                              offline_id: itemObj.offline_id,
                            },
                          })
                        );
                      } else {
                        dispatch(
                          actions.selectedItem({
                            selectedItem: { ...selectedItem, ...itemObj },
                          })
                        );
                      }

                      dispatch(
                        actions.editItemDetails({
                          editItemDetails: itemObj,
                        })
                      );
                      // going to edit cart
                      if(menuType === "bundled"){
                        toggleBundleItemPagePopUp()
                      }else{
                        toggleAddItemPagePopUp()
                      }
                      // navigate(
                      //   `/${
                      //     menuType === "bundled" ? "editBundleItem" : "editItem"
                      //   }/${encode(outletDetails?.settings?.id)}`
                      // );
                      // dispatch(getSelectedItemDetail(selectedItem));
                      // dispatch(getEditedItemDetail(item));
                      // dispatch({ type: TOGGLE_CART_PAGE, payload: true });
                      // if (selectedItem?.menu_item_type == "bundled")
                      //   history.push("/bundle-add-item");
                      // else history.push("/add-item");
                    } else {
                      dispatch(
                        showNotificationWithTimeout(`Invalid item`, "error")
                      );
                    }
                  }}
                  className="square-img mr-2 edit-btn"
                >
                  <h5>Edit</h5>
                </a>
              </div>
            </Slide>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DraggableItem;
