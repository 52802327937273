import moment from "moment";
import { toast } from "react-toastify";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { actions } from "..";
import { checkArray } from "../../helpers";
import { deliveryOptionsServices } from "../../services";
import * as actionTypes from "../actionTypes";

function* addLocation(action) {
  const { data } = yield call(
    deliveryOptionsServices.addLocation,
    action.payload
  );
  if (data.status) {
    // toast.success(data.message);
    yield put({
      type: actionTypes.ADD_LOCATIONS_SUCCEEDED,
      payload: {
        locations: [...data.data],
        addLocationsSucceeded: true,
      },
    });
    const locationArr = data?.data;
    const lastLocation = locationArr[locationArr.length - 1];
    console.log("i am out of deliveryOptionsSaga", action.payload);
    if (action.payload?.url_prefix) {
      yield put(
        actions.getStores({
          latitude: lastLocation?.latitude,
          longitude: lastLocation?.longitude,
          url_prefix: action?.payload?.url_prefix,
          order_type: action?.payload?.order_type,
        })
      );
    }

    yield put(actions.updateAddress(locationArr.length));

    yield put({
      type: actionTypes.SET_SHOW_MAP,
      payload: {
        displayMap: false,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.ADD_LOCATIONS_FAILED,
      payload: { error: data.message },
    });
  }
}

function* getLocations() {
  console.log("asaSAsa");
  const { data } = yield call(deliveryOptionsServices.getLocation);
  if (data?.status) {
    yield put({
      type: actionTypes.GET_LOCATIONS_SUCCEEDED,
      payload: { locations: data.data },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.GET_LOCATIONS_FAILED,
      payload: { error: data?.message },
    });
  }
}

function* getStores(action) {
  console.log("getStores action", action);
  const deliveryTime = yield select(
    (store) => store?.deliveryOptionsReducer?.deliveryTime
  );
  const deliveryDate = yield select(
    (store) => store?.deliveryOptionsReducer?.deliveryDate
  );
  const storePayload = {
    ...action.payload,
    date: moment(deliveryDate).format("yyyy-MM-DD"),
    time: deliveryTime,
  };
  console.log("getStores payload", storePayload);

  const response = yield call(deliveryOptionsServices.getStores, storePayload);
  let { data } = response;
  // test if there is only one store
  // data = {...data, data: data.data.slice(-1)}
  if (data?.status) {
    console.log("====================================");
    console.log("saga", data, data.data.length);
    console.log("====================================");
    yield put({
      type: actionTypes.GET_STORES_SUCCEEDED,
      payload: {
        outlets: data.data,
        showOutletModal: data.data.length <= 1 ? false : true,
      },
    });

    if (data.data.length === 0) {
      toast.error("Sorry no outlets available");
      yield put(actions.toggleDisableBtn(true));
    }

    if (data.data.length === 1) {
      console.log("====================================");
      console.log(data.data);
      console.log("====================================");
      const storeData = {
        ...data.data[0],
        // collectType: options.deliveryType,
        // dateOfDelivery: moment(datePicker).format("YYYY-MM-DD"),
        // timeOfDelivery: moment(timePicker).format("YYYY-MM-DD HH:mm:ss"),
      };
      yield put(actions.saveSeletedStoreData(storeData));
      if(!checkArray(storeData?.ApplicablePeriod)){
        toast.error("This Outlet is not available for orders today.");
      }
    }
  } else {
    toast.error("Sorry no outlets available");
    yield put(actions.toggleDisableBtn(true));
    yield put(actions.noOutletModal(true));
    yield put({
      type: actionTypes.GET_STORES_FAILED,
      payload: { error: data?.message },
    });
  }
}

function* deliveryOptionsSaga() {
  yield takeLatest(actionTypes.ADD_LOCATIONS_REQUESTED, addLocation);
  yield takeLatest(actionTypes.GET_LOCATIONS_REQUESTED, getLocations);
  yield takeLatest(actionTypes.GET_STORES_REQUESTED, getStores);
}

export default deliveryOptionsSaga;
