import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import {
  calendarSmall,
  timeIcon,
  locationBigIcon,
  crossWhtIcon,
  crossGryIcon,
} from "../../Assets";
import crossImg from "../../Assets/images/cross.png";
import crossIcon from "../../Assets/images/apps-icons-ordrr/cross.svg";
import { useSelector, useDispatch, batch } from "react-redux";
import {
  Button,
  Collapse,
  Input,
  InputLabel,
  OutlinedInput,
  TextField,
} from "@mui/material";
import InputAdornment from "@mui/material/InputAdornment";
import {
  MapContainer,
  Marker,
  Popup,
  TileLayer,
  useMapEvents,
  useMap,
} from "react-leaflet";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import Header from "../Components/Header";
import SelectInput from "../Components/SelectInput";
import OutlineInput from "../Components/OutlineInput";
import PopupModal from "../Components/PopupModal";
import FloatingInput from "../Components/FloatingInput";
import FloatingGroupInput from "../Components/FloatingGroupInput";
import osm from "../../Utils/osmProvider";
import logo from "../../Assets/images/location.png";
import focusMarker from "../../Assets/images/location_focus.png";

import L from "leaflet";
import { ReactCountryDropdown } from "react-country-dropdown";
import "react-country-dropdown/dist/index.css";
import IntlTelInput from "react-intl-tel-input";
import "react-intl-tel-input/dist/main.css";

import "leaflet/dist/leaflet.css";
import { actions } from "../../Redux";
// import { LocalizationProvider, MobileDatePicker, TimePicker } from "@mui/lab";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import moment from "moment-timezone";
import {
  roundDateTimeObj,
  roundMomentTime,
  roundTimeQuarterHour,
} from "../../Utils/time";
import { encode } from "../../Utils/Decrypt";
import { toast } from "react-toastify";
import { StyledSVG } from "../../Utils/style";
import { countryList } from "../../Utils/constants";
import { height } from "@mui/system";
import { checkArray, checkStoreAvailable, checkNull } from "../../helpers";

const customLogo = new L.Icon({
  iconUrl: logo,
  iconRetinaUrl: logo,
  iconSize: [35, 35],
  shadowSize: [50, 64],
  iconAnchor: [22, 94],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
});

const DeliveryOption = () => {
  const urlPrefix = window.location.href.split("/")[2].split(".")[0];
  const storeId =
    checkNull(urlPrefix, false) && urlPrefix !== "localhost:3000" && urlPrefix !== "192"
      ? urlPrefix
      : "prive-botanic-gardens";

  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    document.body.classList.add("white");
    clearData();
    dispatch(actions.toggleDisableBtn(false));
    dispatch(actions.toggleConfirmBtn(false));
    setTimeout(() => {
      // getUserLocations();
      getStoreDetails();
      getTagsDetail();
      // dispatch(
      //   actions.getStores({
      //     url_prefix: storeId,
      //     order_type: "delivery",
      //   })
      // );
    }, 1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const clearData = () => {
    dispatch(actions.clearData());
  };

  const {
    availableLocations,
    addLocationsSucceeded,
    locations,
    outlets,
    showOutletModal,
    error,
    noOutletModal,
    selectedStore,
    disableBtn,
    deliveryDate,
    confirmBtnClick,
  } = useSelector((state) => state.deliveryOptionsReducer);
  const { storeDetails, outletDetails } = useSelector(
    (state) => state.storeReducer
  );

  // updating react title
  const storeName = `${storeDetails?.Merchant?.account_name ?? ""}`;
  document.title = `${storeName}  Online Ordering`;
  document
    .getElementsByTagName("meta")
    .namedItem("description")
    .setAttribute(
      "content",
      `Order delivery or pick-up now from ${storeName}!`
    );

  const { displayMap } = useSelector((state) => state.commonReducer);

  const getUserLocations = () => dispatch(actions.getLocation());
  const getStoreDetails = () => dispatch(actions.getStoreDetails({ storeId }));
  const getTagsDetail = () => dispatch(actions.getTagsDetail());

  const collectArr = [
    { id: 1, type: "Delivery", key: "delivery" },
    { id: 2, type: "Pick-Up", key: "pickup" },
  ];

  const [deliveryTypeOptions, setDeliveryTypeOptions] = useState(collectArr);

  const [options, setOptions] = useState({
    deliveryType: 0,
    deliveryLocation: 0,
    store: 0,
  });

  useEffect(() => {
    if (outlets.length === 1) {
      setOptions({ ...options, store: 1 });
    }
  }, []);
  const addressInitialState = {
    // nickname: "Jervois",
    nickname: "",
    // street_address: "Jervois Road",
    street_address: "",
    unit_number: "",
    // unit_number: "39",
    country: 0,
    city: "Singapore",
    // zip_code: "249093" || "",
    zip_code: "",
  };
  const [address, setAddress] = useState(addressInitialState);

  // const [address, setAddress] = useState({
  //   nickname: "",
  //   street_address: "",
  //   unit_number: "",
  //   country: 0,
  //   city: " ",
  //   zip_code: "",
  // });
  const dateFormat = "yyyy/MM/DD hh:mm a";
  const [showAddressModal, setShowAddressModal] = useState(true);
  // const [showMap, setshowMap] = useState(false);
  const [changeAddressModal, setChangeAddressModal] = useState(false);
  const [openStartPicker, setOpenStartPicker] = useState(false);
  const [datePicker, setDatePicker] = useState(
    new Date(moment().format(dateFormat))
  );
  const [timePicker, setTimePicker] = useState(roundTimeQuarterHour(moment()));
  const [maxDate, setMaxDate] = useState(
    new Date(moment().add(6, "days").format(dateFormat))
  );
  const [minDate, setMinDate] = useState(new Date(moment().format(dateFormat)));
  const [maxTime, setMaxTime] = useState(new Date(moment().format(dateFormat)));
  const [minTime, setMinTime] = useState(new Date(moment().format(dateFormat)));
  const [showTimePicker, setShowTimePicker] = useState(false);

  const getStoreApplicableTimes = (deliveryValDate) =>
    checkStoreAvailable(
      selectedStore,
      storeDetails?.Merchant?.timezone_offset,
      moment(moment(deliveryValDate).format("yyyy-MM-DD 00:00:00")).toDate()
    );

  console.log("Testsssss", moment(deliveryDate).format("yyyy-MM-DD 00:00:00"));
  useEffect(() => {
    if (availableLocations) {
      const { LATITUDE: lat, LONGITUDE: lng } =
        availableLocations?.results?.[0];
      console.log(lat, lng, "ffff");
      setPosition({ lat, lng });
    }
  }, [availableLocations]);

  useEffect(() => {
    if (checkNull(storeDetails?.Merchant?.allow_pickup_delivery)) {
      if (storeDetails?.Merchant?.allow_pickup_delivery != "1") {
        if (storeDetails?.Merchant?.allow_pickup_delivery == "2") {
          setDeliveryTypeOptions(collectArr.filter((p) => p?.id == 2));
        } else {
          setDeliveryTypeOptions(collectArr.filter((p) => p?.id == 1));
        }
      }
    }
  }, [storeDetails?.Merchant?.allow_pickup_delivery])
  
  // useEffect(() => {
  //   // analytics
  //   if(checkNull(storeDetails?.Merchant?.account_name) && checkArray(locations)){
  //     // console.log("tesdfsdfdsf:",locations?.[0]?.customer_id);
  //     window.hj('identify', locations?.[0]?.customer_id, {
  //       'action': 'user arrived',
  //       'merchant_id': storeDetails?.Merchant?.id,
  //       'account_name': storeDetails?.Merchant?.account_name,
  //     });
  //   }
  // }, [storeDetails?.Merchant?.account_name,locations]);

  useEffect(() => {
    if (!confirmBtnClick) {
      let isValidAppStore = null;
      moment.tz.setDefault();
      if (selectedStore && checkArray(selectedStore?.ApplicablePeriod)) {
        isValidAppStore = false;
        const checkValidAppStore = getStoreApplicableTimes(datePicker);
        if (checkValidAppStore?.index > -1) {
          isValidAppStore = checkValidAppStore;
        }
      }
      moment.tz.setDefault(storeDetails?.Merchant?.timezone ?? "");
      const current = moment();
      console.log(
        "selectedStoreeeee",
        selectedStore,
        isValidAppStore,
        moment(datePicker).format("yyyy-MM-DD")
      );
      if (
        // moment(datePicker).format("yyyy-MM-DD") !==
        //   current.format("yyyy-MM-DD") &&
        selectedStore &&
        checkArray(selectedStore?.ApplicablePeriod) &&
        isValidAppStore !== null
      ) {
        if (isValidAppStore) {
          const endTime = moment(isValidAppStore?.endTime, ["HH:mm:ss"]);
          let deliveryTimeBuffer =
            storeDetails?.Merchant?.enable_delivery_partner == "yes"
              ? storeDetails?.Merchant?.partner_delivery_estimate ?? 0
              : storeDetails?.Merchant?.minimum_pick_up_buffer ?? 0;
          if (deliveryTypeOptions?.[options?.deliveryType]?.id === 1) {
            // delivery
            if (storeDetails?.Merchant?.enable_delivery_partner == "yes") {
              deliveryTimeBuffer = parseInt(
                storeDetails?.Merchant?.partner_delivery_estimate ?? 0
              );
            } else {
              deliveryTimeBuffer = parseInt(
                storeDetails?.Merchant?.own_delivery_estimate ?? 0
              );
            }
          } else if (deliveryTypeOptions?.[options?.deliveryType]?.id === 2) {
            //pickup
            deliveryTimeBuffer = parseInt(
              storeDetails?.Merchant?.minimum_pick_up_buffer ?? 0
            );
          }
          const momentMaxTime = moment(isValidAppStore?.endTime, [
            "HH:mm:ss",
          ]).add("minutes", deliveryTimeBuffer);
          const desiredMaxTime = new Date(momentMaxTime.format(dateFormat));
          setMaxTime(desiredMaxTime);

          const start = moment(isValidAppStore?.startTime, ["HH:mm:ss"]);
          let startBufferTime = 0;
          let pickUpBufferTime = 5;
          if (deliveryTypeOptions?.[options?.deliveryType]?.id === 1) {
            // delivery
            if (storeDetails?.Merchant?.enable_delivery_partner == "yes") {
              startBufferTime = parseInt(
                storeDetails?.Merchant?.partner_delivery_estimate ?? 0
              );
            } else {
              startBufferTime = parseInt(
                storeDetails?.Merchant?.own_delivery_estimate ?? 0
              );
            }
          } else if (deliveryTypeOptions?.[options?.deliveryType]?.id === 2) {
            //pickup
            startBufferTime = parseInt(
              storeDetails?.Merchant?.minimum_pick_up_buffer ?? 0
            );
          }

          console.log("testtsadasd:", startBufferTime);

          const desiredMinTime =
            moment(datePicker).format("yyyy-MM-DD") ==
            current.format("yyyy-MM-DD")
              ? start.isBefore(current)
                ? new Date(
                    moment().add(startBufferTime, "minutes").format(dateFormat)
                  )
                : new Date(
                    moment(start)
                      .add(startBufferTime, "minutes")
                      .format(dateFormat)
                  )
              : new Date(
                  moment(start)
                    .add(startBufferTime, "minutes")
                    .format(dateFormat)
                );
          console.log(
            "testtsadasd222tttt:",
            roundDateTimeObj(desiredMinTime, 5)
          );
          /* use $momentMaxTime instead $endTime for check current time is valid for order with buffer  */
          setMinTime(roundDateTimeObj(desiredMinTime));
          if (
            moment().add(startBufferTime, "minutes").isAfter(momentMaxTime) &&
            moment(datePicker).format("yyyy-MM-dd") ===
              moment().format("yyyy-MM-dd")
          ) {
            setMinDate(new Date(moment().add(1, "days").format(dateFormat)));
            setDatePicker(new Date(moment().add(1, "days").format(dateFormat)));
            dispatch(
              actions.deliveryDate(moment().add(1, "days").format("YYYY/MM/DD"))
            );
          }
          const validCurrentTime =
            moment().add(startBufferTime, "minutes").isBefore(momentMaxTime) &&
            moment()
              .add(startBufferTime, "minutes")
              .isAfter(moment(start).add(startBufferTime, "minutes"))
              ? moment().add(startBufferTime, "minutes")
              : moment(start).add(startBufferTime, "minutes");

          const newTimePickerVal = roundMomentTime(
            validCurrentTime,
            pickUpBufferTime
          );
          setTimePicker(new Date(moment(newTimePickerVal).format(dateFormat)));
          dispatch(actions.toggleDisableBtn(false));
        } else {
          if (
            moment(datePicker).format("yyyy-MM-DD") !==
            current.format("yyyy-MM-DD")
          ) {
            toast.error(
              `This Outlet is not available for orders on the selected date.`
            );
          } else {
            toast.error("This Outlet is not available for orders today.");
          }
          dispatch(actions.toggleDisableBtn(true));
        }
      } else {
        if (
          moment(datePicker).format("yyyy-MM-DD") !==
            current.format("yyyy-MM-DD") &&
          isValidAppStore !== null
        ) {
          toast.error(
            `This Outlet is not available for orders on the selected date.`
          );
        }
        dispatch(actions.toggleDisableBtn(true));
      }
      // else {
      //   if (storeDetails?.ServicePeriod) {
      //     const endTime = moment(storeDetails?.ServicePeriod?.end_time, [
      //       "HH:mm:ss",
      //     ]);
      //     let deliveryTimeBuffer =
      //       storeDetails?.Merchant?.enable_delivery_partner == "yes"
      //         ? storeDetails?.Merchant?.partner_delivery_estimate ?? 0
      //         : storeDetails?.Merchant?.minimum_pick_up_buffer ?? 0;
      //     if (options?.deliveryType === 0) {
      //       // delivery
      //       if (storeDetails?.Merchant?.enable_delivery_partner == "yes") {
      //         deliveryTimeBuffer = parseInt(
      //           storeDetails?.Merchant?.partner_delivery_estimate ?? 0
      //         );
      //       } else {
      //         deliveryTimeBuffer = parseInt(
      //           storeDetails?.Merchant?.own_delivery_estimate ?? 0
      //         );
      //       }
      //     } else if (options?.deliveryType === 1) {
      //       //pickup
      //       deliveryTimeBuffer = parseInt(
      //         storeDetails?.Merchant?.minimum_pick_up_buffer ?? 0
      //       );
      //     }
      //     const desiredMaxTime = new Date(
      //       moment(storeDetails?.ServicePeriod?.end_time, ["HH:mm:ss"])
      //         .subtract("minutes", deliveryTimeBuffer)
      //         .format(dateFormat)
      //     );
      //     setMaxTime(desiredMaxTime);

      //     const start = moment(storeDetails?.ServicePeriod?.start_time, [
      //       "HH:mm:ss",
      //     ]);
      //     const current = moment();
      //     let startBufferTime = 0;
      //     let pickUpBufferTime = 5;
      //     if (options?.deliveryType === 0) {
      //       // delivery
      //       if (storeDetails?.Merchant?.enable_delivery_partner == "yes") {
      //         startBufferTime = parseInt(
      //           storeDetails?.Merchant?.partner_delivery_estimate ?? 0
      //         );
      //       } else {
      //         startBufferTime = parseInt(
      //           storeDetails?.Merchant?.own_delivery_estimate ?? 0
      //         );
      //       }
      //     } else if (options?.deliveryType === 1) {
      //       //pickup
      //       startBufferTime = parseInt(
      //         storeDetails?.Merchant?.minimum_pick_up_buffer ?? 0
      //       );
      //     }

      //     console.log("testtsadasd:", startBufferTime);

      //     const desiredMinTime =
      //       moment(datePicker).format("yyyy-MM-DD") ==
      //       current.format("yyyy-MM-DD")
      //         ? start.isBefore(current)
      //           ? new Date(
      //               moment().add(startBufferTime, "minutes").format(dateFormat)
      //             )
      //           : new Date(
      //               moment(start)
      //                 .add(startBufferTime, "minutes")
      //                 .format(dateFormat)
      //             )
      //         : new Date(
      //             moment(start).add(startBufferTime, "minutes").format(dateFormat)
      //           );
      //     console.log("testtsadasd222:", desiredMinTime);
      //     setMinTime(desiredMinTime);
      //     if (
      //       moment().add(startBufferTime, "minutes").isAfter(endTime) &&
      //       moment(datePicker).format("yyyy-MM-dd") ===
      //         moment().format("yyyy-MM-dd")
      //     ) {
      //       setMinDate(new Date(moment().add(1, "days").format(dateFormat)));
      //       setDatePicker(new Date(moment().add(1, "days").format(dateFormat)));
      //     }
      //     const validCurrentTime =
      //       moment().add(startBufferTime, "minutes").isBefore(endTime) &&
      //       moment()
      //         .add(startBufferTime, "minutes")
      //         .isAfter(moment(start).add(startBufferTime, "minutes"))
      //         ? moment().add(startBufferTime, "minutes")
      //         : moment(start).add(startBufferTime, "minutes");

      //     const newTimePickerVal = roundMomentTime(
      //       validCurrentTime,
      //       pickUpBufferTime
      //     );
      //     console.log(
      //       "testtsadasd333:",
      //       new Date(moment(validCurrentTime).format(dateFormat))
      //     );
      //     setTimePicker(new Date(moment(newTimePickerVal).format(dateFormat)));

      //     // console.log(start.isBefore(current), "rrrrrrrrr", new Date(Date.now()));
      //     // console.log(
      //     //   moment("17:56:00", ["HH:mm:ss"]).isBefore(moment()),
      //     //   "mmmmmmmm"
      //     // );
      //   }
      // }
    }

    return () => {
      moment.tz.setDefault();
    };
  }, [
    confirmBtnClick,
    storeDetails,
    selectedStore,
    options?.deliveryType,
    datePicker,
  ]);

  const onPressAdd = () =>
    dispatch(
      actions.getLatLngFromAddrs({
        ...address,
        country: countryList[address.country]?.country_code,
      })
    );

  if (error) {
    if (changeAddressModal) setChangeAddressModal(false);
  }
  useEffect(() => {
    dispatch(actions.setAddressError(false));
  }, [changeAddressModal]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function initialiseLastAddress() {
    setOptions({ ...options, deliveryLocation: locations.length + 1 });
  }

  useEffect(() => {
    dispatch(actions.deliveryTime(timePicker.toTimeString().split(" ")[0]));
  }, [timePicker]);

  const onConfirm = () => {
    initialiseLastAddress();
    setChangeAddressModal(false);
    dispatch(
      actions.addLocation({
        ...address,
        unit_number:
          address?.unit_number == ""
            ? "not added by user"
            : address?.unit_number,
        nickname:
          address?.nickname == "" ? "not added by user" : address?.nickname,
        street_address:
          address?.street_address == ""
            ? "not added by user"
            : address?.street_address,

        ...{ latitude: position.lat, longitude: position.lng },
        url_prefix: storeId,
        order_type:
          deliveryTypeOptions?.[selectedStore.collectType]?.id === 1
            ? "delivery"
            : "pickup",
      })
    );
  };
  const onChangeAddress = (type) => (e) => {
    setAddress({
      ...address,
      [type]: e.target.value,
    });
  };

  // const manageChangeAddressModal = useCallback((value) => {
  //   setChangeAddressModal(value);
  //   if (!value) {
  //     setShowAddressModal(true);
  //     setshowMap(false);
  //   }
  // }, []);
  const [storeTarget, setStoreTarget] = useState({});
  const manageChangeOutletModal = useCallback(() => {
    dispatch(actions.changeOutletModal(false));
  }, [dispatch]);

  const handleChange = (type) => (e) => {
    setOptions({ ...options, [type]: e.target.value });

    if (type === "deliveryType" && e.target.value) {
      console.log("=============deliveryType===================");
      console.log(e.target.value);
      console.log("=============deliveryType END===========");
      dispatch(
        actions.getStores({
          url_prefix: storeId,
          order_type:
            deliveryTypeOptions?.[selectedStore.collectType]?.id === 1
              ? "delivery"
              : "pickup",
        })
      );
    }

    if (type === "deliveryLocation") {
      if (e.target.value < 1) return;
      const { latitude, longitude } = locations[e.target.value - 1];
      setStoreTarget({ latitude, longitude, url_prefix: storeId });
      dispatch(
        actions.getStores({
          latitude,
          longitude,
          url_prefix: storeId,
          order_type:
            deliveryTypeOptions?.[options?.deliveryType]?.id === 1
              ? "delivery"
              : "pickup",
        })
      );
    }
  };

  useEffect(() => {
    const storeData = {
      ...outlets[options.store - 1],
      collectType: options.deliveryType,
      dateOfDelivery: moment(datePicker).format("YYYY/MM/DD"),
      timeOfDelivery: moment(timePicker).format("HH:mm:ss"),
    };
    dispatch(actions.saveSeletedStoreData(storeData));
  }, [options]);

  const onFinalConfirm = useCallback(() => {
    if (outlets.length === 1) options.store = 1;
    if (options.store && storeDetails) {
      //
      const storeData = {
        ...outlets[options.store - 1],
        collectType: options.deliveryType,
        dateOfDelivery: moment(datePicker).format("YYYY/MM/DD"),
        timeOfDelivery: moment(timePicker).format("HH:mm:ss"),
      };

      const locationData = options.deliveryLocation
        ? {
            ...locations[options.deliveryLocation - 1],
          }
        : null;
      if (
        deliveryTypeOptions?.[options?.deliveryType]?.id === 1 &&
        !locationData
      ) {
        toast.error("Please Select Location.");
        return;
      }
      dispatch(actions.toggleConfirmBtn(true));
      batch(() => {
        dispatch(actions.saveSeletedStoreData(storeData));
        dispatch(actions.saveSeletedLocation(locationData));
        dispatch(
          actions.saveDeliveryType({
            deliveryType: deliveryTypeOptions[options.deliveryType],
          })
        );
      });
      if (!outlets[options.store - 1]?.id) {
        toast.error("Please Select Location.");
        dispatch(actions.toggleConfirmBtn(false));
        return;
      }
      navigate(`/dashboard/${encode(outlets[options.store - 1]?.id)}`);
    } else {
      if (deliveryTypeOptions?.[options?.deliveryType]?.id === 1) {
        if (options.deliveryLocation) {
          toast.error("Please Select Store.");
          console.log("testdssasdfa1111", storeTarget);
          dispatch(
            actions.getStores({
              ...storeTarget,
              order_type:
                deliveryTypeOptions?.[selectedStore.collectType]?.id === 1
                  ? "delivery"
                  : "pickup",
            })
          );
          console.log("====================================");
        } else toast.error("Please Select Location.");
      } else if (deliveryTypeOptions?.[options?.deliveryType]?.id === 2) {
        console.log("====================================");
        console.log("testdssasdfa222222");
        dispatch(
          actions.getStores({
            url_prefix: storeId,
            order_type:
              deliveryTypeOptions?.[selectedStore.collectType]?.id === 1
                ? "delivery"
                : "pickup",
          })
        );
        console.log("====================================");
        toast.error("Please Select Store.");
      }
    }
  }, [
    options.deliveryLocation,
    options.store,
    options.deliveryType,
    storeDetails,
    outlets,
    datePicker,
    timePicker,
    locations,
    // history,
    dispatch,
    deliveryTypeOptions,
  ]);

  useEffect(() => {
    if(storeDetails?.Merchant){
      dispatch(
        actions.getStores({
          url_prefix: storeId,
          order_type: storeDetails?.Merchant?.allow_pickup_delivery == "2" ? "pickup" :"delivery",
        })
      );
    }
  }, [storeDetails])
  
  const center = { lat: 0, lng: 0 };
  const ZOOM_LEVEL = 18;
  const mapRef = useRef();
  const animateRef = useRef(false);

  const [position, setPosition] = useState(center);

  function DraggableMarker() {
    const markerRef = useRef(null);
    const map = useMapEvents({
      click() {
        // map.locate()
      },
      // locationfound(e) {
      //   setPosition(e.latlng)
      //   // map.flyTo(position, map.getZoom())
      // },
    });

    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setPosition(marker.getLatLng());

            // map.fitBounds([position]);
            // map.
          }
        },
      }),
      []
    );

    return (
      <>
        <img
          onClick={() => {
            map.flyTo(position, map.getZoom());
          }}
          width="40px"
          alt=""
          src={focusMarker}
          style={{ position: "absolute", zIndex: 99999999, right: 15, top: 25 }}
        />
        <Marker
          draggable={true}
          eventHandlers={eventHandlers}
          position={position}
          icon={customLogo}
          ref={markerRef}
        >
          <Popup minWidth={90}>
            <span>{"Hello "}</span>
          </Popup>
        </Marker>
      </>
    );
  }

  const Address = () => (
    <Collapse orientation="horizontal" in={showAddressModal} className="">
      <div className="rider-note-modal">
        <div className="col-12 cross-btn-con">
          <figure
            className="float-right updateAddress"
            onClick={() => setChangeAddressModal(false)}
          >
            <StyledSVG
              color={
                selectedStore?.settings?.brand_highlight_color ||
                storeDetails?.Merchant?.brand_highlight_color
              }
              src={crossIcon}
              width="40"
            />
          </figure>
        </div>
        <section className="login-section OtpVerifyMs row mx-0 align-content-start addSecPopup">
          <div className="sm-content col-12 px-5 mb-3">
            <figure className="add-address-icon">
              <img src={locationBigIcon} alt="" />
            </figure>
            <h2 className="mb-4">Add Delivery Details </h2>
            <p>
              Please provide us your{" "}
              <b> preferred address for this delivery.</b>
            </p>
          </div>

          <div className="col-12 mt-0 px-5">
            <form>
              <div className="group mt-5">
                <FloatingInput
                  label="LOCATION NICKNAME"
                  onChange={onChangeAddress("nickname")}
                  value={address.nickname}
                />
              </div>
              <div className="group">
                <FloatingInput
                  label="STREET ADDRESS"
                  onChange={onChangeAddress("street_address")}
                  value={address.street_address}
                />
              </div>

              <div className="group">
                <FloatingInput
                  label="UNIT NUMBER"
                  onChange={onChangeAddress("unit_number")}
                  value={address.unit_number}
                />
              </div>

              <div className="group row d-flex">
                <FloatingGroupInput
                  fullWidth
                  variant="standard"
                  className="mt-0"
                >
                  <InputLabel>COUNTRY & CITY</InputLabel>
                  <div className="row mx-0 mt-16">
                    <div className="col-3 countrySelect selectBox">
                      <SelectInput
                        brand_highlight_color={
                          selectedStore?.settings?.brand_highlight_color ||
                          storeDetails?.Merchant?.brand_highlight_color
                        }
                        country={true}
                        options={countryList}
                        value={address.country}
                        keyToCheck="country_code"
                        onChange={onChangeAddress("country")}
                      />
                    </div>
                    <div className="col-9">
                      <Input
                        autoComplete="new-password"
                        fullWidth
                        onChange={onChangeAddress("city")}
                        value={address.city || " "}
                      />
                    </div>
                  </div>
                </FloatingGroupInput>
              </div>
              <div className="group">
                <FloatingInput
                  label="ZIP CODE"
                  onChange={onChangeAddress("zip_code")}
                  value={address.zip_code}
                />
              </div>

              <div className="group sub-btn mb-0 d-flex justify-content-between">
                <Button
                  type="button"
                  className="col-12 mr-1 mt-0 mb-5"
                  onClick={() => {
                    onPressAdd();
                    setShowAddressModal(false);
                    // setChangeAddressModal(true)
                  }}
                  style={{
                    backgroundColor: storeDetails?.Merchant?.brand_main_color,
                  }}
                >
                  ADD
                </Button>
              </div>
            </form>
          </div>
        </section>
      </div>
    </Collapse>
  );
  function SetViewOnClick({ coords }) {
    const map = useMap();
    map.setView(coords, map.getZoom());

    return null;
  }
  const CustomMap = useCallback(() => {
    console.log("postion", position);
    const bounds = L.latLngBounds([position]);
    console.log("bounds", bounds);

    return (
      <Collapse orientation="horizontal" in={displayMap}>
        {/* <div> */}
        <div className="maping" style={{}}>
          <MapContainer
            center={position}
            zoom={ZOOM_LEVEL}
            ref={mapRef}
            bounds={bounds}
          >
            <TileLayer
              url={osm.maptiler.url}
              attribution={osm.maptiler.attribution}
            />
            <DraggableMarker />
            <SetViewOnClick coords={position} />
            {/* <SetViewOnClick animateRef={animateRef} /> */}
          </MapContainer>
        </div>

        <text className="col-12 px-4">
          Drag pointer to the accurate location
        </text>

        <div className="group sub-btn my-4 mb-5 col-12 px-4 d-flex justify-content-between maxBtn">
          <Button
            type="button"
            className="col-12 mx-0"
            onClick={() => {
              onConfirm();
            }}
            style={{
              backgroundColor:
                // selectedStore?.settings?.brand_highlight_color ||
                storeDetails?.Merchant?.brand_main_color,
            }}
          >
            Confirm
          </Button>
        </div>
        {/* </div> */}
      </Collapse>
    );
  }, [
    onConfirm,
    position,
    // showMap,
    outletDetails?.settings?.brand_main_color,
  ]);

  const ChooseOutlet = () => (
    <div className="rider-note-modal">
      <div className="col-12 cross-btn-con">
        <figure
          className="float-right cross-address-icon updateAddress mt-n2"
          onClick={manageChangeOutletModal}
        >
          <StyledSVG
            color={
              selectedStore?.settings?.brand_highlight_color ||
              storeDetails?.Merchant.brand_highlight_color
            }
            src={crossIcon}
            width="18px"
          />
        </figure>
      </div>
      <section className="login-section OtpVerifyMs row mx-0 mb-5">
        <div className="sm-content-mb-0 col-12 px-5">
          <h2 className="mb-4 sm-heading">Choose Outlet</h2>
          <p>
            There are multiple outlets within your delivery radius,{" "}
            <b>please select one </b> to proceed.
          </p>
        </div>
        <div className="col-12 mt-5">
          <div className="selectInput selectBox">
            <SelectInput
              brand_highlight_color={
                selectedStore?.settings?.brand_highlight_color ||
                storeDetails?.Merchant.brand_highlight_color
              }
              options={[{ account_name: "Select Store" }].concat(outlets)}
              value={options.store}
              keyToCheck="account_name"
              onChange={(e) => {
                handleChange("store")({ target: { value: e.target.value } });
              }}
            />
          </div>
        </div>

        <div
          className="group sub-btn mb-0 d-flex col-12"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <Button
            type="button"
            className="col-12 mr-1"
            onClick={() => {
              options.store && manageChangeOutletModal();
              const storeData = {
                ...outlets[options.store - 1],
                collectType: options.deliveryType,
                dateOfDelivery: moment(datePicker).format("YYYY/MM/DD"),
                timeOfDelivery: moment(timePicker).format("HH:mm:ss"),
              };
              dispatch(actions.saveSeletedStoreData(storeData));
              console.log("====================================");
              console.log("fffstoreData", storeData, outletDetails, {
                ...outlets[options.store - 1],
              });
              console.log("====================================");
            }}
            style={{
              backgroundColor:
                selectedStore?.settings?.brand_main_color ||
                storeDetails?.Merchant?.brand_main_color,
            }}
          >
            Confirm
          </Button>
        </div>
      </section>
    </div>
  );
  const noOutlet = () => (
    <div className="rider-note-modal">
      <div className="col-12 cross-btn-con">
        <figure
          className="float-right cross-address-icon updateAddress mt-n2"
          onClick={() => dispatch(actions.noOutletModal(false))}
        >
          <StyledSVG
            color={
              selectedStore?.settings?.brand_highlight_color ||
              storeDetails?.Merchant.brand_highlight_color
            }
            src={crossIcon}
            width="18px"
          />
        </figure>
      </div>
      <section className="login-section OtpVerifyMs row mx-0 mb-5">
        <div className="sm-content-mb-0 col-12 px-5">
          <h2 className="mb-4 sm-heading">No Outlet Available</h2>
          <p>
            We're so are so sorry but it seems there are no outlets availaible
            for this merchant nearby this location. Please try another address.
          </p>
        </div>
        <div
          className="group sub-btn mb-0 d-flex col-12"
          style={{ marginTop: "10px", marginBottom: "10px" }}
        >
          <Button
            type="button"
            className="col-12 mr-1"
            onClick={() => {
              options.store && manageChangeOutletModal();
              const storeData = {
                ...outlets[options.store - 1],
                collectType: options.deliveryType,
                dateOfDelivery: moment(datePicker).format("YYYY/MM/DD"),
                timeOfDelivery: moment(timePicker).format("HH:mm:ss"),
              };
              dispatch(actions.noOutletModal(false));
            }}
            style={{
              backgroundColor:
                selectedStore?.settings?.brand_main_color ||
                storeDetails?.Merchant?.brand_main_color,
            }}
          >
            Try Again
          </Button>
        </div>
      </section>
    </div>
  );

  const [toolbarStatus, setToolbarStatus] = useState(true);
  const [innerWindowHeight, setInnerWindowHeight] = useState(
    window.innerHeight
  );

  const calcToolbarStatus = () => {
    const outerHeight = window.outerHeight;
    const innerHeight = window.innerHeight;
    const diff = outerHeight - innerHeight;
    // console.log(innerHeight, outerHeight, diff, "aaaaaaaaaaaaaaaaaaaaaa");
    if (diff > 120) return true;
    else return false;
  };

  useEffect(() => {
    setToolbarStatus(calcToolbarStatus());

    const rootEl = document.getElementById("root");
    console.log(rootEl, "rootEl");
    if (calcToolbarStatus()) rootEl.classList.remove("toolbar-vh");
    else rootEl.classList.add("toolbar-vh");
  }, [innerWindowHeight]);

  const checkValidStoreTime = (timeValue, clockType) => {
    let res = true;
    if (clockType === "hours") {
      if (minTime.getHours() < maxTime.getHours()) {
        if (
          timeValue >= minTime.getHours() &&
          timeValue <= maxTime.getHours()
        ) {
          res = false;
        }
      } else {
        if (
          (timeValue >= minTime.getHours() && timeValue <= 24) ||
          (timeValue >= 0 && timeValue <= maxTime.getHours())
        ) {
          res = false;
        }
      }
    } else if (clockType === "minutes") {
      res = false;
      if (timePicker.getHours() == minTime.getHours()) {
        console.log("timepickerrrrr", timeValue, minTime.getMinutes());
        if (timeValue >= minTime.getMinutes() && timeValue <= 55) {
          res = false;
        } else {
          res = true;
        }
      } else if (timePicker.getHours() == maxTime.getHours()) {
        if (timeValue >= 0 && timeValue <= maxTime.getMinutes()) {
          res = false;
        } else {
          res = true;
        }
      }
    }
    return res;
  };

  useEffect(() => {
    window.addEventListener("resize", function (event) {
      setInnerWindowHeight(window.innerHeight);
    });
  }, []);

  return storeDetails?.Merchant.brand_highlight_color ? (
    <>
      {/* <ReactCountryDropdown onSelect={handleSelect} countryCode='IN' /> */}
      <div className="small-width-wrapper">
        <div
          className={`wrapper colorGreyBg ${toolbarStatus ? "" : "toolbar-vh"}`}
        >
          <Header />
          <section className="user-section Delivery row mx-0">
            <div className="col-12 px-0">
              <form>
                <div className="group">
                  <h1> How do you want to collect?</h1>

                  <div className="selectInput selectBox">
                    <SelectInput
                      brand_highlight_color={
                        selectedStore?.settings?.brand_highlight_color ||
                        storeDetails?.Merchant.brand_highlight_color
                      }
                      options={deliveryTypeOptions}
                      value={options.deliveryType}
                      keyToCheck="type"
                      onChange={(e) =>
                        handleChange("deliveryType")({
                          target: { value: e.target.value },
                        })
                      }
                    />
                  </div>
                </div>
                {deliveryTypeOptions?.[options?.deliveryType]?.id === 1 ? (
                  <div className="group">
                    <h1> Where do you want to collect?</h1>
                    <div className="selectInput selectBox">
                      <SelectInput
                        brand_highlight_color={
                          selectedStore?.settings?.brand_highlight_color ||
                          storeDetails?.Merchant.brand_highlight_color
                        }
                        options={[
                          { nickname: "Select Delivery Location" },
                        ].concat(locations)}
                        value={options.deliveryLocation}
                        keyToCheck="nickname"
                        onChange={(e) => {
                          handleChange("deliveryLocation")({
                            target: { value: e.target.value },
                          });
                        }}
                      />
                    </div>
                    <span
                      style={{
                        color:
                          selectedStore?.settings?.brand_highlight_color ||
                          storeDetails?.Merchant.brand_highlight_color,
                      }}
                      onClick={() => {
                        setChangeAddressModal(true);
                        setShowAddressModal(true);
                        setAddress(addressInitialState);
                      }}
                    >
                      ADD NEW ADDRESS
                    </span>
                  </div>
                ) : null}
                <div className="group">
                  <h1> When do you want it?</h1>
                  <div className="selectInput row twoInp justify-content-between justify-content-sm-start">
                    <span style={{ margin: "0px" }}>
                      <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <MobileDatePicker
                          open={openStartPicker}
                          label="Date"
                          // disabled={true}
                          // disableOpenPicker={true}
                          inputFormat="dd/MM/yyyy"
                          value={datePicker}
                          minDate={minDate}
                          maxDate={maxDate}
                          onClose={() => setOpenStartPicker(false)}
                          onChange={(date) => {
                            // dispatch(actions.deliveryDate(date.toString()));
                            if (
                              moment(date).isSameOrAfter(moment(minDate)) &&
                              moment(date).isSameOrBefore(moment(maxDate))
                            ) {
                              dispatch(
                                actions.deliveryDate(
                                  moment(date).format("YYYY/MM/DD")
                                )
                              );
                              setDatePicker(date);
                            }
                          }}
                          renderInput={(params) => (
                            <OutlinedInput
                              onClick={() => setOpenStartPicker(true)}
                              sx={{
                                borderWidth: "2px",
                                borderRadius: "40px",
                                height: "41.13px",
                                width: "150px",
                                marginRight: "30px",
                                marginLeft: "22px",
                                fontSize: "14px",
                              }}
                              className="calendar"
                              endAdornment={
                                <InputAdornment position="end">
                                  <span
                                    style={{
                                      color:
                                        selectedStore?.settings
                                          ?.brand_highlight_color ||
                                        storeDetails?.Merchant
                                          .brand_highlight_color,
                                    }}
                                    className="icon-calendar"
                                  ></span>
                                </InputAdornment>
                              }
                              {...params}
                            />
                          )}
                        />
                      </LocalizationProvider>
                    </span>
                    {console.log("minTimeeeeeeeeeee", minTime, maxTime)}
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <TimePicker
                        open={!disableBtn && showTimePicker}
                        label="Time"
                        disabled={disableBtn}
                        value={timePicker}
                        // minTime={new Date(moment().add(1, "hour"))}
                        // minTime={minTime}
                        // maxTime={new Date(moment().add(3, "hour"))}
                        // maxTime={maxTime}
                        minutesStep={5}
                        shouldDisableTime={(timeValue, clockType) => {
                          return checkValidStoreTime(timeValue, clockType);
                        }}
                        onClose={() => setShowTimePicker(false)}
                        onChange={(time) => {
                          if (
                            !checkValidStoreTime(time.getHours(), "hours") &&
                            !checkValidStoreTime(time.getMinutes(), "minutes")
                          )
                            setTimePicker(time);
                        }}
                        renderInput={(params) => (
                          <OutlinedInput
                            onClick={() => setShowTimePicker(true)}
                            sx={{
                              borderWidth: "2px",
                              borderRadius: "40px",
                              height: "41.13px",
                              width: "150px",
                              fontSize: "14px",
                            }}
                            className="calendar"
                            endAdornment={
                              <InputAdornment position="end">
                                <StyledSVG
                                  color={
                                    selectedStore?.settings
                                      ?.brand_highlight_color ||
                                    storeDetails?.Merchant.brand_highlight_color
                                  }
                                  src={timeIcon}
                                  width="40px"
                                  stroke="true"
                                />
                              </InputAdornment>
                            }
                            {...params}
                          />
                        )}
                      />
                    </LocalizationProvider>
                  </div>
                </div>

                <div className="sub-btn mb-0 mx-auto d-flex justify-content-between bottom-btn-sec maxBtn px-5">
                  <Button
                    type="button"
                    className="col-12"
                    disabled={disableBtn}
                    style={{
                      opacity: disableBtn ? 0.2 : 1,
                      backgroundColor:
                        selectedStore?.settings?.brand_main_color ||
                        storeDetails?.Merchant.brand_main_color,
                    }}
                    onClick={() => {
                      if (!disableBtn) onFinalConfirm();
                    }}
                  >
                    CONTINUE
                  </Button>
                </div>
              </form>

              <PopupModal
                showDrawer={changeAddressModal}
                toggleDrawer={setChangeAddressModal}
                height={"74vh"}
                className="addressModelCon"
              >
                <>
                  {Address()}
                  {position.lng || position.lat ? CustomMap() : null}
                  {/* {CustomMap()} */}
                </>
              </PopupModal>

              <PopupModal
                showDrawer={showOutletModal}
                toggleDrawer={() => {}}
                height={"auto"}
              >
                <>{ChooseOutlet()}</>
              </PopupModal>
              <PopupModal
                showDrawer={noOutletModal}
                toggleDrawer={() => {}}
                height={"auto"}
              >
                <>{noOutlet()}</>
              </PopupModal>
            </div>
          </section>
        </div>
      </div>
    </>
  ) : (
    ""
  );
};
export default DeliveryOption;
