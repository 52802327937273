import React from "react";
import TextField from "@mui/material/TextField";
import { styled } from "@mui/material/styles";
const FloatingInput = ({
  labelTop,
  beforeColor,
  afterColor,
  labelColor,
  ...props
}) => {
  const labelInputTop = labelTop ?? "-10px";
  const beforeInputColor = beforeColor ?? "#ddd";
  const afterInputColor = afterColor ?? "#3e38a1";
  const afterLabelColor = labelColor ?? "#333";
  const CssTextField = {
    "& label.Mui-focused": {
      color: afterLabelColor,
      top: labelInputTop,
      fontSize: 14,
    },
    "& label.MuiFormLabel-filled": {
      color: afterLabelColor,
      top: labelInputTop,
      fontSize: 14,
    },
    "& .MuiInput-underline:before": {
      borderBottom: `1px solid ${beforeInputColor}`,
      // transition: "0.2s ease all",
    },
    "& .MuiInput-underline:after": {
      borderBottom: `1px solid ${beforeInputColor}`,
      // transition: "0.2s ease all",
    },
    "& label.MuiFormLabel-filled ~ .MuiInput-underline:before": {
      borderBottom: `1px solid ${afterInputColor}`,
    },
    "& label.MuiFormLabel-filled ~ .MuiInput-underline:after": {
      borderBottom: `1px solid ${afterInputColor}`,
    },
  };
  return (
    <TextField
      sx={CssTextField}
      {...props}
      variant="standard"
      // autoComplete="new-password"
      fullWidth
      inputProps={{
        autoComplete: "off",
        form: {
          autocomplete: "off",
        },
      }}
    />
  );
};
export default FloatingInput;
