import moment from "moment";
import axios from "axios";
import { encode } from "base-64";
import { DeviceUUID } from "device-uuid";

export function capitalizeFirstLetter(string) {
  if (string && typeof string === "string" && string !== "") {
    string = string.toLowerCase();
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return "";
}

export function truncateString(str, n) {
  return str.length > n ? str.substr(0, n - 1) + ".." : str;
}

export function removeUnderScore(string, extraString = "", extraTo = "") {
  if (extraString !== "") {
    string = string.replace(extraString, extraTo);
  }
  return string.replace(/_/g, " ");
}

function replaceDateStringFrom(date) {
  let res = undefined;
  try {
    if (date && typeof date === "string" && date !== "") {
      res = date.replace("/Date(", "");
      res = res.replace(")/", "");
      res = moment(parseInt(res));
    }
  } catch (e) {}
  return res;
}

export async function avocadoAuthToken() {
  // Advocado API
  const ad_api_url = process.env.REACT_APP_AVOCADO_API_URL;
  const ad_username = process.env.REACT_APP_AVOCADO_API_USERNAME;
  const ad_password = process.env.REACT_APP_AVOCADO_API_PASSWORD;
  let myHeaders = new Headers({
    "Content-Type": "application/json",
    Accept: "application/json",
    // "Access-Control-Allow-Origin": "*",
  });
  myHeaders.set(
    "Authorization",
    "Basic " + encode(ad_username + ":" + ad_password)
  );
  const json = await fetch(`${ad_api_url}/oauth2/tokenCredential`, {
    method: "POST",
    mode: "cors",
    headers: myHeaders,
    body: JSON.stringify({
      grant_type: "client_credentials",
    }),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("authToken failed : " + error.message));
  return json;
}

export async function orderHistoryApi(token) {
  // Advocado API
  const base_url = process.env.REACT_APP_API_URL;
  let myHeaders = {
    "Content-Type": "application/json",
    Accept: "application/json",
    token: token,
    // "Access-Control-Allow-Origin": "*",
  };
  const json = await fetch(`${base_url}/qr_code_apis/get_loyalty_history`, {
    method: "POST",
    mode: "cors",
    headers: new Headers(myHeaders),
    body: JSON.stringify({}),
  })
    .then((response) => response.json())
    .then((json) => json)
    .catch((error) => console.log("authToken failed : " + error.message));
  return json;
}

export function getReOrderItemIds(data) {
  let allItems = [];
  if (data && data.length > 0) {
    data.map((order, orderIndex) => {
      if (order?.PosOrderItem && order?.PosOrderItem.length > 0) {
        order?.PosOrderItem.map((item, itemIndex) => {
          const modifierArr =
            item?.PosOrderItemDetail && item?.PosOrderItemDetail.length > 0
              ? pluckArray(item?.PosOrderItemDetail, "pos_modifier_id")
              : [];
          const bundleItemArr =
            item?.PosOrderBundleItem && item?.PosOrderBundleItem.length > 0
              ? item?.PosOrderBundleItem.map((bundleItem) => {
                  const resItem = {
                    menu_item_id: bundleItem?.menu_item_id,
                    modifiers_id: pluckArray(
                      bundleItem?.PosOrderItemDetail,
                      "pos_modifier_id"
                    ),
                  };
                  return resItem;
                })
              : [];
          const checkExist = allItems.findIndex(
            (p) => p.menu_item_id == item?.menu_item_id
          );
          if (checkExist > -1) {
            allItems[checkExist].count = allItems[checkExist].count + 1;
            // allItems[checkExist].bundle_item_ids = bundleItemArr;
          } else {
            allItems.push({
              menu_item_id: item?.menu_item_id,
              count: 1,
              created_on: parseStringToDate(
                order?.PosOrder?.order_create_date,
                "YYYY-MM-DD HH-mm-ss",
                "dddd, MMMM Do"
              ),
              quantity: parseInt(item?.menu_item_quantity),
              modifier_ids: modifierArr,
              bundle_item_ids: bundleItemArr,
            });
          }
        });
      }
    });
  }
  allItems.sort(function (a, b) {
    return b.count - a.count;
  });
  if (allItems.length > 0) {
    allItems = allItems.slice(0, 10);
  }
  return allItems;
}

export function getUnixDateFormat(dateNum, format = "MMMM D, YYYY") {
  let res = "N/A";
  const getDate = replaceDateStringFrom(dateNum);
  if (getDate && getDate !== undefined) {
    res = getDate.format(format);
  }
  return res;
}
export function getCartNewItemCount(cartItems) {
  let res = 0;
  if (cartItems && cartItems?.length > 0) {
    res = cartItems
      .filter((p) => p?.is_added == false)
      .reduce(function (sum, current) {
        return sum + parseInt(current.quantity);
      }, 0);
  }
  return res;
}

export function getTypeOfVoucher(type, voucher) {
  let res = false;
  let todayDate = moment();
  const fromDateObj = replaceDateStringFrom(voucher?.ValidTo);
  if (fromDateObj && fromDateObj !== undefined) {
    // console.log("todayDate:", todayDate.format("MMMM D, YYYY"));
    // console.log("fromDateObj:", fromDateObj.format("MMMM D, YYYY"));
    // console.log("cjeckkk:", fromDateObj.diff(todayDate, "days"));
    if (type == 0 && fromDateObj.diff(todayDate, "days") > 0) {
      res = true;
    } else if (
      type == 1 &&
      fromDateObj.diff(todayDate, "days") > 0 &&
      voucher?.IsRedeemable == false
    ) {
      res = true;
    } else if (type == 2 && fromDateObj.diff(todayDate, "days") < 0) {
      res = true;
    }
  }
  return res;
}

export function nFormatter(num) {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
}

export function getModifierActionName(modifierAction) {
  let res = "";
  switch (modifierAction) {
    case "add":
      res = "Add";
      break;
    case "remove":
      res = "No";
      break;
    case "swap":
      res = "Swap";
      break;
    default:
      break;
  }
  console.log(modifierAction, "modifierAction", res, "res");
  return res;
}

export function getBundleItemOptionsPrice(bundleItem, currency = "") {
  let res = null;
  if (
    bundleItem?.action_price !== null &&
    bundleItem?.action_price !== "" &&
    bundleItem?.amount !== "" &&
    bundleItem?.amount !== null
  ) {
    res = (
      <span className="price-sm">
        {parseFloat(bundleItem.amount) !== 0
          ? checkNegNum(bundleItem.amount)
            ? "-"
            : "+"
          : ""}
        {`${currency}`}
        {parseFloat(bundleItem.amount) < 0
          ? makeNegToPosNum(bundleItem.amount)
          : parseFloat(bundleItem.amount).toFixed(2)}
      </span>
    );
  } else if (
    bundleItem?.unit_price !== null &&
    bundleItem?.unit_price !== "" &&
    parseFloat(bundleItem?.unit_price) > 0
  ) {
    res = (
      <span className="price-sm">
        {"+"}
        {`${currency}`}
        {parseFloat(bundleItem.unit_price).toFixed(2)}
      </span>
    );
  }
  return res;
}

export function getModifierActionSign(modifierAction, sizeMultipler = null) {
  let res = "";
  switch (modifierAction) {
    case "add":
      res = "+";
      break;
    case "remove":
      res = "-";
      break;
    case "swap":
      res = "+";
      break;
    case "size":
      res = "+";
      if (checkNull(sizeMultipler, false)) {
        if (parseFloat(sizeMultipler) < 1) {
          res = "-";
        }
      }
      break;
    default:
      break;
  }
  return res;
}

export function checkNegNum(num) {
  return parseFloat(num) < 0;
}

export function makeNegToPosNum(num) {
  return -1 * parseFloat(num);
}

export function getModifierTypeName(modifierType, modifier) {
  let res = "";
  res = modifier?.modifier_name ?? "";
  // if (modifier?.action == "size") {
  //   res = modifier?.modifier_name ?? "";
  // } else {
  //   switch (modifierType) {
  //     case "ingredient":
  //       res = `${modifier?.Ingredient?.ingredient_name ?? ""} ${
  //         modifier?.action == "swap"
  //           ? " with " + modifier?.SwapIngredient?.ingredient_name ?? ""
  //           : ""
  //       }`;
  //       break;
  //     case "recipe":
  //       res = `${modifier?.Recipe?.recipe_name ?? ""} ${
  //         modifier?.action == "swap"
  //           ? " with " + modifier?.SwapRecipe?.recipe_name ?? ""
  //           : ""
  //       }`;
  //       break;
  //     default:
  //       break;
  //   }
  // }

  return res;
}

export function setPageDetails(data = {}) {
  if (data.title) {
    document.title = data.title;
  }
  if (data.favIcon) {
    document.getElementById("fav-icon").setAttribute("href", data.favIcon);
    document
      .getElementById("apple-touch-icon")
      .setAttribute("href", data.favIcon);
  }
}

export function pluckArray(arr, key, isString = false) {
  return arr.map((i) => (isString ? `${i[key]}` : i[key]));
}

export function getSessionStorageOrDefault(key, defaultValue) {
  const stored = sessionStorage.getItem(key);
  if (!stored) {
    return defaultValue;
  }
  return JSON.parse(stored);
}

export function setSessionStorage(name, value) {
  sessionStorage.setItem(name, JSON.stringify(value));
  return value;
}

export function checkItemExistInCart(item, cart) {
  let res = -1;
  if (cart?.length > 0) {
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      if (
        item?.id == element?.id &&
        element?.is_added == false &&
        JSON.stringify(item?.modifierArray) ==
          JSON.stringify(element?.modifierArray)
      ) {
        res = i;
        break;
      }
    }
  }
  return res;
}

export function checkBundleItemExistInCart(item, cart) {
  let res = -1;
  if (
    cart?.length > 0 &&
    ((item?.modifierArray && item?.modifierArray?.length > 0) ||
      (item?.BundledMenuItem && item?.BundledMenuItem?.length > 0))
  ) {
    for (let i = 0; i < cart.length; i++) {
      const element = cart[i];
      if (
        item?.id == element?.id &&
        element?.is_added == false &&
        JSON.stringify(item?.modifierArray) ==
          JSON.stringify(element?.modifierArray) &&
        JSON.stringify(item?.selectedBundleItem) ==
          JSON.stringify(element?.selectedBundleItem)
      ) {
        res = i;
        break;
      }
    }
  }
  return res;
}

export function getItemById(itemId, subId, table_detail) {
  let res = null;
  if (table_detail?.SubCategory?.length > 0) {
    const subIndex = table_detail?.SubCategory?.findIndex((p) => p.id == subId);
    if (subIndex > -1) {
      const subCat = table_detail?.SubCategory?.[subIndex];
      const itemIndex = subCat?.MenuItem?.findIndex((p) => p.id == itemId);
      if (itemIndex > -1) {
        res = subCat?.MenuItem?.[itemIndex];
      }
    }
  }
  return res;
}

export function getCartSubmittedItems(orders, table_detail) {
  let items = [];
  if (orders?.menu_item && orders?.menu_item?.length > 0) {
    orders?.menu_item?.map((order) => {
      const menuItemQyt = order?.menu_item_quantity
        ? parseFloat(order?.menu_item_quantity)
        : 1;
      const quantityMultipleBy = order?.quantityMultipleBy
        ? parseFloat(order?.quantityMultipleBy)
        : 1;
      const exactQyt = parseFloat(menuItemQyt / quantityMultipleBy);
      const menuItemPrice = order?.menu_item_price
        ? parseFloat(order?.menu_item_price)
        : 0;
      const menuDoubleActualAmout = order?.doubleActualAmout
        ? parseFloat(order?.doubleActualAmout)
        : 0;
      const menuTotalPrice = menuDoubleActualAmout
        ? parseFloat(menuDoubleActualAmout * exactQyt)
        : 0;
      const menuPriceForOne = menuItemPrice;
      // console.log("cheksadkkkkkkk=>>>exactQyt", exactQyt);
      // console.log("cheksadkkkkkkk=>>>menuPriceForOne", menuPriceForOne);
      // console.log("cheksadkkkkkkk=>>>diffInTotalPrice", diffInTotalPrice);
      // console.log("cheksadkkkkkkk=>>>quantityMultipleBy", quantityMultipleBy);
      const modifierArray =
        order?.modifiers?.length > 0
          ? order?.modifiers?.map((modifier) => {
              return {
                id: modifier?.modifier_id ?? "",
                modifier_id: modifier?.modifier_id ?? "",
                modifier_group_id: modifier?.modifier_group_id ?? "",
                action: modifier?.action ?? "",
                name: modifier?.name ?? "",
                unit_price: parseFloat(modifier?.modifier_price ?? 0),
                modifier_price: parseFloat(modifier?.modifier_price ?? 0),
                modifier_quantity: parseFloat(modifier?.modifier_quantity ?? 0),
              };
            })
          : [];

      // for discount modifier
      if (order?.discountModifierID !== "") {
        const modiItem = getItemById(
          order?.menu_item_id,
          order?.subCategoryid,
          table_detail
        );
        // console.log("modiItem", modiItem);
        if (modiItem !== null) {
          let disModifierIndex = -1;
          let disModifierItem = null;
          if (modiItem?.menu_item_type === "standard") {
            disModifierIndex = modiItem?.PosMenuitemsModifier?.findIndex(
              (p) => p.id == order?.discountModifierID
            );
            if (disModifierIndex > -1) {
              disModifierItem =
                modiItem?.PosMenuitemsModifier?.[disModifierIndex];
            }
          } else {
            disModifierIndex = modiItem?.DiscountModifiers?.findIndex(
              (p) => p.id == order?.discountModifierID
            );
            if (disModifierIndex > -1) {
              disModifierItem = modiItem?.DiscountModifiers?.[disModifierIndex];
            }
          }
          if (disModifierIndex > -1 && disModifierItem !== null) {
            modifierArray.push({
              id: disModifierItem?.id ?? "",
              modifier_id: disModifierItem?.id ?? "",
              modifier_group_id: disModifierItem?.modifier_group_id ?? "",
              action: disModifierItem?.action ?? "",
              name: disModifierItem?.modifier_name ?? "",
              unit_price: parseFloat(disModifierItem?.unit_price ?? 0),
              modifier_price: parseFloat(disModifierItem?.unit_price ?? 0),
              modifier_quantity: parseFloat(
                disModifierItem?.amount_per_item ?? 0
              ),
            });
          }
        }
      }
      // for discount modifier end

      const BundledMenuItemArray =
        order?.BundledMenuItem?.length > 0
          ? order?.BundledMenuItem?.map((bundledItem) => {
              const bundleModifiers =
                bundledItem?.bundledMenuitemModifers?.length > 0
                  ? bundledItem?.bundledMenuitemModifers.map(
                      (bundleModifier) => {
                        return {
                          id: bundleModifier?.id ?? "",
                          modifier_id: bundleModifier?.modifier_id ?? "",
                          modifier_group_id:
                            bundleModifier?.modifier_group_id ?? "",
                          action: bundleModifier?.action ?? "",
                          name: bundleModifier?.name ?? "",
                          unit_price: bundleModifier?.unit_price
                            ? parseFloat(bundleModifier?.unit_price)
                            : 0,
                          modifier_price: bundleModifier?.unit_price
                            ? parseFloat(bundleModifier?.modifier_price)
                            : 0,
                          modifier_quantity: bundleModifier?.unit_price
                            ? parseFloat(bundleModifier?.modifier_quantity)
                            : 0,
                          menu_item_id: bundleModifier?.menu_item_id ?? "",
                        };
                      }
                    )
                  : [];
              {
                console.log(
                  bundledItem?.category_id,
                  bundledItem?.sub_category_id,
                  "ababababa"
                );
              }
              return {
                action_price: bundledItem?.action_price ?? "",
                amount: bundledItem?.amount
                  ? parseFloat(bundledItem?.amount)
                  : 0,
                amount_without_modifiers: bundledItem?.amount_without_modifiers
                  ? parseFloat(bundledItem?.amount_without_modifiers)
                  : 0,
                bundleName: bundledItem?.bundleName ?? "",
                sort: bundledItem?.sort ?? "",
                type: bundledItem?.type ?? "",
                perBundleQty: bundledItem?.perBundleQty ?? "0",
                //
                category_id: bundledItem?.category_id ?? "",
                sub_category_id: bundledItem?.sub_category_id ?? "",
                //
                bundle_menu_item_id: bundledItem?.bundle_menu_item_id
                  ? parseFloat(bundledItem?.bundle_menu_item_id)
                  : 0,
                bundle_menu_item_name: bundledItem?.bundle_menu_item_name ?? "",
                menu_item_pos_name: bundledItem?.menu_item_pos_name ?? "",
                bundledMenuitemModifers: bundleModifiers,
                comment: bundledItem?.comment,
                id: bundledItem?.id ? parseFloat(bundledItem?.id) : 0,
                is_fired: bundledItem?.is_fired == 1 ? true : false,
                is_hold: bundledItem?.is_hold == 1 ? true : false,
                fired_by: bundledItem?.fired_by ?? "",
                offlineID: bundledItem?.offlineID ?? "",
                option_series_id: bundledItem?.option_series_id
                  ? parseFloat(bundledItem?.option_series_id)
                  : 0,
                option_series_name: bundledItem?.option_series_name ?? "",
                quantity: bundledItem?.quantity
                  ? parseFloat(bundledItem?.quantity)
                  : 0,
                void: bundledItem?.void ? parseFloat(bundledItem?.void) : 0,
              };
            })
          : [];
      let item = {
        id: order?.menu_item_id,
        offline_id: order?.offlineID,
        name: order?.menu_item_name,
        pos_name: order?.pos_name,
        bundleName: order?.menu_item_name ?? "",
        image: order?.image ?? "",
        is_added: true,
        is_service_charge: order?.service_charge == 1 ? true : false,
        is_sales_tax: order?.sales_tax == 1 ? true : false,
        quantity: exactQyt,
        amount: menuItemPrice,
        amount_without_modifiers: parseFloat(menuPriceForOne),
        item_amount: menuItemPrice,
        comment: order?.comment,
        total: menuTotalPrice,
        actual_menu_price: parseFloat(order?.actual_menu_price ?? 0),
        menu_item_price: parseFloat(order?.menu_item_price ?? 0),
        is_fired: false,
        is_hold: order?.is_hold == 1 ? true : false,
        menu_item_type: order?.menu_item_type ?? "",
        menu_price: order?.menu_price ?? "",
        service_charge_amount: order?.serviceChargeAmount
          ? parseFloat(order?.serviceChargeAmount)
          : 0,
        sales_tax_amount: order?.salesTaxAmount
          ? parseFloat(order?.salesTaxAmount)
          : 0,
        catID: order?.catID ?? "",
        sub_category_id: order?.subCategoryid ?? "",
        modifierArray:
          modifierArray?.length > 0
            ? modifierArray.sort(function (a, b) {
                return a.id - b.id;
              })
            : [],
        BundledMenuItem:
          BundledMenuItemArray?.length > 0
            ? BundledMenuItemArray.sort(function (a, b) {
                return a.id - b.id;
              })
            : [],
        selectedBundleItem:
          BundledMenuItemArray?.length > 0
            ? BundledMenuItemArray.sort(function (a, b) {
                return a.id - b.id;
              })
            : [],
      };
      items.push(item);
    });
  }
  return items;
}

export const cartChangesHandler = ({
  cart_detail,
  menuItems,
  getBasketCartTotalAmount,
  userTip,
  cart_promocode,
  userDiscount,
  promoDiscount,
  totalTip,
  dispatch,
  actions,
  deliveryCharges,
  deliveryType,
  selectedLocation,
  deliveryDate,
  deliveryTime,
  totalSaleTax,
  totalServiceCharge,
  totalCartAmount,
  getBasketTotalDisAmount,
  totalDisCartAmount,
  getDeliveryFee,
  getSubsidyCharge
}) => {
  let body = getCartBody({
    recent_order_id: null,
    cart_detail,
    table_detail: menuItems,
    finalTotalAmt: getBasketCartTotalAmount(true),
    totalTip: userTip,
    applied_voucher: [],
    cart_promocode: cart_promocode,
    userDiscount: userDiscount,
    totalDiscount: userDiscount + promoDiscount,
  });
  console.log("body>>", body, totalTip);

  dispatch(
    actions.finalCartSave({
      finalCart: {
        ...body,
        tips: userTip,
        paid_amount:
          parseFloat(body?.finalTotalAmt || 0) +
          parseFloat(userTip || 0) +
          parseFloat(deliveryCharges() || 0),
        balance_due: 0,
        discount: "",
        ordrr_online: {
          order_type: deliveryType?.key,
          delivery_location_id: selectedLocation?.id,
          order_date: deliveryDate,
          order_time: deliveryTime,
          payment_intent_id: "", //
        },
      },
      cartBasicDetails: {
        totalSaleTax: totalSaleTax,
        totalServiceCharge: totalServiceCharge,
        tip: userTip,
        totalSaleTax,
        totalDiscount: userDiscount + promoDiscount,
      },
    })
  );
  dispatch(
    actions.updateCartBasicDetails({
      totalCartAmount: totalCartAmount,
      totalDisCartAmount: totalDisCartAmount,
      tip: userTip,
      totalSaleTax: totalSaleTax,
      totalServiceCharge: totalServiceCharge,
      totalDiscount: userDiscount + promoDiscount,
      totalAmount: getBasketCartTotalAmount(true),
      totalDisAmount: getBasketTotalDisAmount(true),
      deliveryFee: getDeliveryFee(),
      updated_delivery_fee: getDeliveryFee(false),
      subsidy_applied: getSubsidyCharge(),
    })
  );
};

export function getRandomString() {
  return (
    Math.random().toString(36).substring(2, 15) +
    Math.random().toString(36).substring(2, 15)
  );
}

export function percentage(percent, total, limit = 2, isDecimal = true) {
  return isDecimal
    ? parseFloat((percent / 100) * total)
    : parseRound(parseFloat((percent / 100) * total), limit, isDecimal);
}
export function howMuchPercentage(percent, total, limit = 2, isDecimal = true) {
  return isDecimal
    ? parseFloat((percent / total) * 100)
    : parseRound(parseFloat((percent / total) * 100), limit, isDecimal);
}

export function getUniTimeString() {
  const millis = Date.now();
  const time = millis / 1000;
  return time;
}

export function deepCopy(obj) {
  return JSON.parse(JSON.stringify(obj));
}

export function countDecimals(value) {
  if (value % 1 != 0) return value.toString().split(".")?.[1].length;
  return 0;
}

export function lastDecimalPlace(value) {
  if (value % 1 != 0) return parseFloat(value.toString().slice(-1));
  return 0;
}

export function addLastDigitToNum(num, value = 1) {
  if (typeof num == "number" || typeof num == "string") {
    let stringNum = typeof num == "number" ? num.toString() : num;
    num = parseFloat(stringNum + `${value}`);
  }
  return num;
}

export function padEndingZeros(num, size) {
  var s = num + "";
  while (s.length < size) s = s + "0";
  return s;
}

export function parseRound(num, digits = 2, isDecimal = false) {
  if (typeof num == "number" || typeof num == "string") {
    let stringNum = typeof num == "number" ? num.toString() : num;
    if (countDecimals(num) > 2 && lastDecimalPlace(num) == 5) {
      stringNum = addLastDigitToNum(stringNum);
    }
    num = (
      Math.round(parseFloat(stringNum) * padEndingZeros(1, digits + 1)) /
      padEndingZeros(1, digits + 1)
    ).toFixed(digits);
  }
  // console.log("testtttttttt",num,padEndingZeros(1, digits+1));
  if (isDecimal) {
    return parseFloat(num);
  }
  return num;
}

export function checkIfRepricePromo(promos) {
  let res = false;
  if (
    checkArray(promos) &&
    promos.findIndex((p) => p?.type == "Re-Price") > -1
  ) {
    res = true;
  }
  return res;
}
export function sumOfPromos(promos, sumOf = "promotionTotaldiscount") {
  let res = 0;
  if (checkArray(promos)) {
    res = promos.reduce(function (sum, current) {
      return sum + parseFloat(current?.[sumOf] ?? 0);
    }, 0);
  }
  return res;
}

const calBodyTotalSaleTax = (cart_detail, cart_promocode, userDiscount) => {
  let totalSaleItm =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.sales_tax_amount);
            }, 0)
        )
      : 0;
  const totalcartItm =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total);
        }, 0)
      : 0;
  // console.log("ratioDisss0000", checkArray(cart_promocode), userDiscount);
  if (checkArray(cart_promocode) && userDiscount > 0) {
    const ratioDis = howMuchPercentage(
      totalcartItm - userDiscount,
      totalcartItm
    );
    if (ratioDis > 0) {
      const disTotalSaleRatio = percentage(100 - ratioDis, totalSaleItm);
      // console.log("ratioDisss1111", ratioDis,disTotalSaleRatio,totalSaleItm);
      totalSaleItm = totalSaleItm - disTotalSaleRatio;
    }
    // console.log("ratioDisss2222", ratioDis,totalSaleItm);
  }
  return totalSaleItm;
};

export function getAllMenuIds(cart_details) {
  let res = [];
  if (checkArray(cart_details)) {
    const itemIds = pluckArray(cart_details, "id", true);
    let bundleMenuIds = [];
    cart_details
      .filter((p) => p?.menu_item_type == "bundled")
      .map((item) => {
        bundleMenuIds = bundleMenuIds.concat(
          pluckArray(item?.BundledMenuItem, "bundle_menu_item_id", true)
        );
      });
    res = itemIds.concat(bundleMenuIds);
    if (checkArray(res)) {
      res = res.filter((item, pos) => res.indexOf(item) == pos);
    }
  }
  return res;
}

export function getCartBody({
  recent_order_id,
  cart_detail,
  table_detail,
  applied_voucher,
  cart_promocode,
  finalTotalAmt,
  userDiscount,
}) {
  const defaultServiceCharge =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalSaleTax = calBodyTotalSaleTax(
    cart_detail,
    cart_promocode,
    userDiscount
  );
  const defaultTotalCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          const promoDis = checkIfRepricePromo(current?.promotions)
            ? 0
            : sumOfPromos(current?.promotions);
          return sum + parseFloat(current.total) + promoDis;
        }, 0)
      : 0;
  let items = [];
  const offlineId =
    recent_order_id !== undefined && recent_order_id !== ""
      ? recent_order_id
      : getRandomString();
  const isNewOrder =
    recent_order_id !== undefined && recent_order_id !== "" ? "no" : "yes";
  if (cart_detail && cart_detail.length > 0) {
    cart_detail.map((item) => {
      const modifierDetail = [];
      let discountModifierId = "";
      let discountModifierQty = null;
      if (item.modifierArray.length > 0) {
        item.modifierArray.map((modifier, index) => {
          if (modifier?.action == "discount") {
            discountModifierQty = modifier?.modifier_quantity ?? 1;
            discountModifierId = modifier?.modifier_id ?? "";
          } else {
            modifierDetail.push({
              name: modifier?.name ?? "",
              modifier_id: modifier?.modifier_id ?? "",
              modifier_group_id: modifier?.modifier_group_id ?? "",
              modifier_price: modifier?.modifier_price ?? 0,
              actual_modifier_price: modifier?.modifier_price ?? 0,
              action: modifier?.action ?? "",
              // modifier_quantity: modifier?.modifier_quantity ?? 1,
              modifier_quantity: item?.quantity ?? 1,
            });
          }
        });
      }
      let promoItem = item?.promotions;
      const amountWithoutModifiers = promoItem?.[0]?.acutalPrice
        ? parseFloat(promoItem?.[0]?.acutalPrice)
        : item?.amount_without_modifiers;
      const menuItemPrice =
        discountModifierQty !== null
          ? amountWithoutModifiers / discountModifierQty
          : amountWithoutModifiers ?? 0;

      let itemDetail = {
        BundledMenuItem:
          item?.menu_item_type == "bundled" && item?.BundledMenuItem?.length > 0
            ? item?.BundledMenuItem
            : [],
        comment: item?.comment ?? "",
        image: item?.image ?? "",
        discount: 0,
        doubleActualAmout: checkIfRepricePromo(promoItem)
          ? menuItemPrice
          : item?.amount ?? 0,
        firetime: item?.firetime ?? null,
        createDate: item?.createDate ?? "",
        is_fired: item?.is_fired ?? false,
        is_hold: item?.is_hold ?? false,
        menu_item_id: item?.id ?? "",
        menu_class_id: item?.menu_class_id ?? "",
        menu_class_name: item?.menu_class_name ?? "",
        menu_item_name: item?.name ?? "",
        pos_name: item?.pos_name,
        bundleName:
          item?.menu_item_type == "bundled" ? item?.bundleName ?? "" : "",
        discountModifierID: discountModifierId,
        menu_item_price: menuItemPrice,
        menu_item_quantity:
          discountModifierQty !== null && item?.quantity
            ? discountModifierQty * item?.quantity
            : item?.quantity ?? 0,
        quantityMultipleBy:
          discountModifierQty !== null ? discountModifierQty : 1,
        menu_item_type: item?.menu_item_type ?? "",
        offlineID: item?.offline_id,
        promo141Quantity: 0,
        promotionTotaldiscount: sumOfPromos(promoItem) ?? 0,
        promotions: promoItem ? promoItem : [],
        salesTaxAmount: item?.sales_tax_amount ?? 0,
        sales_tax: item?.is_sales_tax == true ? 1 : 0,
        serviceChargeAmount: item?.service_charge_amount ?? 0,
        service_charge: item?.is_service_charge == true ? 1 : 0,
        menu_price: item?.menu_price !== undefined ? item?.menu_price : "{}",
        subCategoryid: item?.sub_category_id ?? "",
        catID: item?.catID ?? "",
        void: 0,
        modifiers: modifierDetail?.length > 0 ? modifierDetail : [],
      };
      if (item?.menu_item_type == "bundled") {
        //
        {
          console.log(item?.bundlePosName, "abbbbbbb");
        }
        itemDetail.bundlePosName = item?.bundlePosName ?? "";
        //
        itemDetail.hide_fixed_items = item?.hide_fixed_items ?? "";
        itemDetail.hide_fixed_items_qr = item?.hide_fixed_items_qr ?? "";
        itemDetail.menu_item_price =
          discountModifierQty !== null
            ? item?.amount / discountModifierQty
            : item?.amount ?? 0;
        itemDetail.actual_menu_price =
          discountModifierQty !== null
            ? item?.actual_menu_price / discountModifierQty
            : item?.actual_menu_price ?? 0;
        itemDetail.kitchen_print_preference =
          item?.kitchen_print_preference ?? null;
      }
      // promotion changes
      if (promoItem) {
        // itemDetail obj changes
        if (checkIfRepricePromo(promoItem)) {
          if (item?.menu_item_type == "bundled") {
            itemDetail.menu_item_price =
              promoItem[0].discountPerItem +
              (item.amount - item.amount_without_modifiers);
            itemDetail.actual_menu_price = promoItem[0]?.acutalPrice;
            itemDetail.doubleActualAmout =
              promoItem[0].acutalPrice + item?.modifersPrice;
          } else {
            itemDetail.menu_item_price = promoItem[0].discountPerItem;
          }
          promoItem[0].discountPerItem = 0;
        }
        // itemDetail obj changes END
        // loyality voucher
        const voucherKey = applied_voucher.findIndex(
          (p) => p.Code == promoItem?.code
        );
        if (voucherKey > -1) {
          const voucherItem = applied_voucher[voucherKey];
          promoItem.isLoyaltyVoucher = "yes";
          promoItem.voucherName = voucherItem?.Code;
        }
        // loyality voucher END
      }
      // promotion changes END

      items.push(itemDetail);
    });
  }
  const loyalityCardName =
    applied_voucher.length > 0
      ? applied_voucher.map((voucher) => voucher?.VoucherNo)
      : [];
  const appliedVouchers =
    applied_voucher &&
    applied_voucher.filter((p) => p.Type == "Discount").length > 0
      ? applied_voucher
          .filter((p) => p.Type == "Discount")
          .map((voucher) => {
            return {
              amount: voucher?.TypeValue ?? 0,
              code: voucher?.VoucherNo ?? "",
              combo_allowed: "",
              id: getRandomString(),
              isLoyaltyVoucher: "",
              name: voucher?.Name ?? "",
              promotionTotaldiscount: voucher?.discountAmt ?? 0,
              type: "loyalty",
              voucherName: "",
            };
          })
      : [];
  const orderPromotions = [...cart_promocode, ...appliedVouchers];
  let body = {
    uuid: new DeviceUUID().get(),
    merchant_id: table_detail?.Merchant?.id ?? "",
    is_new_order: isNewOrder,
    no_of_seats: 0,
    order_offline_id: getRandomString(),
    order_time: moment().format("YYYY/MM/DD HH:mm:ss"),
    promotions: orderPromotions,
    payment_type: [],
    order_status: "new",
    is_refund: false,
    service_charge: defaultServiceCharge,
    refund: 0,
    paid_amount: 0,
    sales_tax: defaultTotalSaleTax,
    order_total: defaultTotalCartAmount,
    channel_name: "Ordrr",
    finalTotalAmt: finalTotalAmt,
    receiver: {
      customer_name: null,
      customer_phone: null,
      customer_email: "",
      customer_address: null,
    },
    menu_item: items,
  };
  return body;
}

export function parseStringToDate(
  date,
  from = "YYYY-MM-DD HH-mm-ss",
  to = "YYYY-MM-DD"
) {
  let res = undefined;
  try {
    if (date && typeof date === "string" && date !== "") {
      res = moment(date, from).format(to);
    }
  } catch (e) {}
  return res;
}

export function parseStringToFloat(num, to = 2) {
  let res = 0;
  try {
    if (num && num !== null) {
      const parseNum = parseFloat(num);
      if (parseNum) {
        res = parseNum.toFixed(to);
      }
    }
  } catch (e) {}
  return res;
}

export function groupByArray(objectArray, property) {
  return objectArray.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    // Add object to list for given key's value
    acc[key].push(obj);
    return acc;
  }, {});
}

export const imageExists = (url) =>
  new Promise(function (resolve, reject) {
    var img = new Image();
    img.src = url;

    img.onload = function () {
      resolve(url);
    };
    img.onerror = function () {
      reject(url);
    };
  });

export const scrollToDiv = (id, top = false) => {
  const element = document.getElementById(id);
  if (element) {
    element.scrollTo({
      left: 0,
      top: top ? 0 : element.scrollHeight,
      behavior: "smooth",
    });
  }
};

// export function checkItemAvailable(item, timezone = "00:00") {
//   let res = false;
//   const day_format = "YYYY-MM-DD";
//   const day_currTime = moment(new Date())
//     .utc()
//     .utcOffset(timezone)
//     .format(day_format);
//   if (
//     item?.servicePeriod?.[0]?.period_start !== "" &&
//     item?.servicePeriod?.[0]?.period_end
//   ) {
//     const beforeDay = moment(
//       item?.servicePeriod?.[0]?.period_start,
//       day_format
//     );
//     const afterDay = moment(item?.servicePeriod?.[0]?.period_end, day_format);
//     res = moment(day_currTime, day_format).isBetween(beforeDay, afterDay);
//   }
//   return res;

//   const format = "HH:mm:ss";
//   const currTime = moment(new Date())
//     .utc()
//     .utcOffset(timezone)
//     .format("HH:mm:ss");
//   const currDay = moment(new Date()).utc().utcOffset(timezone).format("ddd");

//   if (item?.applicablePeriod?.length > 0) {
//     for (let index = 0; index < item?.applicablePeriod?.length; index++) {
//       const key = Object.keys(item?.applicablePeriod[index])[0];
//       const element = item?.applicablePeriod[index][key];

//       const timeString = element?.time;
//       const dayString = element?.availableday;
//       const timeArr = timeString.split("-");
//       const dayArr = dayString.split(",");

//       if (
//         timeArr.length > 1 &&
//         dayArr.length > 0 &&
//         dayArr.findIndex((p) => p.toLowerCase()) > -1
//       ) {
//         const beforeTime = moment(timeArr?.[0], format);
//         const afterTime = moment(timeArr?.[1], format);
//         if (moment(currTime, format).isBetween(beforeTime, afterTime)) {
//           res = true;
//           break;
//         }
//       }
//     }
//   }
//   return res;
// }

// export function checkItemAvailable(item, timezone = "00:00") {
//   let res = false;
//   // const day_format = "YYYY-MM-DD";
//   // const day_currTime = moment(new Date())
//   //   .utc()
//   //   .utcOffset(timezone)
//   //   .format(day_format);
//   // if (
//   //   item?.servicePeriod?.[0]?.period_start !== "" &&
//   //   item?.servicePeriod?.[0]?.period_end
//   // ) {
//   //   const beforeDay = moment(
//   //     item?.servicePeriod?.[0]?.period_start,
//   //     day_format
//   //   );
//   //   const afterDay = moment(item?.servicePeriod?.[0]?.period_end, day_format);
//   //   res = moment(day_currTime, day_format).isBetween(beforeDay, afterDay);
//   // }
//   // return res;

//   const format = "HH:mm:ss";
//   const currTime = moment(new Date())
//     .utc()
//     .utcOffset(timezone)
//     .format("HH:mm:ss");
//   const currDay = moment(new Date())
//     .utc()
//     .utcOffset(timezone)
//     .format("ddd")
//     .toLowerCase();
//   if (item?.applicablePeriod?.length > 0) {
//     for (let index = 0; index < item?.applicablePeriod?.length; index++) {
//       const key = Object.keys(item?.applicablePeriod[index])[0];
//       const element = item?.applicablePeriod[index][key];

//       const timeString = element?.time;
//       const dayString = element?.availableday;
//       const timeArr = timeString.split("-");
//       const dayArr = dayString.split(",");
//       if (
//         timeArr.length > 1 &&
//         dayArr.length > 0 &&
//         dayArr.indexOf(currDay) > -1
//       ) {
//         const beforeTime = moment(timeArr?.[0], format);
//         const afterTime = moment(timeArr?.[1], format);
//         if (moment(currTime, format).isBetween(beforeTime, afterTime)) {
//           res = true;
//           break;
//         }
//       }
//     }
//   }
//   return res;
// }

export function checkExpireMerchantTime(date, timezone = "00:00") {
  let res = false;
  const day_format = "YYYY-MM-DD";
  const currTime = moment(new Date())
    .utc()
    .utcOffset(timezone)
    .format(day_format);
  const merchantDate = moment(date).format(day_format);
  if (currTime !== merchantDate) {
    res = true;
  }
  return res;
}

// export function checkSubCatAvailable(subCat, table_detail = null) {
//   let res = false;
//   const dates = subCat?.ApplicablePeriod;
//   const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
//   const dayFormat = "YYYY-MM-DD";
//   const timeFormat = "HH:mm:ss";
//   const currDate = moment(new Date())
//     .utc()
//     .utcOffset(timezone)
//     .format("YYYY-MM-DD");
//   const currTime = moment(new Date())
//     .utc()
//     .utcOffset(timezone)
//     .format("HH:mm:ss");
//   const currDay = moment(new Date()).utc().utcOffset(timezone).format("dddd");

//   if (dates && dates !== null) {
//     let holidayVerified = true;
//     if (
//       table_detail?.TodayHoliday === true ||
//       table_detail?.EveHoliday === true
//     ) {
//       holidayVerified = false;
//       if (
//         table_detail?.TodayHoliday === true &&
//         dates?.public_holiday == "Yes"
//       ) {
//         holidayVerified = true;
//       }
//       if (
//         table_detail?.EveHoliday === true &&
//         dates?.eve_public_holiday == "Yes"
//       ) {
//         holidayVerified = true;
//       }
//     }
//     if (holidayVerified && dates?.length > 0) {
//       for (let index = 0; index < dates?.length; index++) {
//         const element = dates[index];
//         const dayArr = element?.applicable_days;

//         if (
//           element?.start_time &&
//           element?.end_time &&
//           element?.start_date &&
//           element?.end_date &&
//           dayArr.length > 0 &&
//           dayArr.indexOf(currDay) > -1
//         ) {
//           const beforeDay = moment(element?.start_date, dayFormat);
//           const afterDay = moment(element?.end_date, dayFormat);
//           const beforeTime = moment(element?.start_time, timeFormat);
//           const afterTime = moment(element?.end_time, timeFormat);
//           if (
//             moment(currDate, dayFormat).isBetween(beforeDay, afterDay) &&
//             moment(currTime, timeFormat).isBetween(beforeTime, afterTime)
//           ) {
//             res = true;
//             break;
//           }
//         }
//       }
//     } else {
//       res = true;
//     }
//   }
//   return res;
// }

export function checkSubCatAvailable(
  subCat,
  table_detail = null,
  checkDateTime = null
) {
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
  checkDateTime = checkDateTime === null ? new Date() : checkDateTime;
  let res = false;
  const dates = subCat?.ApplicablePeriod;
  const dayFormat = "yyyy-MM-DD";
  const timeFormat = "HH:mm:ss";
  const currDateTime =
    checkDateTime === null
      ? moment(checkDateTime)
          .utc()
          .utcOffset(timezone)
          .format("yyyy-MM-DD HH:mm:ss")
      : moment(checkDateTime).format("yyyy-MM-DD HH:mm:ss");
  const currDate =
    checkDateTime === null
      ? moment(checkDateTime).utc().utcOffset(timezone).format("yyyy-MM-DD")
      : moment(checkDateTime).format("yyyy-MM-DD");
  const currTime =
    checkDateTime === null
      ? moment(checkDateTime).utc().utcOffset(timezone).format("HH:mm:ss")
      : moment(checkDateTime).format("HH:mm:ss");
  const currDay =
    checkDateTime === null
      ? moment(checkDateTime).utc().utcOffset(timezone).format("dddd")
      : moment(checkDateTime).format("dddd");

  if (dates && dates !== null) {
    let holidayVerified = true;
    if (
      table_detail?.TodayHoliday === true ||
      table_detail?.EveHoliday === true
    ) {
      holidayVerified = false;
      if (
        table_detail?.TodayHoliday === true &&
        dates?.public_holiday == "Yes"
      ) {
        holidayVerified = true;
      }
      if (
        table_detail?.EveHoliday === true &&
        dates?.eve_public_holiday == "Yes"
      ) {
        holidayVerified = true;
      }
    }
    if (holidayVerified && dates?.length > 0) {
      for (let index = 0; index < dates?.length; index++) {
        const element = dates[index];
        const dayArr = element?.applicable_days;

        if (
          element?.start_time &&
          element?.end_time &&
          element?.start_date &&
          element?.end_date &&
          dayArr.length > 0 &&
          dayArr.indexOf(currDay) > -1
        ) {
          const beforeDay = moment(element?.start_date, dayFormat);
          const afterDay = moment(element?.end_date, dayFormat);
          let beforeTime = moment(element?.start_time, timeFormat);
          let afterTime = moment(element?.end_time, timeFormat);
          if (beforeTime.isAfter(afterTime)) {
            // if before time is greater then after time add one day
            const fullFormat = "yyyy-MM-DD HH:mm:ss";
            beforeTime = moment(`${currDate} ${beforeTime.format(timeFormat)}`);
            afterTime = moment(
              `${moment(currDate, dayFormat)
                .add(1, "days")
                .format(dayFormat)} ${beforeTime.format(timeFormat)}`
            );
            if (
              moment(currDate, dayFormat).isBetween(beforeDay, afterDay) &&
              moment(currDateTime, fullFormat).isBetween(beforeTime, afterTime)
            ) {
              res = true;
              break;
            }
          } else {
            if (
              moment(currDate, dayFormat).isBetween(beforeDay, afterDay) &&
              moment(currTime, timeFormat).isBetween(beforeTime, afterTime)
            ) {
              res = true;
              break;
            }
          }
        }
      }
    } else {
      res = true;
    }
  }
  if (
    !(
      res &&
      checkArray(subCat?.MenuItem) &&
      subCat?.MenuItem.findIndex((p) =>
        checkItemAvailable(p, timezone, table_detail, checkDateTime)
      ) > -1
    )
  ) {
    res = false;
  }
  return res;
}

export function checkArray(arr, res = true, val = false) {
  return arr && Array.isArray(arr) && arr.length > 0 ? res : val;
}

export function checkNull(string, val = "") {
  if (typeof string == "number") {
    string = string.toString();
  }

  return string && string !== "" && string !== null ? string : val;
}

export const isPastFromToday = (date, includeToday = false) => {
  let res;
  if (checkNull(date, false)) {
    try {
      if (includeToday) res = moment(date).isSameOrBefore(moment(), "day");
      else res = moment(date).isBefore(moment(), "day");
    } catch (error) {}
  }
  return res;
};

export const getServerImgUrl = (url) => {
  const base_url = process.env.REACT_APP_API_URL;
  let res = null;
  if (typeof url === "string") {
    res = url.replace("https://localhost", base_url);
  }
  return res;
};

export const isFutureFromToday = (date, includeToday = false) => {
  let res;
  if (checkNull(date, false)) {
    try {
      if (includeToday) res = moment(date).isSameOrAfter(moment(), "day");
      else res = moment(date).isAfter(moment(), "day");
    } catch (error) {}
  }
  return res;
};

export function checkStoreAvailable(
  store,
  timezone = "00:00",
  checkDateTime = null
) {
  const dates = checkArray(store?.ApplicablePeriod)
    ? store?.ApplicablePeriod
    : [];
  const holidayArr = store?.["Holidays"] ?? null;

  checkDateTime =
    checkDateTime === null
      ? moment(moment().format("yyyy-MM-DD 00:00:00")).toDate()
      : checkDateTime;
  let res = { index: -1, startTime: null, endTime: null };
  const dayFormat = "yyyy-MM-DD";
  const timeFormat = "HH:mm:ss";
  const format = "yyyy-MM-DD HH:mm:ss";
  const currDate = moment(checkDateTime)
    .utc()
    .utcOffset(timezone)
    .format("yyyy-MM-DD");
  const currDateTime = moment(checkDateTime)
    .utc()
    .utcOffset(timezone)
    .format("yyyy-MM-DD HH:mm:ss");
  const currTime = moment(checkDateTime)
    .utc()
    .utcOffset(timezone)
    .format("HH:mm:ss");
  const currDay = moment(checkDateTime)
    .utc()
    .utcOffset(timezone)
    .format("dddd");

  if (dates && dates !== null) {
    if (dates?.length > 0) {
      for (let index = 0; index < dates?.length; index++) {
        const element = dates[index];
        const dayString = element?.applicable_days;
        const timeArr = [element?.start_datetime, element?.end_datetime];
        const dayArr = dayString.split(",");
        const currHoliday = holidayArr?.[currDate] ?? undefined;
        let holidayVerified = false;
        if (
          (currHoliday && currHoliday?.holiday === true) ||
          currHoliday?.eve_holiday === true
        ) {
          holidayVerified = false;
          if (currHoliday?.holiday === true && element?.public_holiday == "1") {
            holidayVerified = true;
          }
          if (
            currHoliday?.eve_holiday === true &&
            element?.eve_public_holiday == "1"
          ) {
            holidayVerified = true;
          }
        }
        if (
          timeArr.length > 1 &&
          element?.start_datetime &&
          element?.end_datetime &&
          ((dayArr.length > 0 && dayArr.indexOf(currDay) > -1) ||
            holidayVerified)
        ) {
          const beforeDay = moment(
            element?.start_datetime.split(" ")?.[0],
            dayFormat
          );
          const afterDay = moment(
            element?.end_datetime.split(" ")?.[0],
            dayFormat
          );
          let beforeTime = moment(
            element?.start_datetime.split(" ")?.[1],
            timeFormat
          );
          let afterTime = moment(
            element?.end_datetime.split(" ")?.[1],
            timeFormat
          );
          if (
            moment(currDate, dayFormat).isBetween(
              beforeDay,
              afterDay,
              null,
              "[]"
            )
          ) {
            res = {
              index: index,
              startTime: beforeTime.format(timeFormat),
              endTime: afterTime.format(timeFormat),
            };
            break;
          }
        }
      }
    }
  }
  return res;
}

export function checkItemAvailable(
  item,
  timezone = "00:00",
  table_detail = null,
  checkDateTime = null
) {
  let res = false;
  // const day_format = "YYYY-MM-DD";
  // const day_currTime = moment(new Date())
  //   .utc()
  //   .utcOffset(timezone)
  //   .format(day_format);
  // if (
  //   item?.servicePeriod?.[0]?.period_start !== "" &&
  //   item?.servicePeriod?.[0]?.period_end
  // ) {
  //   const beforeDay = moment(
  //     item?.servicePeriod?.[0]?.period_start,
  //     day_format
  //   );
  //   const afterDay = moment(item?.servicePeriod?.[0]?.period_end, day_format);
  //   res = moment(day_currTime, day_format).isBetween(beforeDay, afterDay);
  // }
  // return res;

  checkDateTime = checkDateTime === null ? new Date() : checkDateTime;
  const format = "HH:mm:ss";
  const currTime =
    checkDateTime === null
      ? moment(checkDateTime).utc().utcOffset(timezone).format("HH:mm:ss")
      : moment(checkDateTime).format("HH:mm:ss");
  const currDay =
    checkDateTime === null
      ? moment(checkDateTime)
          .utc()
          .utcOffset(timezone)
          .format("ddd")
          .toLowerCase()
      : moment(checkDateTime).format("ddd").toLowerCase();
  let isInTimeSlot = false;
  // if (item?.id == "22063") {
  //   console.log(
  //     "testttttttttttttt:",
  //     beforeTime.format(format),
  //     afterTime.format(format),
  //     currTime
  //   );
  // }
  if (checkArray(item?.applicablePeriod)) {
    for (let index = 0; index < item?.applicablePeriod?.length; index++) {
      const key = Object.keys(item?.applicablePeriod[index])[0];
      const element = item?.applicablePeriod[index][key];

      const timeString = element?.time;
      const dayString = element?.availableday;
      const timeArr = timeString.split("-");
      const dayArr = dayString.split(",");

      if (timeArr.length > 1) {
        const beforeTime = moment(timeArr?.[0], format);
        const afterTime = moment(timeArr?.[1], format);
        if (
          moment(currTime, format).isBetween(beforeTime, afterTime, null, "[]")
        ) {
          isInTimeSlot = true;
          if (dayArr.length > 0 && dayArr.indexOf(currDay) > -1) {
            res = true;
            break;
          }
        }
      }
    }
  } else {
    res = true;
  }

  if (
    !res &&
    !isInTimeSlot &&
    table_detail?.service_period?.period_name &&
    item?.applicablePeriod.findIndex((p) => {
      let curRes = false;
      const conDayStr =
        p?.[table_detail?.service_period?.period_name]?.availableday;
      const curDayArr = conDayStr ? conDayStr.split(",") : [];
      // if (item?.id == "15561") {
      //   console.log(
      //     "checkkkkkkk",
      //     curDayArr,
      //     currDay,
      //     table_detail?.service_period?.period_name
      //   );
      // }
      if (curDayArr.indexOf(currDay) > -1) curRes = true;
      return curRes;
    }) > -1
  ) {
    res = true;
  }
  return res;
}

export function checkVoucherAvailable(
  promoObj,
  table_detail = null,
  checkDateTime = null
) {
  const timezone = table_detail?.Merchant?.timezone_offset ?? "00:00";
  checkDateTime = checkDateTime === null ? new Date() : checkDateTime;
  const dates = promoObj?.datetime;
  let res = false;
  const dayFormat = "yyyy-MM-DD";
  const timeFormat = "HH:mm:ss";
  const format = "yyyy-MM-DD HH:mm:ss";
  const currDate =
    checkDateTime === null
      ? moment(checkDateTime).utc().utcOffset(timezone).format("yyyy-MM-DD")
      : moment(checkDateTime).format("yyyy-MM-DD");
  const currDateTime =
    checkDateTime === null
      ? moment(checkDateTime)
          .utc()
          .utcOffset(timezone)
          .format("yyyy-MM-DD HH:mm:ss")
      : moment(checkDateTime).format("yyyy-MM-DD HH:mm:ss");
  const currTime =
    checkDateTime === null
      ? moment(checkDateTime).utc().utcOffset(timezone).format("HH:mm:ss")
      : moment(checkDateTime).format("HH:mm:ss");
  const currDay =
    checkDateTime === null
      ? moment(checkDateTime).utc().utcOffset(timezone).format("dddd")
      : moment(checkDateTime).format("dddd");
  // if (promoObj?.id == "452") {
  //   console.log(
  //     "promotioncheckkkkkkkkk333333:",
  //     checkDateTime,
  //     currDay,
  //     currTime,
  //     currDateTime
  //   );
  // }
  if (dates && dates !== null) {
    let holidayVerified = false;
    if (
      table_detail?.TodayHoliday === true
      // || table_detail?.EveHoliday === true
    ) {
      holidayVerified = false;
      if (
        table_detail?.TodayHoliday === true &&
        dates?.public_holiday == "Yes"
      ) {
        holidayVerified = true;
      }
      // if (
      //   table_detail?.EveHoliday === true &&
      //   dates?.eve_public_holiday == "Yes"
      // ) {
      //   holidayVerified = true;
      // }
    }
    if (dates?.length > 0) {
      for (let index = 0; index < dates?.length; index++) {
        const element = dates[index];
        const dayString = element?.days;
        const timeArr = [element?.start_datetime, element?.end_datetime];
        const dayArr = dayString.split(",");
        // console.log("elementelement", dayArr.indexOf(currDay), currDay, dayArr);

        if (
          timeArr.length > 1 &&
          element?.start_datetime &&
          element?.end_datetime &&
          ((dayArr.length > 0 && dayArr.indexOf(currDay) > -1) ||
            holidayVerified)
        ) {
          const beforeDay = moment(
            element?.start_datetime.split(" ")?.[0],
            dayFormat
          );
          const afterDay = moment(
            element?.end_datetime.split(" ")?.[0],
            dayFormat
          );
          let beforeTime = moment(
            element?.start_datetime.split(" ")?.[1],
            timeFormat
          );
          let afterTime = moment(
            element?.end_datetime.split(" ")?.[1],
            timeFormat
          );
          if (beforeTime.isAfter(afterTime)) {
            // if before time is greater then after time add one day
            const fullFormat = "yyyy-MM-DD HH:mm:ss";
            beforeTime = moment(`${currDate} ${beforeTime.format(timeFormat)}`);
            afterTime = moment(
              `${moment(currDate, dayFormat)
                .add(1, "days")
                .format(dayFormat)} ${beforeTime.format(timeFormat)}`
            );
            if (
              moment(currDate, dayFormat).isBetween(
                beforeDay,
                afterDay,
                null,
                "[]"
              ) &&
              moment(currDateTime, fullFormat).isBetween(
                beforeTime,
                afterTime,
                null,
                "[]"
              )
            ) {
              res = true;
              break;
            }
          } else {
            if (
              moment(currDate, dayFormat).isBetween(
                beforeDay,
                afterDay,
                null,
                "[]"
              ) &&
              moment(currTime, timeFormat).isBetween(
                beforeTime,
                afterTime,
                null,
                "[]"
              )
            ) {
              res = true;
              break;
            }
          }
        }
      }
    } else {
      res = true;
    }
  }
  return res;
}

// Axios API call

// let getToken = async () => await AsyncStorage.getItem("access-token");
let getToken;

const client = axios.create({
  baseURL: "",
  // auth: { Authorization: "Bearer " + { getToken } },
});

export const apiCall = function (method, route, body = null, token = null) {
  getToken = token;
  const onSuccess = function (response) {
    console.debug("Request Successful!", response);
    return response.data;
  };

  const onError = function (error) {
    console.error("Request Failed:", error.config);

    if (error.response) {
      // Request was made but server responded with something
      // other than 2xx
      console.error("Status:", error.response.status);
      console.error("Data:", error.response.data);
      console.error("Headers:", error.response.headers);
    } else {
      // Something else happened while setting up the request
      // triggered the error
      console.error("Error Message:", error.message);
    }

    return Promise.reject(error.response || error.message);
  };

  let reqObj = {
    method,
    url: `${route}`,
    data: body,
  };
  if (token) {
    client.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    client.defaults.headers.common["Content-Type"] = `application/json`;
    client.defaults.headers.common[
      "SoapAction"
    ] = `https://matrixapis/JSONCommand2`;
    console.log("common-headers", client.defaults.headers.common);

    // client.defaults.headers.common["origin"] = `http://localhost:3000`;
    // client.defaults.headers.common[
    //   "Content-Type"
    // ] = `application/x-www-form-urlencoded`;
    // client.defaults.proxy.host = "http://www.demoapp.com";
    // reqObj.headers = {
    //   // "Content-Type": `application/json`,
    //   // Accept: `application/json`,
    //   Authorization: `Bearer ${token}`,
    //   // SoapAction: "https://matrixapis/JSONCommand2",
    //   // "Access-Control-Allow-Credentials": "true",
    //   // "Access-Control-Allow-Methods":
    //   //   "GET, POST, PATCH, PUT, DELETE, OPTIONS, HEAD",
    //   // "Access-Control-Allow-Origin": "*",
    //   // "Access-Control-Allow-Headers":
    //   //   "Origin, X-Requested-With, Content-Type, Accept",
    // };
  }
  console.log("reqObj", reqObj);

  return client(reqObj).then(onSuccess).catch(onError);
};
