import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
};

const loaderReducer = (state = initialState, action) => {
  const { type } = action;
  switch (type) {
    case actionTypes.CLEAR_DATA:
      return initialState
    case actionTypes.START_LOADING:
      return {
        ...state,
        loading: true
      };
    case actionTypes.STOP_LOADING:
      return {
        ...state,
        loading: false
      };
    default:
      return state;
  }
};
export default loaderReducer;
