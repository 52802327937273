import React from "react";
import { useNavigate } from "react-router-dom";
import { ordrrLogo } from "../../Assets";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import Button from "@mui/material/Button";
import FloatingInput from "../Components/FloatingInput";


const Login = () => {
  const authReducer = useSelector((state) => state.authReducer);
  // const history = useHistory();
  const navigate = useNavigate()
  const {
    register,
    formState: { errors },
    handleSubmit,
    control,
  } = useForm();

  const onSubmit = (data) => {
    // toggleOtpStepOne(true);
    navigate('/email')
  };


  return (
    <React.Fragment>
      <div className="wrapper colorBg">
        <section className="login-section row mx-0">
          <div className="logo-sec">
            <figure>
              <img src={ordrrLogo} alt="" />
            </figure>
          </div>
          <form className="col-12" onSubmit={handleSubmit(onSubmit)}>
            <div className="group">
              <FloatingInput
                label="USERNAME"
                name="username"
                beforeColor="#fff"
                afterColor="#3e38a1"
                labelColor="#fff"
              />

              {errors.username && errors.username.type === "required" && (
                <p className="errorMsg">username is required.</p>
              )}
            </div>

            <div className="group pass-input">
              <FloatingInput
                label="PASSWORD"
                name="password"
                type="password"
                beforeColor="#fff"
                afterColor="#3e38a1"
                labelColor="#fff"
              />

              {errors.password && errors.password.type === "required" && (
                <p className="errorMsg">Password is required.</p>
              )}
              {errors.password && errors.password.type === "minLength" && (
                <p className="errorMsg">
                  Password should be at-least 6 characters.
                </p>
              )}
            </div>
            <div className="group sub-btn mb-0">
              <Button
                variant="contained"
                type="submit"
                className="w-100"
                style={{ backgroundColor: `${authReducer?.btn_first_color}` }}
              >
                NEXT
              </Button>
            </div>
            <div className="forgot-password">
              <a href="#" onClick={() => { }}>
                FORGOT PASSWORD?
              </a>
            </div>
          </form>
        </section>
      </div>
    </React.Fragment>
  );
};
export default Login;
