import moment from "moment";
import { toast } from "react-toastify";
import { call, put, select, takeLatest } from "redux-saga/effects";
import { checkArray, getAllMenuIds } from "../../helpers";
import { paymentServices } from "../../services";
import * as actionTypes from "../actionTypes";

function* paymentIntent(action) {
  const { data } = yield call(paymentServices.paymentIntent, action.payload);
  console.log("paymentIntent", data);
  if (data.status) {
    // toast.success("Payment Successful");
    yield put({
      type: actionTypes.PAYMENT_INTENT_SUCCEEDED,
      payload: {
        paymentIntent: data.data,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.PAYMENT_INTENT_FAILED,
      payload: { error: data.message },
    });
  }
}
function* paymentToken(action) {
  const { data } = yield call(paymentServices.paymentToken, action.payload);
  console.log("paymentToken", data);

  if (data.status) {
    yield put({
      type: actionTypes.PAYMENT_TOKEN_SUCCEEDED,
      payload: {
        paymentTokenData: data,
        paymentTokengenerated: true,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.PAYMENT_TOKEN_FAILED,
      payload: { error: data.message },
    });
  }
}

function* confirmPayment(action) {
  const { data } = yield call(paymentServices.confirmPayment, action.payload);
  console.log("confirmPayment", data);

  if (data.status) {
    toast.success("Payment Successful");
    yield put({
      type: actionTypes.CONFIRM_PAYMENT_REQUESTED,
      payload: {
        confirmPaymentDetails: data,
        paymentTokengenerated: false,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.CONFIRM_PAYMENT_FAILED,
      payload: { error: data.message },
    });
  }
}

function* trackOrder(action) {
  const { data } = yield call(paymentServices.trackOrder, action.payload);
  if (data.status) {
    yield put({
      type: actionTypes.TRACK_ORDER_DETAILS_SUCCEEDED,
      payload: {
        trackOrder: data?.data,
      },
    });
    if (action.isEmail === true) {
      yield put({
        type: actionTypes.PLACE_ORDER_SUCCEEDED,
        payload: {
          orderDetails: data?.data?.data ?? null,
          orderPlaced: true,
        },
      });
    }
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.TRACK_ORDER_DETAILS_FAILED,
      payload: { error: data.message },
    });
  }
}

function* whereIsMyOrder(action) {
  const { data } = yield call(paymentServices.whereIsMyOrder, action.payload);
  if (data.status) {
    console.log("whereIsMyOrder", data);
    yield put({
      type: actionTypes.WHERE_IS_MY_ORDER_SUCCEEDED,
      payload: {
        whereIsMyOrder: { message: data?.message },
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.WHERE_IS_MY_ORDER_FAILED,
      payload: { error: data.message },
    });
  }
}
function* quantityCheckedDetails(action) {
  const deliveryDate = yield select(
    (store) => store?.deliveryOptionsReducer?.deliveryDate
  );
  const cartItems = yield select((store) => store?.cartReducer?.cartItems);
  const storeDetails = yield select(
    (store) => store?.storeReducer?.storeDetails
  );
  const menuIds = getAllMenuIds(cartItems);
  const payload = {
    merchant_id: storeDetails?.Merchant?.id,
    date: moment(deliveryDate).format("yyyy-MM-DD"),
    menu_item_ids: menuIds,
  };
  const { data } = yield call(paymentServices.quantityCheckedDetails, payload);
  if (data.status) {
    console.log("quantityCheckedDetails", data);
    // yield put({
    //   type: actionTypes.CHECK_QUANTITY_SUCCEEDED,
    // });
    const quotaItems = data?.data ?? [];
    if (
      checkArray(quotaItems) &&
      quotaItems.findIndex((p) => p?.is_86_item == "yes" || p?.quota !== "") >
        -1
    ) {
      let errorMsg = "";
      let isErrorShown = false;
      cartItems.map((item) => {
        const prefix = errorMsg !== "" ? " " : "";
        // Standard
        const checkExistIdx = quotaItems.findIndex((p) => {
          console.log(
            "checkExistIdx1111",
            item.id == p?.menu_item_id,
            p?.is_86_item == "yes",
            p?.quota !== "" && parseInt(p?.quota) < item.quantity
          );
          return (
            item.id == p?.menu_item_id &&
            (p?.is_86_item == "yes" ||
              (p?.quota !== "" && parseInt(p?.quota) < item.quantity))
          );
        });
        console.log("checkExistIdx", checkExistIdx);
        if (checkExistIdx > -1) {
          isErrorShown = true;
          const checkQuotaItem = quotaItems[checkExistIdx];
          if (checkQuotaItem?.is_86_item == "yes") {
            errorMsg =
              errorMsg +
              prefix +
              `"${item?.pos_name}" is no longer available for order.`;
          } else if (
            checkQuotaItem?.quota !== "" &&
            parseInt(checkQuotaItem?.quota) < item.quantity
          ) {
            errorMsg =
              errorMsg +
              prefix +
              `Only "${parseInt(
                checkQuotaItem?.quota
              )}" units are available to order for "${
                item?.pos_name
              }" at the moment.`;
          }
        }
        // Bundled Components
        const checkComExistIdx = quotaItems.findIndex(
          (p) =>
            item?.BundledMenuItem &&
            checkArray(item?.BundledMenuItem) &&
            item?.BundledMenuItem.findIndex(
              (bunItem) =>
                bunItem.bundle_menu_item_id == p?.menu_item_id &&
                (p?.is_86_item == "yes" ||
                  (p?.quota !== "" && parseInt(p?.quota) < bunItem.quantity))
            ) > -1
        );
        if (!isErrorShown && checkComExistIdx > -1) {
          isErrorShown = true;
          const checkQuotaItem = quotaItems[checkComExistIdx];
          const bunItemIdx = item?.BundledMenuItem.findIndex(
            (bunItem) =>
              bunItem.bundle_menu_item_id == checkQuotaItem?.menu_item_id &&
              (checkQuotaItem?.is_86_item == "yes" ||
                (checkQuotaItem?.quota !== "" &&
                  parseInt(checkQuotaItem?.quota) < bunItem.quantity))
          );
          if (bunItemIdx > -1) {
            item?.BundledMenuItem?.map((bunItemObj) => {
              if (
                bunItemObj.bundle_menu_item_id == checkQuotaItem?.menu_item_id
              ) {
                if (checkQuotaItem?.is_86_item == "yes") {
                  errorMsg =
                    errorMsg +
                    prefix +
                    `"${bunItemObj?.bundle_menu_item_name}" of ${item?.pos_name} is no longer available for order.`;
                } else if (
                  checkQuotaItem?.quota !== "" &&
                  parseInt(checkQuotaItem?.quota) < bunItemObj.quantity
                ) {
                  errorMsg =
                    errorMsg +
                    prefix +
                    `Only "${parseInt(
                      checkQuotaItem?.quota
                    )}" units are available to order for "${
                      bunItemObj?.bundle_menu_item_name
                    }" of ${item?.pos_name} at the moment.`;
                }
              }
            });
          }
        }
      });
      if (isErrorShown) {
        yield put({
          type: actionTypes.CHECK_QUANTITY_FAILED,
          payload: {
            showError: true,
            errorMsg: errorMsg,
          },
        });
      }else{
        yield put({
          type: actionTypes.CHECK_QUANTITY_SUCCEEDED,
        });
      }
    } else {
      yield put({
        type: actionTypes.CHECK_QUANTITY_SUCCEEDED,
      });
    }
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.CHECK_QUANTITY_FAILED,
      payload: { error: data.message },
    });
  }
}

function* paymentSaga() {
  yield takeLatest(actionTypes.PAYMENT_INTENT_REQUESTED, paymentIntent);
  yield takeLatest(actionTypes.PAYMENT_TOKEN_REQUESTED, paymentToken);
  yield takeLatest(actionTypes.CONFIRM_PAYMENT_REQUESTED, confirmPayment);
  yield takeLatest(actionTypes.TRACK_ORDER_DETAILS_REQUESTED, trackOrder);
  yield takeLatest(actionTypes.WHERE_IS_MY_ORDER_REQUESTED, whereIsMyOrder);
  yield takeLatest(
    actionTypes.CHECK_QUANTITY_REQUESTED,
    quantityCheckedDetails
  );
}

export default paymentSaga;
