import React from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { backIcon, ordrrLogoNew } from "../../Assets";
import { StyledSVG } from "../../Utils/style";

function Header({ title, backLink, backFunc = false, backName, hideUpper, ...props }) {
  const { storeDetails, outletDetails } = useSelector(
    (state) => state.storeReducer
  );
  const selectedStore = useSelector(
    (state) => state.deliveryOptionsReducer?.selectedStore
  );
  // const history = useHistory();
  const navigate = useNavigate();

  return (
    <header {...props}>
      {outletDetails ? (
        <div
          className={`hotel_name w-100 d-flex flex-wrap ${
            backLink !== undefined ? "con_back" : ""
          } ${hideUpper ? "hideUpper" : ""}`}
        >
          {hideUpper !== true && (
            <div className="col-3  col-lg-1 col-md-2 pr-0">
              <div className="circle-img">
                <figure className="logo">
                  <img
                    src={selectedStore?.logo || storeDetails?.Merchant?.logo}
                    alt=""
                  />
                </figure>
              </div>
            </div>
          )}
          {hideUpper !== true && (
            <div className="col-9 col-lg-11 col-md-10 pl-0">
              <div className="naming-sec">
                {title === undefined && <p> Welcome to </p>}
                <h4>
                  {" "}
                  {selectedStore?.account_name ||
                    storeDetails?.Merchant?.account_name}{" "}
                </h4>
                {title !== undefined && <p className="title_text"> {title} </p>}
                {/* <p className="powered_text">
                  {" "}
                  Powered By{" "}
                  <span className="powered_logo">
                    {" "}
                    <img src={ordrrLogoNew} />
                  </span>
                </p> */}
              </div>
            </div>
          )}
          {backLink !== undefined && (
            <div
              className={`col-12 back_link_con ${hideUpper ? "hideUpper" : ""}`}
            >
              <div className="d-flex align-items-center">
                <figure
                  onClick={() =>{
                    // typeof backLink == "string"
                    //   ? history.push(backLink)
                    //   : backLink()
                    if(backFunc !== false){
                      backFunc()
                    }else{
                      navigate(-1);
                    }
                  }
                  }
                >
                  {/* <StyledSVG
                    color={outletDetails?.settings?.brand_highlight_color}
                    src={backIcon}
                    width="10"
                  /> */}
                  <span
                    style={{
                      color: outletDetails?.settings?.brand_highlight_color,
                    }}
                    className="icon-Component-1--7"
                  ></span>
                </figure>
                <span
                  style={{
                    color: outletDetails?.settings?.brand_highlight_color,
                  }}
                  onClick={() => {
                    // typeof backLink == "string"
                    //   ? history.push(backLink)
                    //   : backLink()
                    if(backFunc !== false){
                      backFunc()
                    }else{
                      navigate(-1);
                    }
                  }}
                  className="back_link"
                >
                  {backName}
                </span>
              </div>
            </div>
          )}
        </div>
      ) : null}
    </header>
  );
}

export default Header;
