import * as actionTypes from '../actionTypes';

export const changeOutletModal = (type = false) => ({
    type: actionTypes.OUTLET_MODAL,
    payload: { showOutletModal: type }
});

export const saveSeletedStoreData = (data = {}) => ({
    type: actionTypes.SAVE_SELECTED_STORE_DATA_SUCCEEDED,
    payload: { selectedStore: data }
});

export const toggleDisableBtn = (data = {}) => ({
    type: actionTypes.TOGGLE_DISABLE_BTN,
    payload: { disableBtn: data }
});
export const toggleConfirmBtn = (data = {}) => ({
    type: actionTypes.TOGGLE_CONFIRM_BTN,
    payload: { confirmBtnClick: data }
});

export const saveSeletedLocation = (data = {}) => ({
    type: actionTypes.SAVE_SELECTED_LOCATION,
    payload: { selectedLocation: data }
});

export const saveDeliveryType = payload => ({ type: actionTypes.DELIVERY_TYPE, payload });


export const addLocation = payload => ({ type: actionTypes.ADD_LOCATIONS_REQUESTED, payload });
export const getLocation = () => ({ type: actionTypes.GET_LOCATIONS_REQUESTED });
export const getStores = payload => ({ type: actionTypes.GET_STORES_REQUESTED, payload });
export const riderNote = payload=>({type: actionTypes.RIDER_NOTE,payload})

export const deliveryTime = payload => ({type: actionTypes.DELIVERY_TIME, payload})
export const deliveryDate = payload => ({type: actionTypes.DELIVERY_DATE, payload})

export const updateAddress = payload => ({type:actionTypes.UPDATE_ADDRESS, payload})

export const setAddressError = payload => ({type: actionTypes.SET_ADDRESS_ERROR, payload});
export const noOutletModal = payload => ({type: actionTypes.NO_OUTLET_MODAL, payload});