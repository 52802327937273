// import * as actionTypes from "../actionTypes";

import { actionTypes } from "..";
import { toast } from "react-toastify";
import moment from "moment";
const initialState = {
  locations: [],
  outlets: [],
  addLocationsSucceeded: false,
  showOutletModal: false,
  selectedStore: null,
  selectedLocation: null,
  deliveryDate: moment().format("YYYY/MM/DD"),
  deliveryTime: null,
  error: false,
  noOutletModal: false,
  disableBtn: false,
  confirmBtnClick: false,
};

const deliveryOptionsReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;

  switch (type) {
    case actionTypes.CLEAR_DATA:
      return initialState;
    case actionTypes.ADD_LOCATIONS_SUCCEEDED:
    case actionTypes.GET_LOCATIONS_SUCCEEDED:
    case actionTypes.GET_LOCATIONS_FAILED:
    case actionTypes.ADD_LOCATIONS_FAILED:
    case actionTypes.GEOCOADING_SUCCEEDED:
    case actionTypes.GEOCOADING_FAILED:
    case actionTypes.GET_STORES_SUCCEEDED:
    case actionTypes.GET_STORES_FAILED:
    case actionTypes.OUTLET_MODAL:
    case actionTypes.SAVE_SELECTED_STORE_DATA_SUCCEEDED:
    case actionTypes.SAVE_SELECTED_LOCATION:
    case actionTypes.DELIVERY_TYPE:
    case actionTypes.TOGGLE_DISABLE_BTN:
    case actionTypes.TOGGLE_CONFIRM_BTN:
      console.log("reducer");
      return {
        ...state,
        ...payload,
        loading: false,
      };

    case actionTypes.RIDER_NOTE: {
      return { ...state, riderNote: payload };
    }
    case actionTypes.DELIVERY_DATE:
      return { ...state, deliveryDate: payload };

    case actionTypes.DELIVERY_TIME:
      return { ...state, deliveryTime: payload };

    case actionTypes.UPDATE_ADDRESS:
      toast.success("Address updated successfully");
      console.log("====================================");
      console.log(state.locations[payload - 1], payload);
      console.log("====================================");

      return { ...state, selectedLocation: state.locations[payload - 1] };

    case actionTypes.SET_ADDRESS_ERROR:
      return { ...state, error: payload };

    case actionTypes.NO_OUTLET_MODAL:
      return { ...state, noOutletModal: payload };
    default:
      return state;
  }
};
export default deliveryOptionsReducer;
