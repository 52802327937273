import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useSwipeable, config } from "react-swipeable";
import Collapse from "@mui/material/Collapse";
import Paper from "@mui/material/Paper";
import { Button, Input, InputLabel } from "@mui/material";
import L from "leaflet";
import { MapContainer, Marker, Popup, TileLayer } from "react-leaflet";

import Header from "../Components/Header";
import logo from "../../Assets/images/location.png";
import cartImg from "../../Assets/images/cartImg.svg";
import {
  APPLY_VOUCHER_DETAIL_RECEIVED,
  TOGGLE_CART_PAGE,
} from "../../Redux/actionTypes";
import {
  getSelectedItemDetail,
  orderPlaceDetail,
  quantityCheckedDetails,
  showNotificationWithTimeout,
} from "../../Redux/actions";
import DraggableItem from "../Components/DraggableItem";
import ImageTag from "../Components/ImageTag";
import {
  checkArray,
  checkNull,
  getCartBody,
  getCartNewItemCount,
  getServerImgUrl,
  parseRound,
  deepCopy,
  getRandomString,
  percentage,
  howMuchPercentage,
  checkVoucherAvailable,
  pluckArray,
  getUniTimeString,
  checkIfRepricePromo,
  sumOfPromos,
} from "../../helpers";
import PopupModal from "../Components/PopupModal";
import { crossIcon, locationBigIcon } from "../../Assets";
import FloatingInput from "../Components/FloatingInput";
import FloatingGroupInput from "../Components/FloatingGroupInput";
import { actions } from "../../Redux";
import SelectInput from "../Components/SelectInput";
import osmProvider from "../../Utils/osmProvider";
import { toast } from "react-toastify";
import moment from "moment";
import { StyledSVG } from "../../Utils/style";
import { countryList } from "../../Utils/constants";

import { updateUserTip } from "../../Redux/actions";
import { getDistance } from "../../Utils/distance";
import basket from "../../Assets/images/basket.svg";
import ProgressBar from "../Components/ProgressBar";

const customLogo = new L.Icon({
  iconUrl: logo,
  iconRetinaUrl: logo,
  iconSize: [35, 35],
  shadowSize: [50, 64],
  iconAnchor: [22, 94],
  shadowAnchor: [4, 62],
  popupAnchor: [-3, -76],
});

function Cart(props) {
  const {
    isLaptopScreen,
    hideCartBtn = false,
    isStickyHeader = false,
    style = {},
    toggleCartPopUp = () => {},
    toggleAddItemPagePopUp = () => {},
    toggleBundleItemPagePopUp = () => {},
    salesTaxPer,
    totalCartAmount,
    promoDiscount,
    showSubmittedItem,
    increaseItem,
    decreaseItem,
    removeItemHandler,
    totalTip,
    getBasketTotalAmount,
    getBasketTotalDisAmount,
    deliveryCharges,
    getDeliveryFee,
    totalSaleTax,
    totalServiceCharge,
    totalDisCartAmount,
    getSubsidyCharge,
    getSubsidyMoreText,
    enable_delivery_partner,
    preferred_delivery_partner,
    setTotalTip,
    cart_detail,
    deliveryFee,
    deliveryFeeWoSubsidy,
    orderPlaceHandler,
  } = props;

  useEffect(() => {
    document.body.classList.add("white");
    hideMap();

    if (locations) {
      locations?.map((each, i) => {
        if (each?.id === selectedLocation?.id) {
          setOptions({ deliveryLocation: i + 1 });
        }
      });
    }
    console.log("appliedPromoooooooo", appliedPromo);
    if (checkNull(appliedPromo, false)) {
      onApplyPromotion();
    }

    setTimeout(() => {
      getUserLocations();
    }, 1000);
  }, []);

  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const getUserLocations = () => dispatch(actions.getLocation());

  const { outletDetails, menuItems, storeDetails } = useSelector(
    (state) => state.storeReducer
  );

  const { displayMap } = useSelector((state) => state.commonReducer);
  const {
    deliveryType,
    cart_promocode,
    cart_promo_discount: userDiscount,
    promotionCode: appliedPromo,
  } = useSelector((state) => state.cartReducer);
  const {
    locations,
    availableLocations,
    selectedLocation,
    selectedStore,
    deliveryDate,
    deliveryTime,
  } = useSelector((state) => state.deliveryOptionsReducer);
  const deliveryOptionsReducer = useSelector(
    (state) => state.deliveryOptionsReducer
  );

  const {
    longitude: storeLong,
    latitude: storeLat,
    address: storeAddress,
    address_2: storeAddressTwo,
    city_id: cityId,
    zip_code: zipCode,
  } = outletDetails;
  const dateFormat = "yyyy/MM/DD";
  const currentDate = moment();
  const deliveryLong = deliveryOptionsReducer?.selectedLocation?.longitude;
  const deliveryLat = deliveryOptionsReducer?.selectedLocation?.latitude;
  const deliveryDateTime = `${deliveryDate} ${deliveryTime}`;
  const deliveryAddress =
    deliveryOptionsReducer?.selectedLocation?.street_address +
    " " +
    deliveryOptionsReducer?.selectedLocation?.unit_number +
    " " +
    deliveryOptionsReducer?.selectedLocation?.city +
    " " +
    deliveryOptionsReducer?.selectedLocation?.zip_code;
  console.log(selectedStore, "selectedStore");

  const { tip: userTip, totalDiscount } = useSelector(
    (state) => state.cartReducer.cartBasicDetails
  );
  const deliveryOptionsTimeAndDate = useSelector(
    (state) => state.deliveryOptionsReducer
  );

  const { quotationId } = useSelector((state) => state.quotationReducer);
  const totaCartItems = () =>
    cart_detail.reduce((prev, curr) => prev + curr.quantity, 0);
  // console.log('selectedLocation', selectedLocation)
  const [isUpdate, setIsUpdate] = useState(true);
  const [voucherModal, setVoucherModal] = useState(false);
  const [tipModal, setTipModal] = useState(false);
  const [tip, setTip] = useState(0);
  const [addModal, setAddModal] = useState(false);

  const [promotionCode, setPromotionCode] = useState(appliedPromo);

  const hideMap = () => dispatch(actions.setShowMap({ displayMap: false }));
  const totalItems = cart_detail.reduce((acc, curr) => acc + curr.quantity, 0);
  const pickup_buffer_time = parseInt(
    outletDetails?.settings?.pickup_buffer_time ?? 0
  );
  const checkScheduleTime = moment(deliveryDateTime).subtract(
    pickup_buffer_time,
    "minutes"
  );
  const currentMechantTime = moment(new Date())
    .utc()
    .utcOffset(storeDetails?.Merchant?.timezone_offset);
  const test = {
    data: {
      // scheduleAt: currentMechantTime.isBefore(checkScheduleTime)
      //   ? checkScheduleTime.format().slice(0, -6) +
      //     `${storeDetails?.Merchant?.timezone_offset}`
      //   : "",
      serviceType: "MOTORCYCLE",
      // specialRequests: ["PURCHASE_SERVICE_1"],
      language: "en_SG",
      stops: [
        {
          coordinates: {
            lat: `${storeLat}`,
            lng: `${storeLong}`,
          },
          address: `${storeAddress}${
            checkNull(storeAddressTwo, false) ? " " + storeAddressTwo : ""
          } ${cityId} ${zipCode}`,
        },
        {
          coordinates: {
            lat: `${deliveryLat}`,
            lng: `${deliveryLong}`,
          },
          address: deliveryAddress,
        },
      ],
      item: {
        quantity: `${totalItems}`,
        weight: "LESS_THAN_3KG",
        categories: ["FOOD_DELIVERY"],
        // categories: ["FOOD_DELIVERY", "OFFICE_ITEM"],
        // handlingInstructions: ["KEEP_UPRIGHT", "FRAGILE"],
      },
      isRouteOptimized: true,
      // "cashOnDelivery": {
      //     "amount": "100"
      // }
    },
  };
  if (currentMechantTime.isBefore(checkScheduleTime)) {
    test.data.scheduleAt =
      checkScheduleTime.format().slice(0, -6) +
      `${storeDetails?.Merchant?.timezone_offset}`;
  }

  useEffect(() => {
    if (availableLocations) {
      const { LATITUDE: lat, LONGITUDE: lng } =
        availableLocations?.results?.[0];
      setPosition({ lat, lng });
    }
  }, [availableLocations]);

  const [changeAddressModal, setChangeAddressModal] = useState(false);
  // const [showMap, setshowMap] = useState(false);
  const [options, setOptions] = useState({
    deliveryLocation: 0,
  });

  const [address, setAddress] = useState({
    nickname: "",
    street_address: "",
    unit_number: "",
    country: 0,
    city: " ",
    zip_code: "",
  });

  // useEffect(() => {
  //   selectedLocation && setAddress({ ...selectedLocation, country: 0 })
  // }, [displayMap])

  const onApplyTip = () => {
    tipChangeHandler();
  };

  const recalBundleAddItem = (menuItemObject, selectedItem) => {
    const menuItemObj = deepCopy(menuItemObject);
    const modifierArray = selectedItem?.modifierArray ?? [];
    const selectedBundleItem = selectedItem?.selectedBundleItem ?? [];
    const currentItemQyt = selectedItem?.quantity;
    const selectedItemPrice = parseFloat(
      menuItemObj?.menu_price?.item_price
        ? menuItemObj?.menu_price?.item_price
        : 0
    );

    if (menuItemObj?.id) {
      const modifierAddActionPrice =
        selectedBundleItem.length > 0
          ? selectedBundleItem
              .filter((item) => item.action_price == "add")
              .map((item) => parseFloat(item?.amount ?? 0))
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifierRemoveActionPrice =
        selectedBundleItem.length > 0
          ? selectedBundleItem
              .filter((item) => item.action_price == "remove")
              .map((item) => parseFloat(item?.amount ?? 0))
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifierAddPrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "add" || item.action == "swap")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifierRemovePrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "remove")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifierSizePrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "size")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifersPrice =
        modifierAddActionPrice -
        modifierRemoveActionPrice +
        modifierAddPrice -
        modifierRemovePrice -
        modifierSizePrice;
      let actualItemPrice = selectedItemPrice;
      console.log("promoActualPriceeeeeeeee000", menuItemObj?.promotions);
      const prevPromos = checkArray(selectedItem?.promotions)
        ? selectedItem?.promotions
        : [];
      if (menuItemObj?.promotions) {
        const promoObj = menuItemObj?.promotions;
        const promoActualPrice = promoObj?.acutalPrice;
        console.log(
          "promoActualPriceeeeeeeee111",
          promoActualPrice,
          modifersPrice,
          selectedItem?.promotions,
          menuItemObj?.promotions
        );

        if (
          (modifersPrice !== 0 || checkArray(selectedItem?.promotions)) &&
          menuItemObj?.promotions?.type == "Percentage"
        ) {
          console.log(
            "promoActualPriceeeeeeeee222",
            promoActualPrice,
            modifersPrice,
            promoObj.amount
          );
          let discountPrice =
            ((promoActualPrice + modifersPrice) * parseFloat(promoObj.amount)) /
            100;
          promoObj.promotionTotaldiscount =
            parseFloat(discountPrice) * currentItemQyt;
          promoObj.discountPerItem = discountPrice;
        }
        if (promoActualPrice < promoObj.discountPerItem) {
          promoObj.discountPerItem = promoActualPrice;
          promoObj.promotionTotaldiscount =
            parseFloat(promoActualPrice) * currentItemQyt;
        }
        prevPromos.push(promoObj);
      }
      menuItemObj.promotions = prevPromos;

      if (menuItemObj?.promotions) {
        // total amount changes with percentage discount
        if (
          checkArray(menuItemObj?.promotions) &&
          menuItemObj?.promotions.findIndex((p) => p?.type == "Percentage") > -1
        ) {
          // multiple promos
          const promoActualPrice = menuItemObj?.promotions?.[0]?.acutalPrice;
          const totalPerDisVal = menuItemObj?.promotions
            .map((item) => parseFloat(item?.amount ?? 0))
            .reduce((prev, curr) => prev + curr, 0);
          // multiple promos
          let discountPrice =
            ((promoActualPrice + modifersPrice) * totalPerDisVal) / 100;
          actualItemPrice =
            promoActualPrice - discountPrice >= 0
              ? promoActualPrice - discountPrice
              : 0;
        }
      }
      const singlePriceWithModifier = actualItemPrice + modifersPrice;
      const priceWithModifier = singlePriceWithModifier * currentItemQyt;
      // const serviceChargeAmount =
      // menuItemObj?.menu_price?.service_charge == "1" ||
      // menuItemObj?.menu_price?.service_charge == true
      //     ? parseFloat(
      //         (parseFloat(priceWithModifier ?? 0) * serviceChargePer) / 100
      //       )
      //     : 0;
      const serviceChargeAmount = 0;
      const salesTaxAmount =
        menuItemObj?.menu_price?.sales_tax == "1" ||
        menuItemObj?.menu_price?.sales_tax == true
          ? parseFloat(
              ((priceWithModifier + serviceChargeAmount) * salesTaxPer) / 100
            )
          : 0;

      let item = {
        id: menuItemObj?.id,
        offline_id: selectedItem?.offline_id,
        name: menuItemObj?.pos_name,
        pos_name: menuItemObj?.menu_item_name,
        menu_class_id: menuItemObj?.menu_class_id,
        menu_class_name: menuItemObj?.menu_class_name,
        bundleName: menuItemObj?.menu_item_name,
        //
        bundlePosName: menuItemObj?.pos_name,
        //
        image: menuItemObj?.image,
        firetime: null,
        createDate: moment().format("YYYY/MM/DD HH:mm:ss"),
        is_added: false,
        is_fired: false,
        is_hold: false,
        is_service_charge:
          menuItemObj?.menu_price?.service_charge == "1" ||
          menuItemObj?.menu_price?.service_charge == true
            ? true
            : false,
        is_sales_tax:
          menuItemObj?.menu_price?.sales_tax == "1" ||
          menuItemObj?.menu_price?.sales_tax == true
            ? true
            : false,
        quantity: currentItemQyt,
        amount: menuItemObj?.promotions?.[0]?.acutalPrice
          ? menuItemObj?.promotions?.[0]?.acutalPrice
          : singlePriceWithModifier,
        amount_without_modifiers: parseFloat(selectedItemPrice),
        total: priceWithModifier,
        item_amount: selectedItemPrice,
        actual_menu_price: parseFloat(
          menuItemObj?.promotions?.[0]?.acutalPrice
            ? menuItemObj?.promotions?.[0]?.acutalPrice
            : menuItemObj?.menu_price?.item_price ?? 0
        ),
        hide_fixed_items: menuItemObj?.hide_fixed_items,
        hide_fixed_items_qr: menuItemObj?.hide_fixed_items_qr,
        menu_item_type: menuItemObj?.menu_item_type ?? "",
        kitchen_print_preference: menuItemObj?.kitchen_print_preference ?? null,
        menu_price: menuItemObj?.menu_price?.item_price ?? "",
        service_charge_amount: serviceChargeAmount,
        sales_tax_amount: salesTaxAmount,
        catID: menuItemObj?.other_category_id ?? "",
        sub_category_id: menuItemObj?.sub_category_id ?? "",
        sub_class_id: menuItemObj?.sub_class_id ?? "",
        modifierArray: modifierArray.sort(function (a, b) {
          return a.id - b.id;
        }),
        BundledMenuItem: selectedItem.BundledMenuItem,
        selectedBundleItem: selectedItem.selectedBundleItem,
        promotions: menuItemObj?.promotions,
      };
      return item;
    }
    return selectedItem;
  };

  const recalAddItem = (menuItemObj, selectedItem) => {
    const modifierArray = selectedItem?.modifierArray ?? [];
    const currentItemQyt = selectedItem?.quantity;
    const selectedItemPrice = parseFloat(
      menuItemObj?.menu_price?.item_price
        ? menuItemObj?.menu_price?.item_price
        : 0
    );
    if (menuItemObj?.id) {
      const modifierAddPrice = modifierArray.length
        ? modifierArray
            .filter((item) => item.action == "add" || item.action == "swap")
            .map((item) => item.unit_price)
            .reduce((prev, curr) => prev + curr, 0)
        : 0;
      const modifierRemovePrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "remove")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifierSizePrice =
        modifierArray.length > 0
          ? modifierArray
              .filter((item) => item.action == "size")
              .map((item) => item.unit_price)
              .reduce((prev, curr) => prev + curr, 0)
          : 0;
      const modifersPrice =
        modifierAddPrice - modifierRemovePrice + modifierSizePrice;
      let actualItemPrice = selectedItemPrice;

      const prevPromos = checkArray(selectedItem?.promotions)
        ? selectedItem?.promotions
        : [];
      if (menuItemObj?.promotions) {
        const promoObj = menuItemObj?.promotions;
        const promoActualPrice = promoObj?.acutalPrice;
        console.log(
          "promoActualPriceeeeeeeee111",
          promoActualPrice,
          modifersPrice,
          selectedItem?.promotions,
          menuItemObj?.promotions
        );

        if (
          (modifersPrice !== 0 || checkArray(selectedItem?.promotions)) &&
          menuItemObj?.promotions?.type == "Percentage"
        ) {
          console.log(
            "promoActualPriceeeeeeeee222",
            promoActualPrice,
            modifersPrice,
            promoObj.amount
          );
          let discountPrice =
            ((promoActualPrice + modifersPrice) * parseFloat(promoObj.amount)) /
            100;
          promoObj.promotionTotaldiscount =
            parseFloat(discountPrice) * currentItemQyt;
          promoObj.discountPerItem = discountPrice;
        }
        if (promoActualPrice < promoObj.discountPerItem) {
          promoObj.discountPerItem = promoActualPrice;
          promoObj.promotionTotaldiscount =
            parseFloat(promoActualPrice) * currentItemQyt;
        }
        prevPromos.push(promoObj);
      }
      menuItemObj.promotions = prevPromos;

      if (menuItemObj?.promotions) {
        // total amount changes with percentage discount
        if (
          checkArray(menuItemObj?.promotions) &&
          menuItemObj?.promotions.findIndex((p) => p?.type == "Percentage") > -1
        ) {
          // multiple promos
          const promoActualPrice = menuItemObj?.promotions?.[0]?.acutalPrice;
          const totalPerDisVal = menuItemObj?.promotions
            .map((item) => parseFloat(item?.amount ?? 0))
            .reduce((prev, curr) => prev + curr, 0);
          // multiple promos
          let discountPrice =
            ((promoActualPrice + modifersPrice) * totalPerDisVal) / 100;
          actualItemPrice =
            promoActualPrice - discountPrice >= 0
              ? promoActualPrice - discountPrice
              : 0;
        }
      }

      console.log("validPromoooooooooooo9999", actualItemPrice);
      const singlePriceWithModifier = actualItemPrice + modifersPrice;
      const priceWithModifier = singlePriceWithModifier * currentItemQyt;

      // const serviceChargeAmount =
      //   menuItemObj?.menu_price?.service_charge == "1"
      //     ? parseFloat(
      //         (parseFloat(priceWithModifier ?? 0) * serviceChargePer) / 100
      //       )
      //     : 0;
      const serviceChargeAmount = 0;
      const salesTaxAmount =
        menuItemObj?.menu_price?.sales_tax == "1"
          ? parseFloat(
              ((priceWithModifier + serviceChargeAmount) * salesTaxPer) / 100
            )
          : 0;

      let item = {
        bundleName:
          menuItemObj?.menu_item_type === "bundled"
            ? menuItemObj?.menu_item_type
            : "",
        bundlePosName:
          menuItemObj?.menu_item_type === "bundled"
            ? menuItemObj?.pos_name
            : "",
        id: menuItemObj?.id,
        offline_id: selectedItem?.offline_id,
        is_fired: false,
        is_hold: false,
        name: menuItemObj?.pos_name,
        pos_name: menuItemObj?.menu_item_name,
        menu_class_id: menuItemObj?.menu_class_id,
        menu_class_name: menuItemObj?.menu_class_name,
        image: menuItemObj?.image,
        is_added: false,
        is_service_charge:
          menuItemObj?.menu_price?.service_charge == "1" ||
          menuItemObj?.menu_price?.service_charge == true
            ? true
            : false,
        is_sales_tax:
          menuItemObj?.menu_price?.sales_tax == "1" ||
          menuItemObj?.menu_price?.sales_tax == true
            ? true
            : false,
        quantity: currentItemQyt,
        amount: singlePriceWithModifier,
        amount_without_modifiers: parseFloat(actualItemPrice),
        item_amount: actualItemPrice,
        actual_menu_price: parseFloat(menuItemObj?.menu_price?.item_price ?? 0),
        comment: selectedItem?.comment,
        total: priceWithModifier,
        menu_item_type: menuItemObj?.menu_item_type ?? "",
        menu_price: menuItemObj?.menu_price ?? "",
        service_charge_amount: serviceChargeAmount,
        sales_tax_amount: salesTaxAmount,
        catID: menuItemObj?.other_category_id ?? "",
        sub_category_id: menuItemObj?.sub_category_id ?? "",
        sub_class_id: menuItemObj?.sub_class_id ?? "",
        modifierArray: modifierArray,
        promotions: menuItemObj?.promotions,
      };
      // if (menuItemObj?.promotions) {
      //   const promoObj = menuItemObj?.promotions;
      //   const promoActualPrice = checkArray(selectedItem?.promotions)
      //     ? selectedItem?.amount_without_modifiers
      //     : promoObj?.acutalPrice;
      //   if (
      //     (modifersPrice !== 0 || checkArray(selectedItem?.promotions)) &&
      //     promoObj.type == "Percentage"
      //   ) {
      //     let discountPrice =
      //       ((promoActualPrice + modifersPrice) * parseFloat(promoObj.amount)) /
      //       100;
      //     promoObj.promotionTotaldiscount =
      //       parseFloat(discountPrice) * currentItemQyt;
      //     promoObj.discountPerItem = discountPrice;
      //   }
      //   if (promoActualPrice < promoObj.discountPerItem) {
      //     promoObj.discountPerItem = promoActualPrice;
      //     promoObj.promotionTotaldiscount =
      //       parseFloat(promoActualPrice) * currentItemQyt;
      //   }
      //   const prevPromos = checkArray(selectedItem?.promotions)
      //     ? selectedItem?.promotions
      //     : [];
      //   prevPromos.push(promoObj);
      //   item.promotions = prevPromos;
      // }
      return item;
    }
    return selectedItem;
  };

  const onApplyPromotion = () => {
    let errMsg = "Invalid Promotion code.";
    // search only in order level promo's
    const validPromoArr = [];
    const validItemPromoArr = [];
    let newCartItems = deepCopy(cart_detail);
    const copyPromos = checkArray(menuItems?.Promotions)
      ? deepCopy(menuItems?.Promotions)
      : [];
    let totalProAmt = 0;
    console.log("validPromoooooooooooo0000", copyPromos, totalCartAmount);

    if (
      checkArray(copyPromos) &&
      copyPromos.findIndex(
        (p) => p?.qualification_type === "order" && p?.code === promotionCode
      ) > -1
    ) {
      const validPromoIndex = copyPromos.findIndex(
        (q) => q?.code === promotionCode && q?.qualification_type === "order"
      );

      if (validPromoIndex > -1) {
        const validPromo = copyPromos?.[validPromoIndex];
        const isValidVoucher = checkVoucherAvailable(
          validPromo,
          menuItems?.Merchant?.timezone_offset ?? "00:00",
          deliveryDateTime
        );
        errMsg = "Promocode unable at this time!";
        if (isValidVoucher) {
          if (validPromo?.type == "Percentage") {
            totalProAmt = percentage(
              parseFloat(validPromo?.amount ?? 0),
              totalCartAmount
            );
          } else {
            totalProAmt = parseFloat(validPromo?.amount ?? 0);
          }
          validPromoArr.push({
            amount: totalProAmt ?? 0,
            code: validPromo?.code ?? "",
            combo_allowed: validPromo?.combo_allowed ?? "",
            id: validPromo?.id ?? "",
            isLoyaltyVoucher: validPromo?.loyalty_only_promotion ?? "",
            name: validPromo?.promotion_name ?? "",
            promotionTotaldiscount: totalProAmt ?? 0,
            type: validPromo?.type ?? "",
            voucherName: validPromo?.promotion_name,
          });
        }
      }
    } else if (
      checkArray(copyPromos) &&
      copyPromos.findIndex(
        (p) => p?.qualification_type === "item" && p?.code === promotionCode
      ) > -1
    ) {
      // item level
      const validPromoIndex = copyPromos.findIndex(
        (q) => q?.code === promotionCode && q?.qualification_type === "item"
      );
      if (validPromoIndex > -1) {
        const validPromo = copyPromos?.[validPromoIndex];
        const isValidVoucher = checkVoucherAvailable(
          validPromo,
          menuItems?.Merchant?.timezone_offset ?? "00:00",
          deliveryDateTime
        );
        console.log("validPromoooooooooooo44441111", isValidVoucher);
        errMsg = "Promocode unable at this time!";
        if (isValidVoucher) {
          const validMenuIds = pluckArray(validPromo?.MenuItem, "id");
          const copyCartDetail = deepCopy(cart_detail);

          if (
            checkArray(validMenuIds) &&
            copyCartDetail.findIndex((p) => validMenuIds.indexOf(p?.id) > -1) >
              -1
          ) {
            newCartItems = copyCartDetail.map((prePromoItem) => {
              if (
                validMenuIds.indexOf(prePromoItem?.id) > -1 &&
                !prePromoItem?.promotions?.id
              ) {
                console.log("validPromoooooooooooo4444", prePromoItem);

                const categorizationType =
                  outletDetails?.settings?.categorisation_preference == "2"
                    ? "sub_class_id"
                    : "sub_category_id";
                const selectedSubCatItemIndex = menuItems?.MenuItems?.findIndex(
                  (p) => p.id == prePromoItem?.[categorizationType]
                );

                const selectedSubCatItem =
                  menuItems?.MenuItems?.[selectedSubCatItemIndex] ?? undefined;

                const selectedItemIndex = selectedSubCatItem
                  ? selectedSubCatItem?.MenuItem?.findIndex(
                      (p) => p.id == prePromoItem.id
                    )
                  : -1;

                const selectedItem =
                  selectedItemIndex > -1
                    ? deepCopy(
                        selectedSubCatItem?.MenuItem?.[selectedItemIndex]
                      )
                    : undefined;
                if (selectedItem) {
                  if (checkArray(prePromoItem?.promotions)) {
                    errMsg = "Promocode can not combine or already applied!";
                    if (
                      prePromoItem?.promotions.length > 1 ||
                      prePromoItem?.promotions.findIndex(
                        (p) => p?.id == validPromo?.id
                      ) > -1 ||
                      checkIfRepricePromo(prePromoItem?.promotions) ||
                      validPromo.combo_allowed !== "Yes" ||
                      prePromoItem?.promotions?.[0].combo_allowed !== "Yes"
                    ) {
                      return prePromoItem;
                    }
                  }
                  validItemPromoArr.push({
                    amount: totalProAmt ?? 0,
                    code: validPromo?.code ?? "",
                    combo_allowed: validPromo?.combo_allowed ?? "",
                    id: validPromo?.id ?? "",
                    isLoyaltyVoucher: validPromo?.loyalty_only_promotion ?? "",
                    name: validPromo?.promotion_name ?? "",
                    promotionTotaldiscount: totalProAmt ?? 0,
                    type: validPromo?.type ?? "",
                    voucherName: validPromo?.promotion_name,
                  });
                  const acutalPrice = parseFloat(
                    selectedItem?.menu_price?.item_price ?? 0
                  );
                  let promoPrice = 0;
                  let discountPrice = 0;
                  if (validPromo.type == "Amount") {
                    discountPrice = parseFloat(validPromo.amount);
                    promoPrice =
                      acutalPrice - discountPrice >= 0
                        ? acutalPrice - discountPrice
                        : 0;
                  } else if (validPromo.type == "Percentage") {
                    discountPrice =
                      (acutalPrice * parseFloat(validPromo.amount)) / 100;
                    promoPrice =
                      acutalPrice - discountPrice >= 0
                        ? acutalPrice - discountPrice
                        : 0;
                  } else if (validPromo.type == "Re-Price") {
                    discountPrice = parseFloat(validPromo.amount);
                    promoPrice = discountPrice;
                  }
                  if (selectedItem.menu_price) {
                    selectedItem.menu_price.item_price = promoPrice;
                  }
                  selectedItem.promotions = {
                    id: validPromo.id,
                    type: validPromo.type,
                    code: validPromo.code,
                    name: validPromo.promotion_name,
                    auto_apply: validPromo.auto_apply,
                    acutalPrice: acutalPrice,
                    promotionTotaldiscount: discountPrice,
                    amount: validPromo.amount,
                    discountPerItem: parseFloat(discountPrice),
                    combo_allowed: validPromo.combo_allowed,
                    isLoyaltyVoucher:
                      validPromo?.loyalty_only_promotion == "1" ? "yes" : "no",
                    voucherName: "",
                  };

                  if (prePromoItem?.menu_item_type === "bundled") {
                    return recalBundleAddItem(selectedItem, prePromoItem);
                  }
                  return recalAddItem(selectedItem, prePromoItem);
                }
              }
              return prePromoItem;
            });
            console.log("validPromoooooooooooo6666", newCartItems);
          }
        }
      }
    }

    dispatch(
      actions.finalCartSave({
        cartItems: newCartItems,
        cart_promocode: validPromoArr,
        cart_promo_discount: totalProAmt,
      })
    );
    dispatch(actions.updateUserDiscount(totalProAmt + promoDiscount));
    if (checkArray(validPromoArr) || checkArray(validItemPromoArr)) {
      closeVoucherModal();
      dispatch(actions.finalCartSave({ promotionCode: promotionCode }));
      return toast.success("Promotion Applied Successfully.");
    }
    dispatch(actions.finalCartSave({ promotionCode: "" }));
    return toast.error(errMsg);
  };

  // const [address, setAddress] = useState({
  //   nickname: 'case 2',
  //   street_address: 'Robinson Road',
  //   unit_number: '02',
  //   country: 'SG',
  //   city: 'Singapore',
  //   zip_code: '068902' || ''
  // });

  useEffect(() => {
    // if (!isUpdate) {
    setAddress({
      // nickname: selectedLocation?.nickname || "43 Jervois",
      // street_address: selectedLocation?.street_address || "43 Jervois Road",
      // unit_number: selectedLocation?.unit_number || "02",
      // country: selectedLocation?.country || 0,
      // city: selectedLocation?.city || "Singapore",
      // zip_code: selectedLocation?.zip_code || "068902",

      nickname: selectedLocation?.nickname,
      street_address: selectedLocation?.street_address,
      unit_number: selectedLocation?.unit_number,
      country: selectedLocation?.country || 0,
      city: selectedLocation?.city || "Singapore",
      zip_code: selectedLocation?.zip_code,
    });
    // }
    // else {
    //   selectedLocation && setAddress({ ...selectedLocation, country: 0 })
    // }

    if (
      !isLaptopScreen &&
      deliveryType.key === "delivery" &&
      outletDetails?.settings?.enable_delivery_partner === "yes" &&
      !(
        outletDetails?.settings?.preferred_delivery_partner === "0" ||
        outletDetails?.settings?.preferred_delivery_partner === null
      )
    ) {
      dispatch(actions.getQuotations(test));
    }
  }, [selectedLocation]);

  const onPressAddUpdate = () => {
    console.log("onPressAddUpdate");
    dispatch(
      actions.getLatLngFromAddrs({
        ...address,
        country: countryList[address.country]?.country_code,
      })
    );
  };

  const handleChange = (type) => (e) => {
    setOptions({ ...options, [type]: e.target.value });
  };

  const onChangeAddress = (type) => (e) => {
    setAddress({
      ...address,
      [type]: e.target.value,
    });
  };

  const center = { lat: 0, lng: 0 };
  const ZOOM_LEVEL = 18;
  const mapRef = useRef();
  const [position, setPosition] = useState(center);

  const onConfirm = () => {
    closeChangeAddressModal(false);
    let data = {
      ...address,
      ...{ latitude: position.lat, longitude: position.lng },
    };
    dispatch(actions.addLocation({ ...data }));
    handleChange("deliveryLocation")({
      target: { value: locations.length + 1 },
    });
    setAddModal(false);
  };

  function DraggableMarker() {
    const markerRef = useRef(null);
    const eventHandlers = useMemo(
      () => ({
        dragend() {
          const marker = markerRef.current;
          if (marker != null) {
            setPosition(marker.getLatLng());
          }
        },
      }),
      []
    );

    return (
      <Marker
        draggable={true}
        eventHandlers={eventHandlers}
        position={position}
        icon={customLogo}
        ref={markerRef}
      >
        <Popup minWidth={90}>
          <span>{"Hello "}</span>
        </Popup>
      </Marker>
    );
  }

  const CustomMap = () => (
    <Collapse orientation="horizontal" in={displayMap}>
      <div>
        <div
          style={{
            height: "300px",
            width: window.screen.width,
            marginBottom: "25px",
            overflow: "hidden",
          }}
        >
          <MapContainer center={position} zoom={ZOOM_LEVEL} ref={mapRef}>
            <TileLayer
              url={osmProvider.maptiler.url}
              attribution={osmProvider.maptiler.attribution}
            />
            <DraggableMarker />
          </MapContainer>
        </div>

        <text className="col-12 px-4">
          Drag pointer to the accurate location
        </text>

        <div className="group sub-btn  my-2 col-12 px-4 d-flex justify-content-between changeAdd">
          <Button
            type="button"
            className="col-12"
            onClick={onConfirm}
            style={{
              backgroundColor: outletDetails?.settings?.brand_main_color,
            }}
          >
            Confirm
          </Button>
        </div>
      </div>
    </Collapse>
  );

  // useEffect(() => {
  //   // console.log('adddd', locations[options.deliveryLocation - 1]);
  //   if (options.deliveryLocation) {
  //     const { nickname, street_address, unit_number, country, city, zip_code } =
  //       locations[options.deliveryLocation - 1];

  //     setAddress({
  //       nickname,
  //       street_address,
  //       unit_number,
  //       country : 0,
  //       city,
  //       zip_code,
  //     });
  //   }
  // }, [locations, options.deliveryLocation]);

  const closeChangeAddressModal = useCallback((value) => {
    setIsUpdate(true);
    setChangeAddressModal(value);
    if (props?.closeChangePropAddressModal) {
      props?.closeChangePropAddressModal(value);
    }
    // setAddress({
    //   nickname: "",
    //   street_address: "",
    //   unit_number: "",
    //   country: "",
    //   city: "",
    //   zip_code: "",
    // });
  }, []);

  const closeVoucherModal = useCallback((value = false) => {
    setVoucherModal(value);
  }, []);

  const closeTipModal = useCallback((value = false) => {
    setTipModal(value);
  }, []);

  const currency = outletDetails?.currency ? outletDetails?.currency + " " : "";

  const table_detail = null;
  // useSelector(
  //   (state) => state.tableDetailReducer?.table_detail
  // );
  // const cart_detail = null;
  // useSelector(
  //   (state) => state.cartDetailReducer?.cart_detail
  // );
  const applied_voucher = null;
  // useSelector(
  //   (state) => state.cartDetailReducer?.applied_voucher
  // );
  const show_cart_popup = null;
  // useSelector(
  //   (state) => state.cartDetailReducer?.show_cart_popup
  // );

  const recent_order_id = null;
  // useSelector(
  //   (state) => state.cartDetailReducer?.recent_order_id
  // );
  const user_detail = null; //remove
  // useSelector(
  //   (state) => state.authDetailReducer?.user_detail
  // );

  ////////////////////////////////////////// CALCULATE AMOUNT ////////////////////////////////////////

  ////////////////////////////////////////// CALCULATE AMOUNT ////////////////////////////////////////

  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  const [wrapperClass, setWrapperClass] = useState("");
  const containerRef = useRef(null);
  const backLink = () => {
    navigate(-1);
  };
  ///// add tip to total ///////

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      console.log("User Swiped!", eventData.dir);
      if (!isLaptopScreen) {
        if (
          hideHeader == true &&
          eventData.dir == "Down" &&
          isHeaderAllUp === true
        ) {
          setWrapperClass("");
          setHideHeader(false);
        }
        if (hideHeader == false && eventData.dir == "Up") {
          setWrapperClass("scroll-active");
          setHideHeader(true);
        }
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    if (!isLaptopScreen) {
      if (headerPageOffset === 0 && hideHeader === true) {
        setIsHeaderAllUp(true);
      }
      if (headerPageOffset > 0 && isHeaderAllUp === true) {
        setIsHeaderAllUp(false);
      }
    }
  }

  function getCartItems() {
    if (cart_detail && cart_detail.length > 0) {
      return cart_detail
        .filter((p) =>
          showSubmittedItem ? p.is_added == true : p.is_added == false
        )
        .map(function (item, itemIndex) {
          return (
            <DraggableItem
              isLaptopScreen={isLaptopScreen}
              item={item}
              increaseItem={increaseItem}
              decreaseItem={decreaseItem}
              itemIndex={item.offline_id}
              defaultimage={outletDetails?.logo}
              removeItemHandler={removeItemHandler}
              toggleAddItemPagePopUp={toggleAddItemPagePopUp}
              toggleBundleItemPagePopUp={toggleBundleItemPagePopUp}
              key={item.offline_id}
            />
          );
        });
    }
    return null;
  }

  function getPopularItems() {
    let res = [];
    if (table_detail?.SubCategory?.length > 0) {
      table_detail?.SubCategory.filter(
        (subCat) => subCat?.MenuItem.findIndex((p) => p.is_popular == 1) > -1
      ).map(function (subCat, index) {
        if (subCat?.MenuItem) {
          subCat?.MenuItem.filter((item) => item.is_popular == 1).map(function (
            item,
            itemKey
          ) {
            res.push(item);
          });
        }
      });
    }
    if (res.length > 0) {
      return res.slice(0, 4).map((item) => {
        return (
          <div
            className="col-6"
            onClick={() => {
              dispatch(getSelectedItemDetail(item));
              dispatch({ type: TOGGLE_CART_PAGE, payload: true });
              // history.push("/add-item");
            }}
          >
            <figure className="menu-img mx-auto mb-3">
              <ImageTag
                src={getServerImgUrl(item?.image)}
                defaultimage={outletDetails?.logo}
                alt=""
              />
            </figure>
            <h5 className="sm-h5"> {item?.menu_item_name ?? ""} </h5>
            <p>
              @
              {item?.menu_price?.item_price !== undefined
                ? parseFloat(item?.item_price?.item_price)
                    .toFixed(3)
                    .slice(0, -1)
                : "0.00"}
            </p>
          </div>
        );
      });
    } else {
      return null;
    }
  }

  function tipChangeHandler(el) {
    let res = 0;
    if (
      totalTip !== undefined &&
      !isNaN(parseFloat(totalTip)) &&
      isFinite(totalTip)
    ) {
      const inputVal = parseFloat(totalTip) > 1000000 ? 1000000 : totalTip;
      res = parseFloat(inputVal).toFixed(2);
    } else if (totalTip !== "") {
      dispatch(
        showNotificationWithTimeout("Please enter valid number!", "error")
      );
    }
    setTip(parseFloat(res));
    closeTipModal();
  }

  function getBasketTotalQuantity() {
    let res = getCartNewItemCount(cart_detail);
    return <div className="items-count"> {res} </div>;
  }

  console.log(userDiscount, promoDiscount, "ppppp");

  const getBillingDetailCon = () => {
    return (
      <section className="billing-details pb-5">
        <ul className="billing-list mb-0 cd-px-md-4">
          {getSubsidyMoreText()}
          <li className="border-btm-grey">
            <span> SUBTOTAL </span>
            <strong>
              <small>{`${outletDetails.currency_symbol ?? ""}`}</small>
              {`${parseRound(totalDisCartAmount + promoDiscount)}`}
            </strong>
          </li>
          <li className="border-btm-grey">
            <span> DISCOUNTS </span>
            <strong>
              (<small>{`${outletDetails.currency_symbol ?? ""}`}</small>
              {`${parseRound(userDiscount + promoDiscount)}`})
            </strong>
          </li>
          {/* <li className="border-btm-grey">
        <span> SERVICE CHARGES </span>
        <strong>
          <small>{`${outletDetails.currency_symbol ?? ""}`}</small>
          {parseRound(totalServiceCharge)}
        </strong>
      </li> */}
          <li className="border-btm-grey">
            <span> TAX </span>
            <strong>
              <small>{`${outletDetails.currency_symbol ?? ""}`}</small>
              {parseRound(totalSaleTax)}
            </strong>
          </li>
          <li className="border-btm-grey">
            <span> DELIVERY FEE </span>
            <strong>
              {getSubsidyCharge() > 0 ? (
                <del className="mr-1">
                  <small>{outletDetails.currency_symbol}</small>
                  {parseRound(deliveryFeeWoSubsidy || 0)}{" "}
                </del>
              ) : null}
              <small>{outletDetails.currency_symbol}</small>
              {parseRound(deliveryFee || 0)}
            </strong>
          </li>
          <li>
            <span> TIP </span>

            <div
              onClick={() => setTipModal(true)}
              className="link offset-4"
              style={{
                color: outletDetails?.settings?.brand_highlight_color,
              }}
            >
              APPLY TIP
            </div>

            <strong>
              <small>{outletDetails.currency_symbol}</small>
              {parseRound(userTip)}
            </strong>
          </li>
          {/* <li>
      
      <span> Gratuity </span>
      <input
        type="text"
        className="type-text"
        disabled="disabled"
        value="$0.00"
      />
    </li> */}
          <li className="total">
            <span> Total </span>
            {getBasketTotalDisAmount()}
          </li>
        </ul>
        <div className="row mx-0 my-2 justify-content-between px-20">
          <div
            onClick={() => setVoucherModal(true)}
            className="link"
            style={{ color: outletDetails?.settings?.brand_highlight_color }}
          >
            APPLY PROMOTION
          </div>
        </div>
      </section>
    );
  };

  const getBillingCon = () => {
    let res = null;
    if (isLaptopScreen) {
      res = (
        <>
          {cart_detail &&
          checkArray(
            cart_detail.filter((p) =>
              showSubmittedItem ? p.is_added == true : p.is_added == false
            )
          )
            ? getBillingDetailCon()
            : null}
        </>
      );
    } else {
      res = getBillingDetailCon();
    }
    return res;
  };

  const getOrderSumTab = () => {
    return (
      <>
        <h3 className="title">Order Summary</h3>
        <section className="middle-sec cart-sec-ms new_items_page">
          {getCartItems()}
        </section>
      </>
    );
  };

  const getOrderSumCon = () => {
    let res = null;
    if (isLaptopScreen) {
      res = (
        <>
          {cart_detail &&
          checkArray(
            cart_detail.filter((p) =>
              showSubmittedItem ? p.is_added == true : p.is_added == false
            )
          ) ? (
            getOrderSumTab()
          ) : (
            <div className="card mb-3 empty-basket-card">
              <div className="card-body">
                <h3 className="d-flex align-items-center justify-content-center">
                  <StyledSVG
                    color={outletDetails?.settings?.brand_main_color}
                    src={cartImg}
                    width="30"
                  />
                  <span className="ml-2">Your basket is empty</span>
                </h3>
              </div>
            </div>
          )}
        </>
      );
    } else {
      res = getOrderSumTab();
    }
    return res;
  };

  return (
    <div
      className={`wrapper cart-screen ${
        !isLaptopScreen ? "cart_sidebar " : ""
      }${checkArray(cart_detail) && hideCartBtn?"height-full ":""}colorGreyBg ${wrapperClass}`}
      style={!isLaptopScreen ? style : {}}
    >
      {!isLaptopScreen && (
        <div ref={containerRef}>
          <Collapse in={!hideHeader} timeout={{ enter: 1000, exit: 100 }}>
            <Paper
              sx={{
                margin: 0,
                pading: 0,
                borderRadius: "unset",
                boxShadow: "none",
              }}
            >
              <Header
                title={"Review Your Basket"}
                backLink={backLink}
                backFunc={toggleCartPopUp}
                backName={"Back to Order"}
              />
            </Paper>
          </Collapse>
          <Collapse in={hideHeader} timeout={{ enter: 500, exit: 100 }}>
            <Paper
              sx={{
                margin: 0,
                pading: 0,
                borderRadius: "unset",
                boxShadow: "none",
              }}
            >
              <Header
                title={"Review Your Basket"}
                backLink={backLink}
                backName={"Back to Order"}
                hideUpper={true}
                backFunc={toggleCartPopUp}
              />
            </Paper>
          </Collapse>
        </div>
      )}
      <div
        className={`orderSummary${
          isStickyHeader && isLaptopScreen ? " increase-height" : ""
        }`}
        onScroll={scrolledElement}
        {...handlers}
      >
        <section
          className="middle-sec cart-sec-ms"
          onScroll={scrolledElement}
          {...handlers}
        >
          {!isLaptopScreen && (
            <div className="menu-sections mt-md-0 mt-3">
              <div className="row mx-0 justify-content-between p-4 cd-px-md-4 cd-pt-md-4">
                <p className="m-0 p16">
                  <b className="b-size">
                    {deliveryType?.key == "delivery"
                      ? `Delivery on ${moment(deliveryDateTime).format(
                          "Do MMM"
                        )} at ${moment(deliveryDateTime).format("h:mm A")}`
                      : `Pickup scheduled for  ${moment(
                          deliveryDateTime
                        ).format("Do MMM")} at ${moment(
                          deliveryDateTime
                        ).format("h:mm A")}`}
                  </b>
                </p>
                {selectedLocation ? (
                  <div
                    onClick={() => setChangeAddressModal(true)}
                    className="link"
                    style={{
                      color: outletDetails?.settings?.brand_highlight_color,
                    }}
                  >
                    CHANGE
                  </div>
                ) : null}
              </div>
            </div>
          )}
          <div className={`outerScroll ${hideHeader ? "scrollup" : ""}`}>
            {getOrderSumCon()}
          </div>
        </section>
        {getBillingCon()}
      </div>

      {!hideCartBtn && (
        <section
          className="bottom-sec maxBtn"
          id="cart-maxbtn"
          style={!isLaptopScreen ? style : {}}
        >
          <div className="sub-btn my-0 mx-mobile-4">
            <Button
              type="button"
              className="col-12 mr-1 mt-0"
              onClick={() => {
                if (!totaCartItems()) {
                  toast.error("Your cart is empty!");
                  return;
                }
                if (deliveryType?.key == "delivery") {
                  if (enable_delivery_partner === "yes") {
                    if (
                      !(
                        preferred_delivery_partner === "0" ||
                        preferred_delivery_partner === null
                      ) &&
                      !checkNull(quotationId, false)
                    ) {
                      if (isLaptopScreen) {
                        orderPlaceHandler();
                      }
                      toast.error(
                        "There must be an issue with partner API. Please contact ADMIN further!"
                      );
                      return;
                    }
                  }
                }
                orderPlaceHandler();
              }}
              style={{
                backgroundColor: outletDetails?.settings?.brand_main_color,
                "&:hover": {
                  backgroundColor: outletDetails?.settings?.brand_main_color,
                },
              }}
            >
              Continue to Pay
            </Button>
          </div>
        </section>
      )}

      <PopupModal
        showDrawer={tipModal}
        toggleDrawer={closeTipModal}
        height={"45%"}
      >
        <div className="rider-note-modal">
          <div className="col-12 cross-btn-con">
            <figure
              className="float-right"
              onClick={() => {
                closeTipModal(false);
              }}
            >
              {/* <img width="20px" src={crossIcon} alt="" /> */}
              <StyledSVG
                color={outletDetails?.settings?.brand_highlight_color}
                src={crossIcon}
                width="40"
              />
            </figure>
          </div>
          <section className="login-section OtpVerifyMs row mx-auto w-100">
            <div className="sm-content-mb-0 col-12 px-4 mb-4">
              <h2 className="mb-2 sm-heading">Add Tip</h2>
              <p>Please indicate your tip for the restaurant.</p>
            </div>
            <div className="col-12 mt-0 px-4">
              {/* <form> */}
              <div className="group my-3 d-flex align-items-center pl-0">
                {/* <span className="currency_ico px-3" style={{ top: '17px' }}> {outletDetails.currency_symbol} </span> */}
                <FloatingInput
                  key={"tip"}
                  label="TIP"
                  onClick={(e) => {
                    if (e.target.selectionStart === 0)
                      e.target.selectionStart = 1;
                  }}
                  onKeyUp={(e) => {
                    if (e.target.selectionStart === 0)
                      e.target.selectionStart = 1;
                  }}
                  onChange={(e) => {
                    setTotalTip(e.target.value.slice(1));
                  }}
                  value={outletDetails.currency_symbol + totalTip}
                />
              </div>

              <div className="group sub-btn mb-0 d-flex justify-content-between">
                <Button
                  type="button"
                  className="col-12 mr-1"
                  onClick={() => {
                    if (totalTip < 0) {
                      toast.error("Tip should be more than 0");
                      return;
                    }
                    onApplyTip();
                    // add tip in redux store
                    dispatch(updateUserTip(totalTip));
                  }}
                  style={{
                    backgroundColor: outletDetails?.settings?.brand_main_color,
                  }}
                >
                  Apply
                </Button>
              </div>

              {/* </form> */}
            </div>
          </section>
        </div>
      </PopupModal>

      <PopupModal
        showDrawer={voucherModal}
        toggleDrawer={closeVoucherModal}
        height={"45%"}
      >
        <div className="rider-note-modal">
          <div className="col-12 cross-btn-con">
            <figure
              className="float-right"
              onClick={() => closeVoucherModal(false)}
            >
              {/* <img width="20px" src={crossIcon} alt="" /> */}
              <StyledSVG
                color={outletDetails?.settings?.brand_highlight_color}
                src={crossIcon}
                width="40"
              />
            </figure>
          </div>
          <section className="login-section OtpVerifyMs row mx-auto w-100">
            <div className="sm-content-mb-0 col-12 px-4 mb-4">
              <h2 className="mb-2 sm-heading">Apply Promotion</h2>
              <p>Please provide the valid promotion code and submit.</p>
            </div>
            <div className="col-12 mt-0 px-4">
              <form>
                <div className="group my-3">
                  <FloatingInput
                    key={"promotionCode"}
                    label="PROMOTION CODE"
                    onChange={(e) => setPromotionCode(e.target.value)}
                    // onChange={(e) => dispatch(actions.finalCartSave({promoCode:e.target.value}))}
                    value={promotionCode}
                  />
                </div>

                <div
                  // onClick={() => console.log("clickeddd")}
                  className="group sub-btn mb-0 d-flex justify-content-between"
                >
                  <Button
                    type="button"
                    className="col-12 mr-1"
                    onClick={() => {
                      console.log("clickeddd");
                      onApplyPromotion();
                    }}
                    style={{
                      backgroundColor:
                        outletDetails?.settings?.brand_main_color,
                    }}
                  >
                    Apply
                  </Button>
                </div>
              </form>
            </div>
          </section>
        </div>
      </PopupModal>

      <PopupModal
        showDrawer={changeAddressModal || props?.changePropAddressModal}
        toggleDrawer={closeChangeAddressModal}
        height="auto"
        paddingBottom="30px"
      >
        {displayMap ? (
          <>{(position?.lat || position?.lng) && CustomMap()}</>
        ) : (
          <>
            <div className="rider-note-modal">
              <div className="col-12 cross-btn-con">
                <figure
                  className="float-right updateAddress"
                  onClick={() => {
                    closeChangeAddressModal(false);
                    setAddModal(false);
                  }}
                >
                  {/* <img width="20px" src={crossIcon} alt="" /> */}
                  <StyledSVG
                    color={outletDetails?.settings?.brand_highlight_color}
                    src={crossIcon}
                    width="40"
                  />
                </figure>
              </div>
              <section className="login-section OtpVerifyMs row mx-0">
                <div
                  className="sm-content col-12 px-5 mb-0"
                  style={{ marginBottom: isUpdate ? "50px" : "0px" }}
                >
                  <figure style={{ marginLeft: "-8px" }}>
                    <img src={locationBigIcon} alt="" />
                  </figure>
                  <h2 className="mb-4">
                    {isUpdate ? "Update" : "Add"} Delivery Details
                  </h2>
                  <p>
                    Please provide us your
                    <b> preferred address for this delivery.</b>
                  </p>
                </div>
                {isUpdate ? (
                  <div className="col-12 mt-5 px-5">
                    <div className="selectInput mx-5">
                      <SelectInput
                        brand_highlight_color={
                          outletDetails?.settings?.brand_highlight_color
                        }
                        options={[
                          { nickname: "Select Delivery Location" },
                        ].concat(locations)}
                        value={options.deliveryLocation}
                        keyToCheck="nickname"
                        onChange={(e) => {
                          handleChange("deliveryLocation")({
                            target: { value: e.target.value },
                          });
                          dispatch(actions.updateAddress(e.target.value));
                        }}
                      />
                    </div>
                  </div>
                ) : null}
                {isUpdate ? (
                  <div className="col-12 mt-5 px-5">
                    <div className="text-align-left">
                      <span
                        className="anchor_link bolder"
                        onClick={() => {
                          setIsUpdate(false);
                          setAddModal(true);
                        }}
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                      >
                        ADD NEW ADDRESS
                      </span>
                    </div>
                  </div>
                ) : null}
                <div className={`col-12 mt-0 px-5`}>
                  <form
                    className={`${addModal ? "showAddModal" : "hideAddModal"}`}
                  >
                    <div className="group mt-5">
                      <FloatingInput
                        label="LOCATION NICKNAME"
                        onChange={onChangeAddress("nickname")}
                        value={address.nickname}
                      />
                    </div>
                    <div className="group">
                      <FloatingInput
                        label="STREET ADDRESS"
                        onChange={onChangeAddress("street_address")}
                        value={address.street_address}
                      />
                    </div>

                    <div className="group">
                      <FloatingInput
                        label="UNIT NUMBER"
                        onChange={onChangeAddress("unit_number")}
                        value={address.unit_number}
                      />
                    </div>

                    <div className="group row d-flex">
                      <FloatingGroupInput
                        fullWidth
                        variant="standard"
                        className="mt-0"
                      >
                        <InputLabel>COUNTRY & CITY</InputLabel>
                        <div className="row mx-0 mt-16">
                          <div className="col-3 countrySelect">
                            <SelectInput
                              brand_highlight_color={
                                outletDetails?.settings?.brand_highlight_color
                              }
                              country={true}
                              options={countryList}
                              value={address.country}
                              keyToCheck="country_code"
                              onChange={onChangeAddress("country")}
                            />
                          </div>
                          <div className="col-9">
                            <Input
                              autoComplete="new-password"
                              fullWidth
                              onChange={onChangeAddress("city")}
                              value={address.city || " "}
                            />
                          </div>
                        </div>
                      </FloatingGroupInput>
                    </div>
                    <div className="group">
                      <FloatingInput
                        label="ZIP CODE"
                        onChange={onChangeAddress("zip_code")}
                        value={address.zip_code}
                      />
                    </div>

                    <div className="group sub-btn mb-0 d-flex justify-content-between">
                      <Button
                        type="button"
                        className="col-12 mr-1 mt-0 mb-5"
                        onClick={onPressAddUpdate}
                        style={{
                          backgroundColor:
                            outletDetails?.settings?.brand_main_color,
                        }}
                      >
                        {isUpdate ? "Update" : "Add"}
                      </Button>
                    </div>
                  </form>
                </div>
              </section>
            </div>
          </>
        )}
      </PopupModal>
    </div>
  );
}

export default Cart;
