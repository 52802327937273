import React from "react";
import Drawer from "@mui/material/Drawer";
import { useDispatch } from "react-redux";
import { actions } from "../../Redux";
let PopupModal = ({ showDrawer, toggleDrawer, children, height, maxWidth, ...props }) => {
  const dispatch = useDispatch();
  return (
    <Drawer
      anchor={"bottom"}
      open={showDrawer}
      sx={{
        "& .MuiPaper-root": {
          height: height ? height : "92%",
          borderTopLeftRadius: "30px",
          borderTopRightRadius: "30px",
          maxWidth: maxWidth ? maxWidth : "767px",
          margin: "0 auto",
          paddingBottom: props.paddingBottom,
        },
      }}
      onClose={() => {
        dispatch(actions.setShowMap({ displayMap: false }));
        console.log("hiiip");
        toggleDrawer(false, true);
      }}
      className={props?.className??""}
    >
      {children}
    </Drawer>
  );
};
export default PopupModal;
