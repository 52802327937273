import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import persistReducer from 'redux-persist/es/persistReducer';
import persistStore from 'redux-persist/es/persistStore';

import createSagaMiddleware from 'redux-saga';
import storage from '../../indexed-db/config';
import rootReducer from '../reducers';
import rootSaga from '../sagas';

const persistConfig = {
    key: 'root',
    storage: storage('myDB'),
}

const persistedReducer = persistReducer(persistConfig, rootReducer)

const sagaMiddleware = createSagaMiddleware();
const store = createStore(persistedReducer, applyMiddleware(sagaMiddleware, logger));
const persistor = persistStore(store);


sagaMiddleware.run(rootSaga);

export { store, persistor };

