import React from "react";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import { styled } from "@mui/material/styles";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
const SelectInput = ({
  children,
  options,
  keyToCheck,
  brand_highlight_color,
  country,
  ...props
}) => {
  const items = options;
  // ? Object.keys(options).map((key) => [Number(key), options[key]])
  // : [];
  // console.log(`items>>>>`, items);
  const CssSelect = {
    "& legend": {
      display: "none",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      top: "0",
      borderWidth:country ? "0px" : "2px",
      borderBottomWidth:country ? "1px": "2px",
      // borderBottomColor:country && brand_highlight_color 
    // border-bottom: 1px solid #3e38a1;
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: brand_highlight_color,
      borderWidth:country ? "0px" : "2px",
    },
    "&.MuiInputBase-root .MuiSelect-outlined": {
      padding: "10.5px 20px",
      textAlign: "left",
    },
    "&.MuiInputBase-root svg": {
      margin: "0px 10px",
      transitionDuration: "0.3s",
      transitionProperty: "transform",
      color: "#3D3D3D",
    },
    "&.MuiInputBase-root.Mui-focused svg": {
      color: brand_highlight_color,
      transform: "rotate(-180deg)",
    },
    "&.MuiInputBase-root": {
      borderRadius: country ? "0px" : "40px",
    },
  };
  return (
    <FormControl fullWidth>
      <Select
       sx={CssSelect}
        labelId="test-select-label"
        IconComponent={() => <KeyboardArrowDownIcon />}
        displayEmpty
        inputProps={{ "aria-label": "Without label" }}
        {...props}
      >
        {items &&
          items.length > 0 &&
          items.map((item, index) => (
            <MenuItem value={index} key={index}>
              {item?.[keyToCheck]}
            </MenuItem>
          ))}
      </Select>
    </FormControl>
  );
};
export default SelectInput;
