import { all } from 'redux-saga/effects';
import commonSaga from './commonSaga';
import deliveryOptionsSaga from './deliveryOptionsSaga';
import storeSaga from './storeSaga';
import cartSaga from './cartSaga';
import paymentSaga from './paymentSaga';
import chatSaga from './chatSaga';
import quotationSaga from './quotationSaga';

function* rootSaga() {
  yield all([deliveryOptionsSaga(), commonSaga(), storeSaga(), cartSaga(), paymentSaga(), chatSaga(), quotationSaga()]);
}

export default rootSaga;
