import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { chatServices } from "../../services";
import * as actionTypes from "../actionTypes";

function* StartChatSession(action) {
  const { data } = yield call(chatServices.startChatSession, action.payload);

  console.log("StartChatSession", data);
  if (data.status) {
    toast.success(data.message);
    yield put({
      type: actionTypes.START_CHAT_SESSION_SUCCEEDED,
      payload: {
        chatSession: data.data,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.START_CHAT_SESSION_FAILED,
      payload: { error: data.message },
    });
  }
}

function* cartSaga() {
  yield takeLatest(actionTypes.START_CHAT_SESSION_REQUESTED, StartChatSession);
}

export default cartSaga;
