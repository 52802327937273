import React, { Suspense, useState, useEffect } from "react";
// import { connect } from "react-redux";
import Dashboard from "./Dashboard";
// import AuthScreens from "./AuthScreens";
import Loading from "../Scenes/Components/Loading";
import { useSelector } from "react-redux";
import ToastMessage from "../Scenes/Components/ToastMessage";
import { setPageDetails } from "../helpers";
import { BrowserRouter, Route } from "react-router-dom";
import "./styles.css";

import { Routing } from "../Utils";

const Main = () => {
  // const { isLogin, page_title } = useSelector((state) => state.authReducer);
  const { loading } = useSelector((state) => state.loaderReducer);

  function updateSize() {
    console.log("chekadsadasd",window.innerWidth)
    console.log("overflow-visible11111",window.innerWidth > 768)
    if(window.innerWidth > 768){
      document.body.classList.add("laptop-device");
      const slideRouteCols = document.getElementsByClassName("slide-routes");
      console.log("overflow-visible0000",slideRouteCols?.[0])
      if(slideRouteCols?.[0]){
        slideRouteCols?.[0].classList.add("overflow-visible");
      }
      document.body.classList.add("overflow-visible");
    }else{
      document.body.classList.remove("laptop-device");
      const slideRouteCols = document.getElementsByClassName("slide-routes");
      if(slideRouteCols?.[0]){
        slideRouteCols?.[0].classList.remove("overflow-visible");
      }
      document.body.classList.remove("overflow-visible");
    }
    // setSize([window.innerWidth, window.innerHeight]);
  }

  useEffect(() => {
    setPageDetails({
      title: 'Gogmgo',
    });
    // document.body.classList.remove("white");    
    window.addEventListener('resize', updateSize);

    return () => window.removeEventListener('resize', updateSize);
  }, []);

  updateSize();

  return (
    <>
      <Loading show={loading} />
      <ToastMessage />
      <BrowserRouter>
        <Dashboard />
      </BrowserRouter>
    </>
  )
  // return (
  //   <Suspense fallback={<></>}>
  //     <Loading show={loading} />
  //     <ToastMessage />
  //     <BrowserRouter>

  //       {[...Routing].map((route, key) => (
  //         <Route key={key} exact path={route.path} component={route.component} />
  //       ))}
  //     </BrowserRouter>
  //   </Suspense>
  // );
};
export default Main;
