import * as actionTypes from "../actionTypes";

const initialState = {
  loading: false,
  menuItems: null,
  outletDetails: {
    // brand_main_color: "#3e38a1",
  },
  selectedItem: null,
  storeDetails: null,
  search_input: "",
  filters: "",
  promotionArr: [],
  filter_tag_detail: [],
};

const storeReducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case actionTypes.CLEAR_DATA:
      return initialState;
    case actionTypes.GET_TAGS_SUCCEEDED:
      return {
        ...state,
        ...payload,
        isError: false,
      };
      case actionTypes.GET_TAGS_FAILED:
        return {
          ...state,
          filter_tag_detail: [],
          loading: false,
          isError: true,
        };  
    case actionTypes.GET_MENU_ITEMS_SUCCEEDED:
      return {
        ...state,
        ...payload,
        isError: false,
      };
    case actionTypes.GET_MENU_ITEMS_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
      };
    case actionTypes.GET_OUTLET_DETAILS_SUCCEEDED:
      return {
        ...state,
        ...payload,
        isError: false,
      };
    case actionTypes.GET_OUTLET_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
      };
    case actionTypes.GET_STORE_DETAILS_SUCCEEDED:
      return {
        ...state,
        ...payload,
        isError: false,
      };
    case actionTypes.GET_STORE_DETAILS_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
      };
    case actionTypes.FILTER_TAG_SUCCEEDED:
      return {
        ...state,
        ...payload,
        isError: false,
      };
    case actionTypes.FILTER_TAG_FAILED:
      return {
        ...state,
        loading: false,
        isError: true,
      };
    case actionTypes.SELECTED_ITEM:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.EDIT_ITEM_DETAILS:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.UPDATE_PROMOTION_LIST:
      return {
        ...state,
        ...payload,
      };
    case actionTypes.ADD_SEARCH_TEXT:
      return { ...state, search_input: action.input };
    case actionTypes.ADD_FILTER_TAG:
      return { ...state, filters: action.input };
    default:
      return state;
  }
};
export default storeReducer;
