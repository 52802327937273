import React, { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Components/Header";
import StepperProgress from "../Components/StepperProgress";
import { Button } from "@mui/material";
import { orderIcon } from "../../Assets";
import DividerCom from "../Components/DividerCom";
import moment from "moment";
import { StyledSVG } from "../../Utils/style";
import { actions } from "../../Redux";
import cross from "../../Assets/images/cross_2.png";
import { checkArray, parseRound } from "../../helpers";

const OrderConfirmation = ({ prevTab, nextTab }) => {
  useEffect(() => {
    document.body.classList.add("white");
  }, []);
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { storeDetails, outletDetails } = useSelector(
    (state) => state.storeReducer
  );
  const { trackOrder } = useSelector((state) => state.paymentReducer);
  const { orderDetails, cartBasicDetails } = useSelector(
    (state) => state.cartReducer
  );
  const { deliveryType, selectedLocation } = useSelector(
    (state) => state.deliveryOptionsReducer
  );
  const { cartItems: cart_detail } = useSelector((state) => state.cartReducer);
  const { pos_order_id, menu_item, paid_amount, order_id, order_create_date } =
    orderDetails?.order ?? {};

  const currency = outletDetails?.currency ? outletDetails?.currency + " " : "";
  const currencySymbol = outletDetails?.currency_symbol
    ? outletDetails?.currency_symbol + " "
    : "";

  function checkCancel() {
    return trackOrder?.status.find((el) => el.name === "Order Cancelled");
  }
  return (
    <div className="wrapper colorGreyBg confirm-width">
      <Header title={"Order confirmation"} />
      <section className="user-section OrderDetailCon mx-0">
        <div className="OrderDetail">
          <div className="row mx-0 my-3">
            <div className="col-2 pr-0">
              <figure className="mt-3">
                <StyledSVG
                  color={outletDetails?.settings?.brand_highlight_color}
                  src={orderIcon}
                  width="35"
                  height="35"
                />
                {/* <img width="35px" height="35px" src={orderIcon} alt="" />{" "} */}
              </figure>
            </div>
            <div className="col-10 pl-0">
              <p className="sm-title px-2">
                Your order has been received! To track the status, please click
                below :
              </p>
            </div>
          </div>
          <div className="col-12 my-0 trackStatus">
            <div className="sub-btn maxBtn ">
              {checkCancel() ? (
                <div className="d-flex align-items-center justify-content-center">
                  <img
                    style={{ width: "20px", marginRight: "5px" }}
                    src={cross}
                    alt="cross"
                  />
                  <span style={{ fontSize: "16px", fontWeight: "600" }}>
                    Your order has been cancelled.
                  </span>
                </div>
              ) : (
                <Button
                  type="button"
                  className="col-12"
                  onClick={() =>
                    navigate(
                      `/trackStatus/${orderDetails.order.order_offline_id}`
                    )
                  }
                  style={{
                    backgroundColor: outletDetails?.settings?.brand_main_color,
                  }}
                >
                  TRACK STATUS
                </Button>
              )}
              {trackOrder?.is_refund && (
                <p
                  style={{
                    fontSize: "16px",
                    fontWeight: "600",
                    paddingTop: "10px",
                  }}
                >
                  Amount Refunded Successfully
                </p>
              )}
            </div>
          </div>
          <div className="col-12 my-3">
            <div className="container">
              <p className="sm-text my-3">TOTAL</p>
              <span
                className="anchor_link my-3"
                style={{
                  color: outletDetails?.settings?.brand_highlight_color,
                }}
              >
                {currencySymbol.trim()}
                {parseRound(paid_amount || 0)}
              </span>
              <DividerCom color={"#dfdfdf"} className="my-3 mx-5 px-5" />
              <p className="sm-text mt-3">
                {moment(order_create_date).format("MMMM D, YYYY h:mm a")}
              </p>
            </div>
          </div>
        </div>
        <div className="OrderDetailMid">
          <div className="col-12">
            <p className="sm-text">ORDER DETAILS</p>
          </div>
          <div className="col-12">
            <p className="sm-text">Store Name</p>
            <p className="sm-title">{orderDetails?.order?.origination?.name}</p>
          </div>
          <div className="col-12">
            <p className="sm-text">Bill ID</p>
            <p className="sm-title">#{order_id}</p>
          </div>

          {deliveryType.key === "delivery" ? (
            <div className="col-12">
              <p className="sm-text">Destination</p>
              <p className="sm-title w-60">
                {selectedLocation?.street_address || ""} <br />
                {selectedLocation?.unit_number || ""} <br />
                {selectedLocation?.city + " " || ""}
                {selectedLocation?.zip_code || ""}
              </p>
            </div>
          ) : (
            ""
          )}

          <div className="col-12 mt-5">
            <p className="sm-text">RECEIPT SUMMARY</p>
          </div>
        </div>
        <div className="OrderSummary px-1">
          <div className="col-12 mb-3">
            <p className="sm-text">Payment Method</p>
            <p className="sm-title">Visa</p>
          </div>
          <div className="col-6">
            <p className="sm-text">Description</p>
          </div>
          <div className="col-6 text-align-right">
            <p className="sm-text">Amount</p>
          </div>
          {menu_item?.map((item) => {
            console.log("itemsssss,sda", item);
            const promotionTotal = checkArray(item?.promotions)
              ? item.promotions.reduce(
                  (prev, curr) => prev + curr?.discountPerItem ?? 0,
                  0
                )
              : 0;
            const itemAmtVal =
              item?.promotions?.[0]?.type == "Re-Price"
                ? item?.promotions?.[0]?.promotionTotaldiscount
                : item?.doubleActualAmout;
            return (
              <>
                <div className="col-6">
                  <p className="sm-title">
                    {/* {item?.menu_item_quantity} x {item?.menu_item_name} */}
                    {item?.menu_item_quantity} x {item?.pos_name}
                  </p>
                </div>
                <div className="col-6 text-align-right">
                  <p className="sm-title">
                    {currencySymbol.trim()}
                    {parseRound(
                      (itemAmtVal + promotionTotal) *
                        item?.menu_item_quantity
                    )}
                  </p>
                </div>
              </>
            );
          })}

          {/*Discount */}
          <div className="col-6">
            <p className="sm-title">Discount</p>
          </div>
          <div className="col-6 text-align-right">
            <p className="sm-title">
              ({currencySymbol.trim()}
              {parseRound(cartBasicDetails?.totalDiscount)})
            </p>
          </div>

          {/*Sales Tax */}
          <div className="col-6">
            <p className="sm-title">Sales Tax</p>
          </div>
          <div className="col-6 text-align-right">
            <p className="sm-title">
              {currencySymbol.trim()}
              {parseRound(cartBasicDetails?.totalSaleTax)}
            </p>
          </div>

          {/*Service Charge */}
          {/* <div className="col-6">
            <p className="sm-title">Service Charge</p>
          </div>
          <div className="col-6 text-align-right">
            <p className="sm-title">
              {currencySymbol.trim()}
              {cartBasicDetails?.totalServiceCharge.toFixed(2)}
            </p>
          </div> */}
          {/* Delivery fee */}
          <div className="col-6">
            <p className="sm-title">Tip</p>
          </div>
          <div className="col-6 text-align-right">
            <p className="sm-title">
              {currencySymbol.trim()}
              {parseRound(cartBasicDetails?.tip)}
            </p>
          </div>
          {/* Delivery fee */}
          <div className="col-6">
            <p className="sm-title">Delivery Fee</p>
          </div>
          <div className="col-6 text-align-right">
            <p className="sm-title">
              {currencySymbol.trim()}
              {parseRound(cartBasicDetails?.deliveryFee)}
            </p>
          </div>
          <div className="col-12">
            <DividerCom color={"#dfdfdf"} className="my-3 mx-5 px-5" />
          </div>
          <div className="col-12 mt-0 mb-3 text-align-center">
            <p className="sm-title tax-text">
              TOTAL (INCLUDING TAX) | &nbsp;
              <b>
                {currencySymbol.trim()}
                {parseRound(cartBasicDetails?.totalDisAmount)}
              </b>
            </p>
          </div>
        </div>
        <div className="bottom-sec">
          <div className="col-12 my-0">
            <p className="sm-title mt-0">
              @2022 GoGMGo Pte Ltd. All Rights Reserved.
            </p>
          </div>
        </div>
      </section>
    </div>
  );
};
export default OrderConfirmation;
