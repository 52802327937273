import * as actionTypes from "../actionTypes";

export const startLoader = () => ({
  type: actionTypes.START_LOADING,
  loading: true,
});
export const stopLoader = () => ({
  type: actionTypes.STOP_LOADING,
  loading: false,
});

export const clearData = () => ({
  type: actionTypes.CLEAR_DATA,
});

export const setShowMap = (payload) => ({
  type: actionTypes.SET_SHOW_MAP,
  payload,
});

export const getLatLngFromAddrs = ({
  street_address,
  city,
  country,
  zip_code,
  unit_number,
}) => {
  let query = "";

  query = `${zip_code}`;

  console.log(street_address, city, country, zip_code, unit_number, "aaaaa");
  //   if (street_address?.trim()) {
  //     let street = street_address.trim();
  //   }
  //   if (city?.trim()) {
  //     let citya = city.trim();
  //   }
  //   if (country?.trim()) {
  //     let country = country?.trim();
  //   }
  //   if (zip_code?.trim()) {
  //     query += `&postalcode=${zip_code.trim()}`;
  //   }
  //   if (unit?.trim()) {
  //     query += `&unit=${unit.trim()}`;
  //   }
  return {
    type: actionTypes.GEOCOADING_REQUESTED,
    query,
  };
};

export const slideDirection = (payload) => ({
  type: actionTypes.SLIDE_DIRECTION,
  payload,
});
