// import * as actionTypes from "../actionTypes";

import { actionTypes } from "..";

const initialState = {
    locations: [],
    chatSession: null,
    chatList: []
};

const chatReducer = (state = initialState, action) => {
    const { type, payload = {} } = action;
    const { chatList } = state;
    switch (type) {
        case actionTypes.CLEAR_DATA:
            return initialState
        case actionTypes.STORE_MESSAGE:

            let cloneChat = [];
            if (payload.type === 'store') {
                cloneChat = payload.list;
            }
            else {
                cloneChat = [...chatList];
                cloneChat.push(payload?.message ?? '');
            }
            return {
                ...state,
                chatList: cloneChat,
                loading: false,
            };

        case actionTypes.START_CHAT_SESSION_SUCCEEDED:
            return {
                ...state,
                ...payload,
                loading: false,
            };
        default:
            return state;
    }
};
export default chatReducer;
