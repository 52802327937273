import React from "react";
import { styled } from "@mui/material/styles";
import Divider from "@mui/material/Divider";

const DividerCom = ({ color, ...props }) => {
  const backgroundColor = color ?? "#b9b6b6";
  return (
    <Divider
      variant="middle"
      sx={{ backgroundColor: backgroundColor, height: "1px" }}
      {...props}
    />
  );
};
export default DividerCom;
