import { toast } from "react-toastify";
import { call, put, takeLatest } from "redux-saga/effects";
import { cartServices } from "../../services";
import * as actionTypes from "../actionTypes";

function* placeOrder(action) {
  console.log(action.payload, "placeOrderpayload");
  const { data } = yield call(cartServices.placeOrder, action.payload);
  console.log("====================================");
  console.log("cartSaga", data);
  console.log("====================================");
  if (data.status) {
    toast.success(data.message);
    yield put({
      type: actionTypes.PLACE_ORDER_SUCCEEDED,
      payload: {
        orderDetails: data.data,
        orderPlaced: true,
      },
    });
  } else {
    toast.error(data.message);
    yield put({
      type: actionTypes.PLACE_ORDER_FAILED,
      payload: { error: data.message },
    });
  }
  console.log("qwas>", data);
}

function* cartSaga() {
  yield takeLatest(actionTypes.PLACE_ORDER_REQUESTED, placeOrder);
}

export default cartSaga;
