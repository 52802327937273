import React from "react";
import Drawer from "@mui/material/Drawer";
const SlideDrawer = ({
  showDrawer,
  toggleDrawer,
  backColor,
  children,
  ...props
}) => {
  return (
    <Drawer
      anchor={"right"}
      open={showDrawer}
      className={backColor ? "greyBg" : ""}
      sx={{
        ".MuiBackdrop-root": {
          backgroundColor: "white",
        },
        ".MuiPaper-root": {
          width: "100%",
          backgroundColor: "whitesmoke",
        },
        ".wrapper": {
          width: "100%",
          margin: "0 auto",
        },
      }}
      onClose={() => toggleDrawer(false)}
    >
      {children}
    </Drawer>
  );
};
export default SlideDrawer;
