import * as actionTypes from "../actionTypes";

const initialState = {
  displayMap: false
};

const commonReducer = (state = initialState, action) => {
  const { type, payload = {} } = action;
  switch (type) {
    case actionTypes.CLEAR_DATA:
      return {
      ...initialState,
        loading: false
      };
    case actionTypes.SET_SHOW_MAP:
      return {
        ...state,
        ...payload
      }
      
        case actionTypes.SLIDE_DIRECTION: 
        return {
          ...state, direction:payload
        }
    default:
      return state;
  }
};
export default commonReducer;
