import axios from "axios";
import { DeviceUUID } from "device-uuid";
import FingerprintJS from '@fingerprintjs/fingerprintjs-pro'

import { BASE_URL_ORDRR } from "./apiUrls";
import { store, START_LOADING, STOP_LOADING } from "../Redux";
import { getSessionStorageOrDefault, setSessionStorage } from "../helpers";

export const customAxios = axios.create({
  baseURL: BASE_URL_ORDRR,
});

console.log("BASE_URL_ORDRRrrrrrrr",BASE_URL_ORDRR);

customAxios.defaults.baseURL = BASE_URL_ORDRR;
customAxios.defaults.headers["Content-Type"] = "application/json";
// customAxios.defaults.headers["deviceid"] = new DeviceUUID().get();

customAxios.interceptors.request.use(
  async (config) => {

    // const res = await axios.get('https://geolocation-db.com/json/')
    let newDeviceId = getSessionStorageOrDefault("device_id", undefined);
    if(!newDeviceId){
      const fpPromise = FingerprintJS.load({
        apiKey: "bjqpKdV8NBZaxve6JPwz",
        region: "ap"
      })
      const fp = await fpPromise
      const result = await fp.get();
      newDeviceId = result.visitorId ?? '';
      setSessionStorage("device_id",newDeviceId);
    }
    let merchantId =  getSessionStorageOrDefault("merchant_id", "");

    !config.disableLoading && store.dispatch({ type: START_LOADING });
    let newConfig = { ...config };
    console.log('stateeeeeeee',merchantId);
    newConfig.headers.deviceid = newDeviceId;
    newConfig.headers.merchantid = merchantId;
    return newConfig;
  },
  (error) => {
    return Promise.reject(error);
  }
);

customAxios.interceptors.response.use(
  (response) => {
    const config = response?.config
    !config?.disableLoading && store.dispatch({ type: STOP_LOADING });
    if (response.status === 401 || response.data.responseCode === 401) {
      localStorage.clear();
      // store.dispatch({ type: SET_ACTIVE_NAVIGATOR, payload: 'Auth' });
      // store.dispatch({ type: LOGOUT_SUCCEEDED });
    }
    return response;
  },
  (error) => {
    const config = error?.config
    !config?.disableLoading && store.dispatch({ type: STOP_LOADING });
    if (
      error.response.status === 401 ||
      error.response.data.responseCode === 401
    ) {
      localStorage.clear();
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export const setAuthToken = (token) =>
  (customAxios.defaults.headers.AUTHTOKEN = token);
