import * as actionTypes from "../actionTypes";

export const startChatSession = ({ order_offline_id }) => ({
  type: actionTypes.START_CHAT_SESSION_REQUESTED,
  payload: { order_offline_id },
});

export const storeMessage = ({ list = [], type, message }) => ({
  type: actionTypes.STORE_MESSAGE,
  payload: {
    type,
    list,
    message,
  },
});
