import { API_URLS, customAxios } from "../config";

export const paymentIntent = (payload) =>
  customAxios
    .post(API_URLS.paymentIntent, payload)
    .catch((ex) => ({
      data: { message: "Some error occured, Please try after sometime." },
    }));
export const paymentToken = (payload) =>
  customAxios
    .post(API_URLS.paymentToken, payload)
    .catch((ex) => ({
      data: { message: "Some error occured, Please try after sometime." },
    }));
export const confirmPayment = (payload) =>
  customAxios
    .post(API_URLS.confirmPayment, payload)
    .catch((ex) => ({
      data: { message: "Some error occured, Please try after sometime." },
    }));
export const trackOrder = (payload) =>
  customAxios
    .post(API_URLS.trackStatus, payload)
    .catch((ex) => ({
      data: { message: "Some error occured, Please try after sometime." },
    }));
export const whereIsMyOrder = (payload) =>
  customAxios
    .post(API_URLS.whereIsMyOrder, payload)
    .catch((ex) => ({
      data: { message: "Some error occured, Please try after sometime." },
    }));
export const quantityCheckedDetails = (payload) =>
  customAxios
    .post(API_URLS.quantityCheckedDetails, payload)
    .catch((ex) => ({
      data: { message: "Some error occured, Please try after sometime." },
    }));

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  paymentIntent,
  paymentToken,
  confirmPayment,
  trackOrder,
  whereIsMyOrder,
  quantityCheckedDetails
};
