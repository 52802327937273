import React, { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import Header from "../Components/Header";
import UnableToLoadData from "../Components/UnableToLoadData";
import StepperProgress from "../Components/StepperProgress";
import { ellipse } from "../../Assets";
import { Base64 } from "js-base64";
import { actions } from "../../Redux";
import moment from "moment";
import {
  capitalizeFirstLetter,
  checkArray,
  checkNull,
  removeUnderScore,
} from "../../helpers";

const TrackEmailStatus = ({ prevTab }) => {
  const storeId = window.location.href.split("/")[2].split(".")[0];
  const { merchantId, orderId } = useParams();
  useEffect(() => {
    document.body.classList.add("white");
    document.body.classList.add("order-tracking");
    let root = document.getElementsByTagName( 'html' )[0];
    if(root){
      root.classList.add('white');
    }
    // document.html.classList.add("white");
    dispatch(
      actions.trackOrder(
        {
          order_offline_id: orderId,
          merchant_id: Base64.decode(merchantId),
        },
        true
      )
    );
    getStoreDetails();
    let intervalID = setInterval(() => {
      if (!trackOrder?.status[3]?.time) {
        dispatch(
          actions.trackOrder(
            {
              order_offline_id: orderId,
              merchant_id: Base64.decode(merchantId),
            },
            true
          )
        );
      }
    }, 1000 * 60);

    setIntervalID(intervalID);
    return () => {
      clearInterval(intervalID);
    };
  }, []);
  // const history = useHistory();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [intervalID, setIntervalID] = useState(null);
  const { trackOrder } = useSelector((state) => state.paymentReducer);
  const { orderDetails, deliveryType } = useSelector(
    (state) => state.cartReducer
  );

  const { outletDetails, storeDetails } = useSelector(
    (state) => state.storeReducer
  );
  const deliveryDateTime = orderDetails?.order?.deliveryTime ?? "";
  const deliveryDate = deliveryDateTime.split(" ")[0] ?? "";
  const deliveryTime = deliveryDateTime.split(" ")[1] ?? "";
  const getStoreDetails = () => dispatch(actions.getStoreDetails({ storeId }));

  useEffect(() => {
    if (storeDetails !== null && !outletDetails?.settings?.id) {
      dispatch(
        actions.getOutletDetails({
          outlet_id: Base64.decode(merchantId),
        })
      );
    }
  }, [storeDetails]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const orderObj = orderDetails?.order ?? "";

  if (trackOrder?.status[3]?.time) {
    clearInterval(intervalID);
  }

  function deliveryInfo() {
    if (deliveryType.key === "pickup") return "Pickup";
    else if (
      !outletDetails?.settings?.preferred_delivery_partner ||
      outletDetails?.settings?.preferred_delivery_partner == "0"
    )
      return "Own Delivery";
    else
      return (
        <span>
          {" "}
          Delivery Partner: <b>Lalamove</b>{" "}
        </span>
      );
  }

  const getDeliveryDetails = () => {
    let res = null;
    if (trackOrder?.delivery_details?.status === true) {
      const deliveryDetail = trackOrder?.delivery_details;
      res = (
        <>
          <div className="row mx-0">
            <div className="col-12">
              <h3 className="delivery-text">Delivery Details</h3>
            </div>
          </div>
          <div className="row mx-0 bg-white mt-2 mb-4 py-2">
            <div className="col-6">
              <div className="my-2">
                <p>Delivered By</p>
                <h3>{orderObj?.delivery_partner?.partner_name}</h3>
              </div>
            </div>
            <div className="col-6">
              <div className="my-2">
                <p>Delivery Status</p>
                <h3>
                  {capitalizeFirstLetter(
                    removeUnderScore(deliveryDetail?.order_status)
                  )}
                </h3>
              </div>
            </div>
            <div className="col-6">
              <div className="my-2">
                <p>Rider Contact</p>
                <h3 className="">
                  {checkNull(deliveryDetail?.data?.name, false)
                    ? `${deliveryDetail?.data?.name} (${deliveryDetail?.data?.phone})`
                    : "N/A"}
                </h3>
              </div>
            </div>
            <div className="col-6 d-flex align-items-end">
              <div className="my-2">
                <a
                  href={deliveryDetail?.shareLink}
                  target="_blank"
                  className="btn btn-track-order"
                  style={{
                    backgroundColor: outletDetails?.settings?.brand_main_color,
                    "&:hover": {
                      backgroundColor:
                        outletDetails?.settings?.brand_main_color,
                    },
                  }}
                >
                  Track rider
                </a>
              </div>
            </div>
          </div>
        </>
      );
    }
    return res;
  };
  console.log("Asdsadasdasd:", storeDetails);
  return storeDetails ? (
    <div className="wrapper colorGreyBg trackStatusScreen">
      <Header
        title={"Order Tracking"}
        // backLink={backLink}
        backName={"Back to Confirmation"}
      />
      {getDeliveryDetails()}
      <section className="user-section mx-0 new-user-sec-height">
        <div className="TrackStatus">
          <div className="col-12">
            <p className="sm-title font-normal px-0 mb-1">
              <b>
                {deliveryType?.key == "delivery"
                  ? `Requested delivery on ${moment(deliveryDateTime).format(
                      "Do MMM"
                    )} at ${moment(deliveryDateTime).format("h:mm A")}`
                  : `Pickup scheduled for  ${moment(deliveryDateTime).format(
                      "Do MMM"
                    )} at ${moment(deliveryDateTime).format("h:mm A")}`}
              </b>
            </p>
          </div>
          <div className="col-12 statusTracking">
            <StepperProgress
              steps={trackOrder?.status || []}
              checkKey={"name"}
              type="trackStatus"
            />
          </div>
        </div>
        <div className="bottom-sec new-bottom-help">
          {/* <div className="col-12 my-0">
            <p className="sm-title">{deliveryInfo()}</p>
          </div> */}
          <div className="col-12">
            <span
              className="anchor_link bolder"
              style={{ color: outletDetails?.settings?.brand_highlight_color }}
              onClick={() => navigate("/help")}
            >
              HELP
            </span>
          </div>
        </div>
      </section>
    </div>
  ) : (
    <UnableToLoadData
      title="Order Tracking"
      text="Please wait while we fetch your order status and tracking information."
    />
  );
};
export default TrackEmailStatus;
