import moment from "moment";

function roundTimeQuarterHour(time, bufferTime = 15) {
  var timeToReturn = new Date(time);

  timeToReturn.setMilliseconds(
    Math.round(timeToReturn.getMilliseconds() / 1000) * 1000
  );
  timeToReturn.setSeconds(Math.round(timeToReturn.getSeconds() / 60) * 60);
  timeToReturn.setMinutes(
    Math.round(timeToReturn.getMinutes() / bufferTime) * bufferTime
  );
  return timeToReturn;
}

function roundDateTimeObj(time, bufferTime = 15) {
  const current_time =
    time.getMinutes() % 5 === 0 ? time.getMinutes() - 5 : time.getMinutes();
  //   const current_time = time.minute();
  if (time.getMinutes() % 5 === 0) {
    return time;
  } else {
    const remainder = bufferTime - (current_time % bufferTime);
    // console.log(
    //   "tesssssggggroundDateTimeObj",
    //   bufferTime,
    //   current_time,
    //   current_time % bufferTime,
    //   time.getMinutes(),
    //   remainder
    // );
    time.setMinutes(
      time.getMinutes() + remainder
    );
    return time;
  }
}

function roundMomentTime(time, bufferTime = 15) {
  const current_time =
    time.minute() % 5 === 0 ? time.minute() - 5 : time.minute();
  //   const current_time = time.minute();
  if (time.minute() % 5 === 0) {
    return moment(time);
  } else {
    // console.log(
    //   "tesssssgggg",
    //   current_time,
    //   time.minute(),
    //   current_time % bufferTime,
    //   bufferTime
    // );
    const remainder = bufferTime - (current_time % bufferTime);
    const dateTime = moment(time).add(remainder, "minutes");
    return dateTime;
  }
}

export { roundTimeQuarterHour, roundMomentTime, roundDateTimeObj };
