import React, { useEffect, useMemo, useRef, useState } from "react";
// import { useHistory } from "react-router-dom";
import { useNavigate, NavLink } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { DeviceUUID } from "device-uuid";
import { useSwipeable, config } from "react-swipeable";
import { Motion, spring } from "react-motion";
import IntersectionVisible from "react-intersection-visible";
import InfiniteScroll from "react-infinite-scroll-component";
import { Button } from "@mui/material";
import { Element } from "react-scroll";
import ImageTag from "../Components/ImageTag";
import { actions } from "../../Redux";
import { decode } from "../../Utils/Decrypt";
import Collapse from "@mui/material/Collapse";
import { Colors } from "../../config/constants";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import IsVisible from "react-is-visible";
import {
  checkArray,
  checkItemAvailable,
  checkSubCatAvailable,
  checkVoucherAvailable,
  deepCopy,
  getServerImgUrl,
  pluckArray,
  checkNull,
  scrollToDiv,
  howMuchPercentage,
  percentage,
  parseRound,
  sumOfPromos,
  checkIfRepricePromo,
  getRandomString,
  getCartBody,
  cartChangesHandler,
} from "../../helpers";

import Badge from "@mui/material/Badge";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";

import PopupModal from "../Components/PopupModal";
import {
  emptyEditedItemDetail,
  getSelectedItemDetail,
} from "../../Redux/actions";
import { useCallback } from "react";
import cartImg from "../../Assets/images/cartImg.svg";
import bellImg from "../../Assets/images/bellImg.svg";
import searchIcon from "../../Assets/images/search_icon.svg";
import filterImg from "../../Assets/images/filter.png";
import close_dark from "../../Assets/images/close_dark.png";
import { StyledSVG } from "../../Utils/style";
import moment from "moment";
import Cart from "./Cart";
import AddItem from "./AddItem";
import AddBundleItem from "./AddBundleItem";
import ProgressBar from "../Components/ProgressBar";
import { getDistance } from "../../Utils/distance";
import Delivery from "./Delivery";
import DeliveryInfo from "./DeliveryInfo";
const Home = (params) => {
  // toolbar vh
  const [innerWindowHeight, setInnerWindowHeight] = useState(
    window.innerHeight
  );
  const [isPageLoaded, setIsPageLoaded] = useState(false);
  const [isLaptopScreen, setIsLaptopScreen] = useState(false);
  const [isStickyHeader, setIsStickyHeader] = useState(false);

  const [showAddItemPopUp, setShowAddItemPopUp] = useState(false);
  const [showBundleItemPopUp, setShowBundleItemPopUp] = useState(false);
  const [changePropAddressModal, setChangePropAddressModal] = useState(false);

  const closeChangePropAddressModal = useCallback((value) => {
    setChangePropAddressModal(value);
  }, []);

  const toggleAddItemPopUp = useCallback((value = false) => {
    setShowAddItemPopUp(value);
  }, []);
  const toggleBundleItemPopUp = useCallback((value = false) => {
    setShowBundleItemPopUp(value);
  }, []);

  const calcToolbarStatus = () => {
    const outerHeight = window.outerHeight;
    const innerHeight = window.innerHeight;
    const diff = outerHeight - innerHeight;
    console.log(innerHeight, outerHeight, diff, "aaaaaaaaaaaaaaaaaaaaaa");
    if (diff > 120) return true;
    else return false;
  };

  useEffect(() => {
    const rootEl = document.getElementById("root");
    console.log(rootEl, "rootEl");
    if (calcToolbarStatus()) rootEl.classList.remove("toolbar-vh");
    else rootEl.classList.add("toolbar-vh");
  }, [innerWindowHeight]);

  const checkWindowWidth = () => {
    if (window.innerWidth > 768) {
      setIsLaptopScreen(true);
      // dispatch(actions.getQuotations(test));
    } else {
      setIsLaptopScreen(false);
    }
  };

  useEffect(() => {
    let resizeListner;
    let scrollListner;
    checkWindowWidth();
    resizeListner = window.addEventListener("resize", function (event) {
      setInnerWindowHeight(window.innerHeight);
      checkWindowWidth();
    });

    scrollListner = window.addEventListener("scroll", (event) => {
      console.log("window.scrollY", window.scrollY);
      if (window.scrollY > 120) {
        setIsStickyHeader(true);
      } else {
        setIsStickyHeader(false);
      }
    });

    setTimeout(() => {
      setIsPageLoaded(true);
    }, 1000);

    return () => {
      window.removeEventListener("resize", resizeListner);
      window.removeEventListener("scroll", scrollListner);
    };
  }, []);

  function updateSize() {
    if (window.innerWidth > 768) {
      document.body.classList.add("laptop-device");
      const slideRouteCols = document.getElementsByClassName("slide-routes");
      if (slideRouteCols?.[0]) {
        slideRouteCols?.[0].classList.add("overflow-visible");
      }
      document.body.classList.add("overflow-visible");
    } else {
      document.body.classList.remove("laptop-device");
      const slideRouteCols = document.getElementsByClassName("slide-routes");
      if (slideRouteCols?.[0]) {
        slideRouteCols?.[0].classList.remove("overflow-visible");
      }
      document.body.classList.remove("overflow-visible");
    }
    // setSize([window.innerWidth, window.innerHeight]);
  }

  useEffect(() => {
    updateSize();
  }, [showAddItemPopUp, showBundleItemPopUp]);

  //store scroll position
  const menuSections = document.querySelector(".menu-sections");
  useEffect(() => {
    // console.log("scrollPosition", scrollPosition);
    const scrollPosition = sessionStorage.getItem("scrollPosition");
    menuSections?.scrollBy(0, scrollPosition);
  }, [menuSections]);

  const {
    menuItems,
    outletDetails,
    search_input,
    selectedItem,
    filters,
    filter_tag_detail,
    storeDetails,
  } = useSelector((state) => state.storeReducer);
  const currentMechantTime = moment(new Date())
    .utc()
    .utcOffset(storeDetails?.Merchant?.timezone_offset);
  const selectedItemId = selectedItem?.sub_category_id || 0;
  // console.log('====================================');
  // console.log(selectedItemId);
  // console.log('====================================');
  const searchInputContainer = useRef(null);
  const { tip: userTip, totalDiscount } = useSelector(
    (state) => state.cartReducer.cartBasicDetails
  );
  const {
    cartItems,
    deliveryType,
    cart_promocode,
    cart_promo_discount: userDiscount,
    promotionCode: appliedPromo,
  } = useSelector((state) => state.cartReducer);
  const cart_detail = deepCopy(cartItems);

  const salesTaxPer = parseFloat(menuItems?.SalesTaxRate ?? 0);

  const { quotationId, priceBreakdown } = useSelector(
    (state) => state.quotationReducer
  );

  const deliveryCharge = priceBreakdown?.total;

  // cart states

  const defaultTotalCartAmount = () => {
    const total =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;

    return total > 0 ? total : 0;
  };

  function getBasketCartTotalAmount(onlyTotal = false) {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      // const totalDisCh = parseFloat(onlyTotal ? 0 : totalDiscount);
      const totalDisCh = userDiscount;
      res =
        totalCartAmount +
        totalServiceCharge +
        userTip +
        deliveryFee +
        totalSaleTax -
        totalDisCh;
    }

    if (onlyTotal) {
      return res;
    }
    return (
      <strong>
        <small>{`${outletDetails.currency_symbol ?? ""}`}</small>
        {parseRound(res)}
      </strong>
    );
  }
  function getBasketTotalDisAmount(onlyTotal = false) {
    let res = 0;
    if (cart_detail && cart_detail.length > 0) {
      const totalDisCh = userDiscount;
      res =
        totalDisCartAmount +
        totalServiceCharge +
        userTip +
        deliveryFee +
        totalSaleTax -
        totalDisCh;
    }

    if (onlyTotal) {
      return res;
    }
    return (
      <strong>
        <small>{`${outletDetails.currency_symbol ?? ""}`}</small>
        {parseRound(res)}
      </strong>
    );
  }

  const defaultSubTotal = () => {
    return cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current?.total);
        }, 0)
      : 0;
  };

  const {
    locations,
    selectedLocation,
    selectedStore,
    deliveryDate,
    deliveryTime,
  } = useSelector((state) => state.deliveryOptionsReducer);

  const getSubsidyCharge = () => {
    let res = 0;
    if (
      deliveryType?.key == "delivery" &&
      checkArray(selectedStore?.SubsidyThreshold)
    ) {
      // const netSales = totalCartAmount + promoDiscount;
      const netSales = totalCartAmount - userDiscount;
      selectedStore?.SubsidyThreshold.sort(
        (a, b) => a.net_sales_threshold - b.net_sales_threshold
      ).map((subsidyObj) => {
        if (netSales >= parseFloat(subsidyObj?.net_sales_threshold)) {
          res = parseFloat(subsidyObj?.delivery_subsidy);
        }
      });
    }
    return res;
  };

  const getSubsidyMoreText = () => {
    let res = null;
    if (
      deliveryType?.key == "delivery" &&
      checkArray(selectedStore?.SubsidyThreshold) &&
      deliveryFee > 0
    ) {
      const copyThreshold = deepCopy(selectedStore?.SubsidyThreshold);
      // const netSales = totalCartAmount + promoDiscount;
      const netSales = totalCartAmount - userDiscount;
      let needMoreVal = 0;
      let getOffVal = 0;
      let TotalNeedVal = 0;
      copyThreshold
        .sort((a, b) => b.net_sales_threshold - a.net_sales_threshold)
        .map((subsidyObj) => {
          if (netSales < parseFloat(subsidyObj?.net_sales_threshold)) {
            needMoreVal = parseRound(
              parseFloat(subsidyObj?.net_sales_threshold) - netSales
            );
            TotalNeedVal = parseFloat(subsidyObj?.net_sales_threshold);
            getOffVal = parseRound(parseFloat(subsidyObj?.delivery_subsidy));
          }
        });
      const completePer =
        netSales > 0 && TotalNeedVal > 0
          ? howMuchPercentage(netSales, TotalNeedVal)
          : 0;
      if (parseFloat(needMoreVal) > 0) {
        res = (
          <div className="p-padding">
            <p className="mb-1">
              <b className="font-weight-bolder text-dark">
                Add {`${outletDetails.currency_symbol ?? ""}${needMoreVal}`}
              </b>{" "}
              more to get{" "}
              <b className="font-weight-bolder text-dark">
                {`${outletDetails.currency_symbol ?? ""}${getOffVal}`} OFF
              </b>{" "}
              on delivery fee
            </p>
            <ProgressBar
              key={
                totalCartAmount + userDiscount > 0
                  ? totalCartAmount + userDiscount
                  : getRandomString()
              }
              endValue={completePer}
              color={outletDetails?.settings?.brand_main_color}
            />
          </div>
        );
      }
    }
    return res;
  };

  const deliveryCharges = () => {
    const delivery_fee_charged = outletDetails?.settings?.delivery_fee_charged;
    const fixed_fee = outletDetails?.settings?.fixed_fee;
    const cost_per_km = outletDetails?.settings?.cost_per_km;
    const delivery_fee_basis = outletDetails?.settings?.delivery_fee_basis;
    if (delivery_fee_charged === "yes") {
      if (delivery_fee_basis === "1") {
        return parseFloat(fixed_fee);
      } else if (delivery_fee_basis === "2") {
        cost_per_km = +cost_per_km;
        let distance = Math.ceil(
          getDistance(
            [outletDetails?.latitude, outletDetails?.longitude],
            [selectedLocation?.latitude, selectedLocation?.longitude]
          ) / 1000
        );
        console.log("abcdefgh", cost_per_km, distance);
        return parseFloat(distance * cost_per_km);
      }
      return 0;
    } else return 0;
  };
  // ////////////////////
  //  calculate delivery time
  const enable_delivery_partner =
    outletDetails?.settings?.enable_delivery_partner;
  const preferred_delivery_partner =
    outletDetails?.settings?.preferred_delivery_partner;

  //  calculate Delivery fee
  function getDeliveryFee(withSubsidy = true) {
    let deliveryFee = 0;
    const subsidyCharge = withSubsidy ? getSubsidyCharge() : 0;
    if (deliveryType?.key == "delivery") {
      if (enable_delivery_partner === "yes") {
        if (
          preferred_delivery_partner === "0" ||
          preferred_delivery_partner === null
        ) {
          deliveryFee = deliveryCharges();
        } else {
          deliveryFee = deliveryCharge;
        }
      } else {
        deliveryFee = deliveryCharges();
      }
    }
    let finalDeliveryFee = +deliveryFee || 0;
    if (subsidyCharge > finalDeliveryFee) {
      finalDeliveryFee = 0;
    } else {
      finalDeliveryFee = finalDeliveryFee - subsidyCharge;
    }
    return finalDeliveryFee;
  }
  ///////////////////////////
  let defaultDiscountTotalCartAmount = () =>
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseFloat(current.total * current.quantity);
        }, 0)
      : 0;
  const defaultServiceCharge = () =>
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_service_charge == true)
            .reduce(function (sum, current) {
              return sum + parseFloat(current.service_charge_amount);
            }, 0)
        )
      : 0;
  const defaultTotalDisCartAmount =
    cart_detail && cart_detail.length > 0
      ? cart_detail.reduce(function (sum, current) {
          return sum + parseRound(current.total ?? 0, 2, true);
        }, 0)
      : 0;
  const defaultTotalDisSaleTax =
    cart_detail && cart_detail.length > 0
      ? parseFloat(
          cart_detail
            .filter((cartItem) => cartItem.is_sales_tax == true)
            .reduce(function (sum, current) {
              return sum + parseRound(current.sales_tax_amount, 2, true);
            }, 0)
        )
      : 0;

  const calTotalSaleTax = () => {
    let totalSaleItm =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.sales_tax_amount);
              }, 0)
          )
        : 0;
    const totalcartItm =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;
    // console.log("ratioDisss0000", checkArray(cart_promocode), userDiscount);
    if (checkArray(cart_promocode) && userDiscount > 0) {
      const ratioDis = howMuchPercentage(
        totalcartItm - userDiscount,
        totalcartItm
      );
      if (ratioDis > 0) {
        const disTotalSaleRatio = percentage(100 - ratioDis, totalSaleItm);
        // console.log("ratioDisss1111", ratioDis,disTotalSaleRatio,totalSaleItm);
        totalSaleItm = totalSaleItm - disTotalSaleRatio;
      }
      // console.log("ratioDisss2222", ratioDis,totalSaleItm);
    }
    return totalSaleItm;
  };

  const [isCartChanged, setIsCartChanged] = useState(false);
  const [showSubmittedItem, setShowSubmittedItem] = useState(false);
  const [totalCartAmount, setTotalCartAmount] = useState(
    defaultTotalCartAmount()
  );
  const [totalServiceCharge, setTotalServiceCharge] = useState(
    defaultServiceCharge()
  );
  const [totalSaleTax, setTotalSaleTax] = useState(calTotalSaleTax());
  const [deliveryFee, setDeliveryFee] = useState(getDeliveryFee());
  const [deliveryFeeWoSubsidy, setDeliveryFeeWoSubsidy] = useState(
    getDeliveryFee(false)
  );

  // fordisplay
  const [totalDisCartAmount, setTotalDisCartAmount] = useState(
    defaultTotalDisCartAmount
  );
  const [totalDisSaleTax, setTotalDisSaleTax] = useState(
    defaultTotalDisSaleTax
  );

  const [totalTip, setTotalTip] = useState("");
  const [promoDiscount, setPromoDiscount] = useState(0);

  useEffect(() => {
    setDeliveryFee(getDeliveryFee());
    setDeliveryFeeWoSubsidy(getDeliveryFee(false));
  }, [
    deliveryType,
    enable_delivery_partner,
    preferred_delivery_partner,
    selectedLocation,
    totalCartAmount,
    userDiscount,
    quotationId,
  ]);

  useEffect(() => {
    const newTotalCartAmount =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseFloat(current.total);
          }, 0)
        : 0;
    const newTotalDisCartAmount =
      cart_detail && cart_detail.length > 0
        ? cart_detail.reduce(function (sum, current) {
            return sum + parseRound(current.total, 2, true);
          }, 0)
        : 0;
    const newServiceCharge =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_service_charge == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.service_charge_amount);
              }, 0)
          )
        : 0;
    const newTotalSaleTax =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseFloat(current.sales_tax_amount);
              }, 0)
          )
        : 0;
    const newTotalDisSaleTax =
      cart_detail && cart_detail.length > 0
        ? parseFloat(
            cart_detail
              .filter((cartItem) => cartItem.is_sales_tax == true)
              .reduce(function (sum, current) {
                return sum + parseRound(current.sales_tax_amount, 2, true);
              }, 0)
          )
        : 0;
    let defaultPromoDiscount = 0;
    // promotion discount
    if (
      cart_detail &&
      checkArray(
        cart_detail.filter(
          (p) =>
            checkArray(p?.promotions) && !checkIfRepricePromo(p?.promotions)
        )
      )
    ) {
      cart_detail
        .filter(
          (p) =>
            checkArray(p?.promotions) && !checkIfRepricePromo(p?.promotions)
        )
        .map((item) => {
          defaultPromoDiscount =
            defaultPromoDiscount +
            sumOfPromos(item?.promotions, "discountPerItem") *
              parseInt(item?.quantity ?? 0);
        });
    }
    // promotion discount END
    setPromoDiscount(defaultPromoDiscount);
    setTotalSaleTax(calTotalSaleTax());
    setTotalServiceCharge(newServiceCharge);
    setTotalCartAmount(newTotalCartAmount);
    setTotalDisCartAmount(newTotalDisCartAmount);
    setTotalDisSaleTax(newTotalDisSaleTax);
  }, [isCartChanged, cart_detail, showAddItemPopUp, showBundleItemPopUp]);

  function removeItemHandler(index) {
    const itemIndx = cart_detail.findIndex((p) => p.offline_id == index);
    if (itemIndx > -1) {
      dispatch(actions.removeCartItem({ index: itemIndx }));
      dispatch(
        actions.finalCartSave({
          cart_promocode: [],
          cart_promo_discount: 0,
          promotionCode: "",
        })
      );
    }
    setIsCartChanged(!isCartChanged);
  }

  const increaseItem = (index) => {
    const itemIndx = cart_detail.findIndex((p) => p.offline_id == index);
    if (itemIndx > -1) {
      const newItem = { ...cart_detail[itemIndx] };
      newItem.quantity = newItem.quantity + 1;
      newItem.total = newItem.amount * newItem.quantity;
      const serviceChargeAmount = 0;
      const salesTaxAmount =
        newItem?.menu_price?.sales_tax == "1"
          ? parseFloat(
              ((newItem.total + serviceChargeAmount) * salesTaxPer) / 100
            )
          : 0;
      newItem.sales_tax_amount = salesTaxAmount;
      dispatch(
        actions.updateCartItem({
          index: itemIndx,
          item: newItem,
          isToast: false,
        })
      );
    }
    setIsCartChanged(!isCartChanged);
  };

  const decreaseItem = (index) => {
    const itemIndx = cart_detail.findIndex((p) => p.offline_id == index);
    if (itemIndx > -1) {
      const newItem = { ...cart_detail[itemIndx] };
      if (newItem.quantity - 1 > 0) {
        newItem.quantity = newItem.quantity - 1;
        newItem.total = newItem.amount * newItem.quantity;
        const serviceChargeAmount = 0;
        const salesTaxAmount = newItem?.is_sales_tax
          ? parseFloat(
              ((newItem.total + serviceChargeAmount) * salesTaxPer) / 100
            )
          : 0;
        newItem.sales_tax_amount = salesTaxAmount;
        dispatch(
          actions.updateCartItem({
            index: itemIndx,
            item: newItem,
            isToast: false,
          })
        );
        setIsCartChanged(!isCartChanged);
      } else {
        removeItemHandler(index);
      }
    }
  };

  function orderPlaceHandler() {
    cartChangesHandler({
      cart_detail,
      menuItems,
      getBasketCartTotalAmount,
      userTip,
      cart_promocode,
      userDiscount,
      promoDiscount,
      totalTip,
      dispatch,
      actions,
      deliveryCharges,
      deliveryType,
      selectedLocation,
      deliveryDate,
      deliveryTime,
      totalSaleTax,
      totalServiceCharge,
      totalCartAmount,
      getBasketTotalDisAmount,
      totalDisCartAmount,
      getDeliveryFee,
      getSubsidyCharge,
    });
    togglePaymentPopUp();
    // navigate("/delivery");
  }

  // cart states END

  // pull data from redux for quotation
 
  const dateFormat = "yyyy/MM/DD";
  const currentDate = moment();
  const deliveryDateTime = `${deliveryDate} ${deliveryTime}`;

  const arr = window.location.href.split("/");
  const dispatch = useDispatch();

  const currency = outletDetails?.currency ? outletDetails?.currency + " " : "";
  const currencySymbol = outletDetails?.currency_symbol
    ? outletDetails?.currency_symbol + " "
    : "";

  const {
    longitude: storeLong,
    latitude: storeLat,
    address: storeAddress,
    address_2: storeAddressTwo,
    city_id: cityId,
    zip_code: zipCode,
  } = outletDetails;
  const totalItems = cart_detail.reduce((acc, curr) => acc + curr.quantity, 0);

  const deliveryLong = selectedLocation?.longitude;
  const deliveryLat = selectedLocation?.latitude;
  const deliveryAddress =
    selectedLocation?.street_address +
    " " +
    selectedLocation?.unit_number +
    " " +
    selectedLocation?.city +
    " " +
    selectedLocation?.zip_code;
  const quotationBody = {
    data: {
      // scheduleAt: currentMechantTime.isBefore(checkScheduleTime)
      //   ? checkScheduleTime.format().slice(0, -6) +
      //     `${storeDetails?.Merchant?.timezone_offset}`
      //   : "",
      serviceType: "MOTORCYCLE",
      // specialRequests: ["PURCHASE_SERVICE_1"],
      language: "en_SG",
      stops: [
        {
          coordinates: {
            lat: `${storeLat}`,
            lng: `${storeLong}`,
          },
          address: `${storeAddress}${
            checkNull(storeAddressTwo, false) ? " " + storeAddressTwo : ""
          } ${cityId} ${zipCode}`,
        },
        {
          coordinates: {
            lat: `${deliveryLat}`,
            lng: `${deliveryLong}`,
          },
          address: deliveryAddress,
        },
      ],
      item: {
        quantity: `0`,
        weight: "LESS_THAN_3KG",
        categories: ["FOOD_DELIVERY"],
        // categories: ["FOOD_DELIVERY", "OFFICE_ITEM"],
        // handlingInstructions: ["KEEP_UPRIGHT", "FRAGILE"],
      },
      isRouteOptimized: true,
      // "cashOnDelivery": {
      //     "amount": "100"
      // }
    },
  };

  useEffect(() => {
    if (totalItems > 0 && isLaptopScreen && !checkNull(quotationId, false)) {
      quotationBody.data.item.quantity = `${totalItems}`;
      console.log("inasdsadsad");
      if (
        deliveryType.key === "delivery" &&
        outletDetails?.settings?.enable_delivery_partner === "yes" &&
        !(
          outletDetails?.settings?.preferred_delivery_partner === "0" ||
          outletDetails?.settings?.preferred_delivery_partner === null
        )
      ) {
        dispatch(actions.getQuotations(quotationBody));
      }
    }
  }, [totalItems, selectedLocation]);

  // useEffect(() => {
  //   // analytics
  //   if (
  //     deliveryType &&
  //     checkNull(storeDetails?.Merchant?.account_name) &&
  //     checkArray(locations)
  //   ) {
  //     // console.log("tesdfsdfdsf:",locations?.[0]?.customer_id);
  //     window.hj("identify", locations?.[0]?.customer_id, {
  //       action: deliveryType.key === "delivery" ? "Delivery" : "Pickup", // Send null when no value exists for a user.
  //       merchant_id: storeDetails?.Merchant?.id, // Send null when no value exists for a user.
  //       account_name: storeDetails?.Merchant?.account_name, // Send null when no value exists for a user.
  //     });
  //   }
  // }, [storeDetails?.Merchant?.account_name, locations, deliveryType]);

  useEffect(() => {
    if (menuItems === null) {
      dispatch(
        actions.getMenuItems({
          merchant_id: decode(arr[arr.length - 1]),
          customer_id: 1,
          date: moment(deliveryDate).format("yyyy-MM-DD"),
          time: deliveryTime,
          outlet_id: decode(arr[arr.length - 1]),
          navigate,
        })
      );
      // dispatch(
      //   actions.getOutletDetails({
      //     outlet_id: decode(arr[arr.length - 1]),
      //   })
      // );
    }
  }, []);

  ///
  // const filter_tag_detail = null; // remove
  // const SubCategory = menuItems?.MenuItems ?? []; // remove
  const [SubCategory, setSubCategory] = useState(menuItems?.MenuItems || []);
  const subCategoryLength = SubCategory?.length; // remove

  const [promotionArr, setPromotionArr] = useState([]);
  const [promotionDisplayArr, setPromotionDisplayArr] = useState([]);

  const [selectedSubcat, setSelectedSubcat] = useState(null);
  const [highLightSubcat, setHighLightSubcat] = useState(null);
  const [Categories, setCategories] = useState(menuItems?.MenuItems || []);
  
  const [selectedSubcatArr, setSelectedSubcatArr] = useState([]);
  const selectedSubcatArrRef = useRef(selectedSubcatArr);
  selectedSubcatArrRef.current = selectedSubcatArr;

  const [searchText, setSearchText] = useState(search_input);
  const [showFilterCon, setShowFilterCon] = useState(false);
  const [applyFilters, setApplyFilters] = useState(filters);

  const [showCartPage, setShowCartPage] = useState(false);
  const [slideCartPage, setSlideCartPage] = useState(false);

  const [showAddItemPage, setShowAddItemPage] = useState(false);
  const [slideAddItemPage, setSlideAddItemPage] = useState(false);

  const [showBundleItemPage, setShowBundleItemPage] = useState(false);
  const [slideBundleItemPage, setSlideBundleItemPage] = useState(false);

  const [showPaymentPage, setShowPaymentPage] = useState(false);
  const [slidePaymentPage, setSlidePaymentPage] = useState(false);

  const isFilterOrSearchApplied =
    (filters && filters !== "") || (search_input && search_input !== "")
      ? true
      : false;

  //header scrolling effect
  const [hideHeader, setHideHeader] = useState(false);
  const [isHeaderAllUp, setIsHeaderAllUp] = useState(true);
  // infinite scroller
  const [hasMore, setHasMore] = useState(false);
  const [sliceHasMore, setSliceHasMore] = useState(3);

  useEffect(() => {
    const subItems = menuItems?.MenuItems ?? [];
    let newCategories = [...subItems];
    let newSubCategories = [...subItems];
    console.log(Categories, "CategoriesCategories");
    console.log("promotionDisplayArr", promotionDisplayArr);
    if (
      checkArray(promotionDisplayArr) &&
      newCategories.findIndex((p) => p.name === "Promotions") === -1 &&
      !isFilterOrSearchApplied
    ) {
      newCategories = [{ id: "1", name: "Promotions" }, ...newCategories];
    }
    if (isFilterOrSearchApplied) {
      newCategories = newCategories.filter((SubCat) =>
        filterSubCatTableDetails(SubCat)
      );
      newSubCategories = newSubCategories.filter((SubCat) =>
        filterSubCatTableDetails(SubCat)
      );
    }
    setCategories(newCategories);
    setSubCategory(newSubCategories);
  }, [menuItems, promotionArr, promotionDisplayArr, search_input, filters]);

  const toggleCartPopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showCartPage) {
        setSlideCartPage(false);
        setTimeout(() => {
          setShowCartPage(!showCartPage);
        }, 500);
      } else {
        setShowCartPage(!showCartPage);
        setTimeout(() => {
          setSlideCartPage(true);
        }, 100);
      }
    },
    [slideCartPage, showCartPage]
  );
  const togglePaymentPopUp = useCallback(() => {
    if (showPaymentPage) {
      setSlidePaymentPage(false);
      setTimeout(() => {
        setShowPaymentPage(!showPaymentPage);
      }, 500);
    } else {
      setShowPaymentPage(!showPaymentPage);
      setTimeout(() => {
        setSlidePaymentPage(true);
      }, 100);
    }
  }, [slidePaymentPage, showPaymentPage]);

  const toggleAddItemPagePopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showAddItemPage) {
        setSlideAddItemPage(false);
        setTimeout(() => {
          setShowAddItemPage(!showAddItemPage);
        }, 500);
      } else {
        setShowAddItemPage(!showAddItemPage);
        setTimeout(() => {
          setSlideAddItemPage(true);
        }, 100);
      }
    },
    [slideAddItemPage, showAddItemPage]
  );

  const toggleBundleItemPagePopUp = useCallback(
    (event) => {
      // event.preventDefault();
      if (showBundleItemPage) {
        setSlideBundleItemPage(false);
        setTimeout(() => {
          setShowBundleItemPage(!showBundleItemPage);
        }, 500);
      } else {
        setShowBundleItemPage(!showBundleItemPage);
        setTimeout(() => {
          setSlideBundleItemPage(true);
        }, 100);
      }
    },
    [slideBundleItemPage, showBundleItemPage]
  );

  function checkItemHasTag(item) {
    let res = filters && filters !== "" ? false : true;
    const filterArr = filters && filters !== "" ? filters.split(",") : [];
    const applyFilterArr =
      filterArr.length > 0 && filter_tag_detail && filter_tag_detail !== ""
        ? filter_tag_detail.filter((item) =>
            filterArr.includes(item.id.toString())
          )
        : [];
    const filterSymbols =
      applyFilterArr.length > 0
        ? applyFilterArr
            .filter((p) => !p.name.includes("Contains"))
            .map((filter) => {
              return `${filter.id}`;
            })
        : [];
    const containfilterSymbols =
      applyFilterArr.length > 0
        ? applyFilterArr
            .filter((p) => p.name.includes("Contains"))
            .map((filter) => {
              return `${filter.id}`;
            })
        : [];

    if (
      containfilterSymbols.length > 0 &&
      filterSymbols.length == 0 &&
      item?.allergy_tags?.length == 0
    ) {
      res = true;
    } else if (res == false && item?.allergy_tags?.length > 0) {
      const allergies = item.allergy_tags;
      for (let i = 0; i < allergies.length; i++) {
        const element = allergies[i];
        if (
          containfilterSymbols.length > 0 &&
          containfilterSymbols.includes(`${element}`)
        ) {
          res = false;
          break;
        } else if (
          filterSymbols.includes(`${element}`) ||
          (filterSymbols.length == 0 &&
            containfilterSymbols.length > 0 &&
            !containfilterSymbols.includes(`${element}`))
        ) {
          res = true;
          if (containfilterSymbols.length == 0) break;
        }
      }
    }
    return res;
  }

  function checkItemHasMatchingModifiers(item) {
    let res = true;
    if (item && search_input && search_input !== "") {
      res = false;
      const searchInputText = search_input.toLowerCase();
      if (item?.menu_item_name.toLowerCase().indexOf(searchInputText) > -1) {
        res = true;
      } else if (
        item?.PosMenuitemsModifier &&
        item?.PosMenuitemsModifier.length > 0 &&
        item?.PosMenuitemsModifier.findIndex(
          (p) =>
            p?.Recipe?.recipe_name?.toLowerCase().indexOf(searchInputText) >
              -1 ||
            p?.Ingredient?.ingredient_name
              ?.toLowerCase()
              .indexOf(searchInputText) > -1
        ) > -1
      ) {
        res = true;
      } else if (
        // description
        item?.description &&
        item?.description.toLowerCase().indexOf(searchInputText) > -1
      ) {
        res = true;
      }
    }
    return res;
  }

  function checkItemHasMatch(subCat, item) {
    let res = true;
    if (filters && filters !== "" && search_input && search_input !== "") {
      res = checkItemHasMatchingModifiers(item) && checkItemHasTag(item);
      if (res) console.log("item:", item?.menu_item_name, res);
    } else if (filters && filters !== "") {
      res = checkItemHasTag(item);
    } else if (search_input && search_input !== "") {
      res =
        checkItemHasMatchingModifiers(item) ||
        subCat?.name.toLowerCase().indexOf(search_input.toLowerCase()) > -1;
    }
    return res;
  }

  function checkPromoItemHasMatch(item) {
    let res = true;
    if (filters && filters !== "" && search_input && search_input !== "") {
      res = checkItemHasMatchingModifiers(item) && checkItemHasTag(item);
      if (res) console.log("item:", item?.menu_item_name, res);
    } else if (filters && filters !== "") {
      res = checkItemHasTag(item);
    } else if (search_input && search_input !== "") {
      res =
        checkItemHasMatchingModifiers(item) ||
        "promotions".indexOf(search_input.toLowerCase()) > -1;
    }
    return res;
  }

  function filterSubCatTableDetails(subCat) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      menuItems.Merchant &&
      SubCategory &&
      SubCategory.length > 0 &&
      subCat?.MenuItem
    ) {
      return (
        subCat?.MenuItem.findIndex(
          (p) =>
            checkItemHasMatch(subCat, p) &&
            checkItemAvailable(
              p,
              menuItems?.Merchant?.timezone_offset ?? "00:00",
              menuItems,
              deliveryDateTime
            )
        ) > -1 ||
        (search_input &&
          search_input !== "" &&
          !(filters && filters !== "") &&
          subCat?.name.toLowerCase().indexOf(search_input.toLowerCase()) > -1)
      );
    }
    return true;
  }
  function filterPromoCatTableDetails() {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      menuItems.Merchant
    ) {
      // hide when search and filter use
      return false;
      // hide when search and filter use END

      return (
        promotionArr.findIndex((p) => checkPromoItemHasMatch(p)) > -1 ||
        (search_input &&
          search_input !== "" &&
          !(filters && filters !== "") &&
          "promotion".toLowerCase().indexOf(search_input.toLowerCase()) > -1)
      );
    }
    return true;
  }

  function filterItemTableDetails(subCat, item) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      menuItems.Merchant &&
      SubCategory &&
      SubCategory.length > 0
    ) {
      return checkItemHasMatch(subCat, item);
    }
    return true;
  }

  function filterPromoItemTableDetails(item) {
    if (
      ((filters && filters !== "") || (search_input && search_input !== "")) &&
      menuItems.Merchant
    ) {
      // hide when search and filter use
      return false;
      // hide when search and filter use END
      return checkPromoItemHasMatch(item);
    }
    return true;
  }

  const makePromotionItem = (promotion) => {
    let copyPromotionArr = [];
    const isValidVoucher = checkVoucherAvailable(
      promotion,
      menuItems?.Merchant?.timezone_offset ?? "00:00",
      deliveryDateTime
    );
    // console.log("menuItemsPromotions2222", promotion?.MenuItem?.length, isValidVoucher)
    if (promotion?.MenuItem?.length > 0 && isValidVoucher) {
      promotion.MenuItem.map((itemKey, promoItemIndex) => {
        const promoSubKey = menuItems?.MenuItems?.findIndex((p) => {
          let promRes = false;
          const subIdCheck =
            itemKey?.categorisation == "SubCategory"
              ? itemKey?.sub_category_id
              : itemKey?.sub_class_id;
          if (p.id == subIdCheck) {
            promRes = true;
          }
          return promRes;
        });
        // const promoSubKey = menuItems?.MenuItems?.findIndex(
        //   (p) => p.id == itemKey?.sub_category_id
        // );
        const promoItemKey = menuItems?.MenuItems?.[
          promoSubKey
        ]?.MenuItem.findIndex((p) => p.id == itemKey?.id);
        if (
          promoSubKey > -1 &&
          checkSubCatAvailable(
            menuItems?.MenuItems?.[promoSubKey],
            menuItems,
            deliveryDateTime
          ) &&
          menuItems?.MenuItems?.[promoSubKey]?.MenuItem?.[promoItemKey]
        ) {
          const promoItem = deepCopy(
            menuItems?.MenuItems?.[promoSubKey]?.MenuItem?.[promoItemKey]
          );
          const acutalPrice = parseFloat(
            promoItem?.menu_price?.item_price ?? 0
          );
          let promoPrice = 0;
          let discountPrice = 0;
          if (promotion.type == "Amount") {
            discountPrice = parseFloat(promotion.amount);
            promoPrice =
              acutalPrice - discountPrice >= 0
                ? acutalPrice - discountPrice
                : 0;
          } else if (promotion.type == "Percentage") {
            discountPrice = (acutalPrice * parseFloat(promotion.amount)) / 100;
            promoPrice =
              acutalPrice - discountPrice >= 0
                ? acutalPrice - discountPrice
                : 0;
          } else if (promotion.type == "Re-Price") {
            discountPrice = parseFloat(promotion.amount);
            promoPrice = discountPrice;
          }
          if (promoItem.menu_price) {
            promoItem.menu_price.item_price = promoPrice;
          }
          promoItem.promotions = {
            id: promotion.id,
            type: promotion.type,
            code: promotion.code,
            name: promotion.promotion_name,
            auto_apply: promotion.auto_apply,
            acutalPrice: acutalPrice,
            promotionTotaldiscount: discountPrice,
            amount: promotion.amount,
            discountPerItem: parseFloat(discountPrice),
            combo_allowed: promotion.combo_allowed,
            isLoyaltyVoucher:
              promotion?.loyalty_only_promotion == "1" ? "yes" : "no",
            voucherName: "",
          };
          copyPromotionArr.push(promoItem);
        }
      });
    }
    return copyPromotionArr;
  };

  const increaseSubItems = (add) => {
    setSliceHasMore(add);
    // setScrollerItems(SubCategory?.slice(0, add));
  };

  const fetchMoreData = () => {
    // if (scrollerItems?.length === subCategoryLength) {
    //   setHasMore(false);
    //   return;
    // }
    increaseSubItems(sliceHasMore + 3);
  };

  // for no of cart items
  const getItemTotalQuantity = (item, type) => {
    let res = 0;
    let cartItemKey;
    if (type === "promotion") {
      cartItemKey =
        cartItems && cartItems.length > 0 && item?.id
          ? cartItems.findIndex(
              (p) => p.id == item.id && p.promotions?.id == item.promotions?.id
            )
          : -1;
    } else {
      cartItemKey =
        cartItems && cartItems.length > 0 && item?.id
          ? cartItems.findIndex((p) =>
              p?.promotions?.id
                ? p.id == item.id && p.promotions?.id == item.promotions?.id
                : p.id == item.id
            )
          : -1;
    }
    if (cartItemKey !== -1) {
      res = cartItems
        .filter((cartItem) => cartItem.id == item.id)
        .reduce(function (sum, current) {
          return sum + parseInt(current.quantity);
        }, 0);
    }
    return res > 0 ? (
      <span
        className="txt-pink"
        style={{ color: outletDetails?.settings?.brand_highlight_color }}
      >
        x{res}
      </span>
    ) : null;
  };

  // bottom total bar
  function getBasketTotalQuantity() {
    let qty = 0;
    cartItems.forEach((each) => {
      qty += each.quantity;
    });
    return (
      <div
        className="items-count"
        style={{ backgroundColor: outletDetails?.settings?.brand_main_color }}
      >
        {" "}
        {qty}{" "}
      </div>
    );
  }

  // bottom total bar
  function getBasketTotalAmount() {
    let total = 0;
    cartItems.forEach((each) => {
      total += each.amount * each.quantity;
    });

    return (
      <>
        <small>{`${currencySymbol ?? ""}`}</small>
        {total.toFixed(2)}
      </>
    );
  }

  // show popularity tags
  function getItemTags(item) {
    let res = null;
    if (item?.is_popular && item?.is_popular === 1) {
      res = <span className="tag">Popular</span>;
    } else if (item?.is_trending && item?.is_trending === 1) {
      res = <span className="tag blue">Trending</span>;
    }
    return res;
  }

  function addFilter(id) {
    if (id !== undefined) {
      id = id.toString();
      let applyFiltersArr = applyFilters === "" ? [] : applyFilters.split(",");
      const checkIndex = applyFiltersArr.findIndex((p) => p == id);
      if (checkIndex === -1) {
        applyFiltersArr.push(id);
      } else {
        applyFiltersArr.splice(checkIndex, 1);
      }
      setApplyFilters(applyFiltersArr.join());
    }
  }

  function countActiveFilter() {
    let res = null;
    let applyFiltersArr = filters === "" ? [] : filters.split(",");
    if (applyFiltersArr.length > 0) {
      res = <span className="count">{applyFiltersArr.length}</span>;
    }
    return res;
  }

  function checkActiveFilter(id) {
    let res = false;
    if (id !== undefined) {
      id = id.toString();
      let applyFiltersArr = applyFilters === "" ? [] : applyFilters.split(",");
      const checkIndex = applyFiltersArr.findIndex((p) => p == id);
      if (checkIndex != -1) {
        res = true;
      }
    }
    return res;
  }

  function getFilterModalTags() {
    let res = null;
    if (filter_tag_detail && filter_tag_detail.length > 0) {
      res = (
        <ul>
          {filter_tag_detail.map(function (tag, index) {
            const checkActive = checkActiveFilter(tag.id);
            let style = {
              border: `2px solid ${tag?.color ?? "#000"}`,
              color: `${tag?.color ?? "#000"}`,
            };
            if (checkActive) {
              style.background = tag?.color ?? "";
              style.color = "#fff";
            }
            return (
              <li style={style} key={index} onClick={() => addFilter(tag.id)}>
                {tag?.name ? tag?.name.replace("Contains", "No") : ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  // show
  function getItemFilterTags(item) {
    let res = null;
    if (item?.allergy_tags && item?.allergy_tags.length > 0) {
      res = (
        <ul className={`${isLaptopScreen ? "allery_con" : ""}`}>
          {item?.allergy_tags.map(function (tag, index) {
            const filterTagKey =
              filter_tag_detail && filter_tag_detail.length > 0
                ? filter_tag_detail.findIndex((p) => p.id == tag)
                : null;
            const filterTag =
              filterTagKey !== null && filterTagKey !== undefined
                ? filter_tag_detail[filterTagKey]
                : null;
            const style =
              filterTag?.color && filterTag?.color !== ""
                ? { backgroundColor: filterTag?.color ?? "" }
                : {};
            const className =
              filterTag?.color && filterTag?.color !== "" ? "" : "gray";
            return (
              <li style={style} className={className} key={index}>
                {filterTag?.symbol ?? ""}
              </li>
            );
          })}
        </ul>
      );
    }
    return res;
  }

  const handlers = useSwipeable({
    onSwiped: (eventData) => {
      if (!isLaptopScreen) {
        if (
          hideHeader == true &&
          eventData.dir == "Down" &&
          isHeaderAllUp === true
        ) {
          setHideHeader(false);
        }
        if (hideHeader == false && eventData.dir == "Up") {
          setHideHeader(true);
        }
      }
    },
    ...config,
  });

  function scrolledElement(element) {
    const headerPageOffset = element.target?.scrollTop;
    const headerPageScrollHeight = element.target?.scrollHeight;
    const headerPageClientHeight =
      element.target?.parentNode?.parentNode?.clientHeight;
    sessionStorage.setItem("scrollPosition", headerPageOffset);
    if (!isLaptopScreen) {
      if (
        headerPageOffset &&
        headerPageOffset > 50 &&
        headerPageScrollHeight > headerPageClientHeight - 168 &&
        hideHeader === false
      ) {
        setHideHeader(true);
      } else if (headerPageOffset === 0 && hideHeader === true) {
        setHideHeader(false);
      }
      if (headerPageOffset === 0 && hideHeader === true) {
        setIsHeaderAllUp(true);
      }
      if (headerPageOffset > 0 && isHeaderAllUp === true) {
        setIsHeaderAllUp(false);
      }
    }
  }

  // const history = useHistory();
  const navigate = useNavigate();
  const deviceid = new DeviceUUID().get();

  useEffect(() => {
    document.body.classList.remove("white");
  }, []);
  useEffect(() => {
    if (SubCategory?.length > 0) {
      increaseSubItems(3);
    }
  }, [SubCategory]);

  useEffect(() => {
    getPromotionItemDetails();
    // makeReorderItems();
  }, [menuItems]);

  useEffect(() => {
    let newPromoDisplayArr = [];
    if (
      checkArray(promotionArr) &&
      menuItems?.PromotionalItems !== null &&
      checkArray(Object.keys(menuItems?.PromotionalItems))
    ) {
      const copyPromotionArr = deepCopy(promotionArr);
      Object.keys(menuItems?.PromotionalItems).map((promoInx) => {
        const promoMenuIds = menuItems?.PromotionalItems[promoInx];
        if (checkArray(promoMenuIds)) {
          promoMenuIds.map((promoMenuId) => {
            const promomenuIndex = copyPromotionArr.findIndex(
              (p) => p.id == promoMenuId && p.promotions.id == promoInx
            );
            if (promomenuIndex > -1) {
              const promoItem = copyPromotionArr[promomenuIndex];
              if (
                filterPromoItemTableDetails(promoItem) &&
                checkItemAvailable(
                  promoItem,
                  menuItems?.Merchant?.timezone_offset ?? "00:00",
                  menuItems,
                  deliveryDateTime
                )
              ) {
                newPromoDisplayArr.push(promoItem);
              }
            }
          });
        }
      });
    } else {
      const copyPromotionArr = deepCopy(promotionArr);
      newPromoDisplayArr = copyPromotionArr.filter(
        (promoItem) =>
          filterPromoItemTableDetails(promoItem) &&
          checkItemAvailable(
            promoItem,
            menuItems?.Merchant?.timezone_offset ?? "00:00",
            menuItems,
            deliveryDateTime
          )
      );
    }
    setPromotionDisplayArr(newPromoDisplayArr);
  }, [promotionArr]);

  useEffect(() => {
    if (selectedSubcatArrRef.current) {
      let targetSub = selectedSubcatArrRef.current.slice(0, 1).shift();
      if (
        subCategoryLength > 2 &&
        selectedSubcatArrRef.current.includes(SubCategory[0].id)
      ) {
        targetSub = SubCategory[0].id;
      } else if (
        subCategoryLength > 2 &&
        selectedSubcatArrRef.current.includes(
          SubCategory[subCategoryLength - 1].id
        )
      ) {
        targetSub = SubCategory[subCategoryLength - 1].id;
      }
      console.log("targetSubbbbbb", targetSub);
      if (
        isLaptopScreen &&
        document.getElementById(`head_sub_${targetSub}`) &&
        selectedSubcat === null &&
        isPageLoaded
      ) {
        const platform =
          navigator?.userAgentData?.platform ||
          navigator?.platform ||
          "unknown";
        setHighLightSubcat(targetSub);
        // document.querySelector(`#head_sub_${targetSub}`).scrollIntoView();

        let scrollOp = {
          behavior: "auto",
          inline: "center",
        };
        if (
          navigator.maxTouchPoints &&
          navigator.maxTouchPoints > 2 &&
          /MacIntel/.test(platform)
        ) {
          scrollOp = {
            behavior: "smooth",
            block: "nearest",
            inline: "center",
          };
        }
        document
          .querySelector(`#head_sub_${targetSub}`)
          .scrollIntoView(scrollOp);
        /* issue with scrolling */
        // setSelectedSubcat(targetSub);
        // document.querySelector(`#head_sub_${targetSub}`).scrollIntoView();
      } else if (
        !isLaptopScreen &&
        document.getElementById(`head_sub_${targetSub}`)
      ) {
        setSelectedSubcat(targetSub);
        document.getElementById(`head_sub_${targetSub}`).scrollIntoView({
          behavior: "auto",
          block: "center",
          inline: "center",
        });
      }
    }
  }, [selectedSubcatArr]);

  const scrollToSubDiv = (subId, additionHeight = 185) => {
    const targetEl = document.getElementById(`sub_${subId}`);
    console.log("testtttasdvasd", targetEl.offsetTop, additionHeight);
    const targetDiv = document.getElementById("home-wrapper");
    window.scrollTo({
      left: 0,
      top: targetEl.offsetTop + additionHeight,
      behavior: "smooth",
    });
  };

  const subCatClickHandler = (subId, clicked = false) => {
    const subIndex = SubCategory.findIndex((p) => p.id == subId);

    if (subIndex > -1 && sliceHasMore < subIndex) {
      increaseSubItems(subIndex + 2);
    }
    console.log("====================================");
    console.log("i got clicked", subId);
    console.log("====================================");
    setSelectedSubcat(subId);
    document.querySelector(`#head_sub_${subId}`).scrollIntoView({
      behavior: "auto",
      block: "center",
      inline: "center",
    });
    setTimeout(() => {
      if (document.getElementById(`sub_${subId}`)) {
        if (isLaptopScreen) {
          scrollToSubDiv(subId);
        } else {
          document.getElementById(`sub_${subId}`).scrollIntoView({
            behavior: "auto",
            block: "start",
          });
        }
      }
    }, 300);
    if (isLaptopScreen) {
      setTimeout(() => {
        setHighLightSubcat(subId);
        setSelectedSubcat(null);
      }, 1000);
    }
  };
  const getPromotionItemDetails = () => {
    let copyPromotionArr = [];
    if (
      menuItems?.Promotions &&
      menuItems?.Promotions.filter(
        (p) => p?.auto_apply === "Yes" && p?.qualification_type == "item"
      ).length > 0
    ) {
      const copyPromotions = deepCopy(
        menuItems?.Promotions.filter(
          (p) => p?.auto_apply === "Yes" && p?.qualification_type == "item"
        )
      );

      copyPromotions.map((promotion, promoKey) => {
        const newProArr = makePromotionItem(promotion);
        if (newProArr.length > 0) {
          copyPromotionArr = [...copyPromotionArr, ...newProArr];
        }
      });
    }
    // if (user_detail?.promotions && user_detail?.promotions.length > 0) {
    //   const copyCopounPromotions = deepCopy(user_detail?.promotions);
    //   copyCopounPromotions.map((promotion, promoKey) => {
    //     const newProArr = makePromotionItem(promotion);
    //     if (newProArr.length > 0) {
    //       copyPromotionArr = [...copyPromotionArr, ...newProArr];
    //     }
    //   });
    // }
    setPromotionArr(copyPromotionArr);
  };

  function getItemDiv(item, itemKey, type) {
    let cartItemKey;
    if (type === "promotion") {
      cartItemKey =
        cartItems && cartItems.length > 0 && item?.id
          ? cartItems.findIndex(
              (p) => p.id == item.id && p.promotions?.id == item.promotions?.id
            )
          : -1;
    } else {
      const checkAutoPay =
        promotionArr.length > 0 && item?.promotions?.id === undefined
          ? promotionArr.findIndex(
              (p) => p.id == item.id && p?.promotions?.auto_apply == "Yes"
            )
          : -1;
      if (checkAutoPay > -1) {
        item = promotionArr[checkAutoPay];
      }
      cartItemKey =
        cartItems && cartItems.length > 0 && item?.id
          ? cartItems.findIndex((p) =>
              p?.promotions?.id
                ? p.id == item.id && p.promotions?.id == item.promotions?.id
                : p.id == item.id
            )
          : -1;
    }
    const isPromotion = item?.promotions?.id ? true : false;

    const checkAvailable =
      item?.is_86_item == "false" ||
      currentDate.format(dateFormat) != moment(deliveryDate).format(dateFormat)
        ? true
        : false;
    const checkValidImg = /\.(jpe?g|png|gif|bmp)$/i.test(item?.image);
    return (
      <div
        className={`main-box-new menu-box br-1${
          isLaptopScreen ? " col-6" : ""
        }${cartItemKey !== -1 ? " active" : ""}${
          checkAvailable === false ? " disabled" : ""
        }`}
        key={itemKey}
        id={`element_${
          item?.id !== undefined ? "item_id_" + item?.id : "null"
        }`}
      >
        {cartItemKey !== -1 ? (
          <div
            className="side-bar"
            style={{
              backgroundColor: outletDetails?.settings?.brand_main_color,
            }}
          />
        ) : null}
        <div
          onClick={() => {
            if (checkAvailable) {
              getSelectedItemDetail(item);
              emptyEditedItemDetail();
              console.log("====================================");
              console.log("fhejefhjefb");
              console.log("====================================");

              dispatch(
                actions.selectedItem({
                  selectedItem: item,
                })
              );
              dispatch(actions.slideDirection("vertical-slide"));
              const menuType = item.menu_item_type;
              if (isLaptopScreen) {
                if (menuType === "bundled") {
                  toggleBundleItemPopUp(true);
                } else {
                  toggleAddItemPopUp(true);
                }
              } else {
                if (menuType === "bundled") {
                  toggleBundleItemPagePopUp();
                } else {
                  toggleAddItemPagePopUp();
                }
                // navigate(
                //   `/${menuType === "bundled" ? "addBundleItem" : "addItem"}/${
                //     arr[arr.length - 1]
                //   }`
                // );
              }
            }
          }}
          className="row mx-0 align-items-center"
          style={{
            border:
              isLaptopScreen && cartItemKey !== -1
                ? `1px solid ${outletDetails?.settings?.brand_main_color}`
                : isLaptopScreen
                ? "1px solid #DDDDDD"
                : "none",
          }}
        >
          {checkValidImg && (
            <figure className="col-3 col-md-3 col-lg-4 col-xl-3 menu-img phones">
              <ImageTag
                src={getServerImgUrl(item?.image)}
                defaultimage={menuItems?.Merchant?.logo}
                removeImage={true}
                removecol={true}
                replacecolname={"col-12"}
                removecolname={"col-7"}
              />
            </figure>
          )}

          <div
            className={`${
              checkValidImg ? "col-9 col-md-9 col-lg-8 col-xl-9" : "col-12 px-0"
            }  menu-content`}
          >
            <h4>
              {getItemTotalQuantity(item, type)} {item?.menu_item_name}
            </h4>
            <p> {item?.description}</p>
            <div className="label_row">
              <p
                className={`pricing ${
                  isPromotion ? "strike-off" : ""
                } d-flex align-items-baseline`}
              >
                {" "}
                <small>{`${currencySymbol ?? ""}`}</small>
                {isPromotion
                  ? item?.promotions?.acutalPrice
                    ? parseFloat(item?.promotions?.acutalPrice).toFixed(2)
                    : "0.00"
                  : item?.menu_price?.item_price
                  ? parseFloat(item?.menu_price?.item_price).toFixed(2)
                  : "0.00"}
              </p>
              {isPromotion && (
                <p
                  className="ml-2 promotion-price d-flex align-items-baseline"
                  style={{
                    color: outletDetails?.settings?.brand_highlight_color,
                  }}
                >
                  <small>{`${currencySymbol ?? ""}`}</small>
                  {item?.menu_price?.item_price
                    ? parseFloat(item?.menu_price?.item_price).toFixed(2)
                    : "0.00"}
                </p>
              )}
              {!isLaptopScreen && getItemFilterTags(item)}
            </div>
          </div>
          {isLaptopScreen && getItemFilterTags(item)}
        </div>
      </div>
    );
  }

  const getMenuCon = (subCat) => {
    return subCat?.MenuItem?.filter(
      (item) =>
        filterItemTableDetails(subCat, item) &&
        checkItemAvailable(
          item,
          menuItems?.Merchant?.timezone_offset ?? "00:00",
          menuItems,
          deliveryDateTime
        )
    ).map((item, itemIndex) => {
      const _index = promotionArr.findIndex((el) => el.id === item.id);
      const isPromotion = _index !== -1;
      let updatedItem = isPromotion ? promotionArr[_index] : item;
      // item?.promotions?.id ? true : false;
      const checkAvailable =
        updatedItem?.is_86_item == "false" ||
        currentDate.format(dateFormat) !=
          moment(deliveryDate).format(dateFormat)
          ? true
          : false;
      const checkValidImg = /\.(jpe?g|png|gif|bmp)$/i.test(updatedItem?.image);
      let cartItemKey =
        cartItems && cartItems.length > 0 && updatedItem?.id
          ? cartItems.findIndex((p) =>
              p?.promotions?.id
                ? p.id == updatedItem.id &&
                  p.promotions?.id == updatedItem.promotions?.id
                : p.id == updatedItem.id
            )
          : -1;
      return (
        <IsVisible once>
          {(isVisible) => (
            <div
              onClick={() => {}}
              className={`main-box-new menu-box${
                item?.is_popular || item?.is_trending ? " check-popular" : ""
              }${isLaptopScreen ? " col-6" : ""}${
                cartItemKey !== -1 ? " active" : ""
              }${checkAvailable === false ? " disabled" : ""}`}
              key={updatedItem?.id}
            >
              {cartItemKey !== -1 ? (
                <div
                  className="side-bar"
                  style={{
                    backgroundColor: outletDetails?.settings?.brand_main_color,
                  }}
                />
              ) : null}
              {getItemTags(updatedItem)}
              <div
                onClick={() => {
                  if (checkAvailable) {
                    console.log("====================================");
                    console.log("i am hereee");
                    dispatch(actions.slideDirection("vertical-slide"));
                    console.log("====================================");
                    dispatch(
                      actions.selectedItem({
                        selectedItem: updatedItem,
                      })
                    );
                    console.log(item, "kkkkk");
                    const menuType = item.menu_item_type;
                    if (isLaptopScreen) {
                      if (menuType === "bundled") {
                        toggleBundleItemPopUp(true);
                      } else {
                        toggleAddItemPopUp(true);
                      }
                    } else {
                      if (menuType === "bundled") {
                        toggleBundleItemPagePopUp();
                      } else {
                        toggleAddItemPagePopUp();
                      }
                      // navigate(
                      //   `/${
                      //     menuType === "bundled" ? "addBundleItem" : "addItem"
                      //   }/${arr[arr.length - 1]}`
                      // );
                    }
                  }
                }}
                className="row mx-0 align-items-center"
                style={{
                  border:
                    isLaptopScreen && cartItemKey !== -1
                      ? `1px solid ${outletDetails?.settings?.brand_main_color}`
                      : isLaptopScreen
                      ? "1px solid #DDDDDD"
                      : "none",
                }}
              >
                {checkValidImg && isVisible && (
                  <figure className="col-3 col-md-3 col-lg-4 col-xl-3 menu-img phones">
                    <ImageTag
                      src={getServerImgUrl(updatedItem?.image)}
                      defaultimage={outletDetails?.logo}
                      removeImage={true}
                      removeCol={true}
                      replacecolname={"col-12"}
                      removecolname={"col-9"}
                    />
                  </figure>
                )}
                <div
                  className={`${
                    checkValidImg
                      ? "col-9 col-md-9 col-lg-8 col-xl-9"
                      : "col-12 px-0"
                  }  menu-content`}
                >
                  <h4>
                    {getItemTotalQuantity(updatedItem, "")}{" "}
                    {updatedItem?.menu_item_name}
                  </h4>
                  <p> {item?.description}</p>
                  <div className="label_row">
                    <p
                      className={`pricing ${
                        isPromotion
                          ? "strike-off d-flex align-items-baseline"
                          : ""
                      }`}
                    >
                      {" "}
                      <small>{`${currencySymbol ?? ""}`}</small>
                      {isPromotion
                        ? updatedItem?.promotions?.acutalPrice
                          ? parseFloat(
                              updatedItem?.promotions?.acutalPrice
                            ).toFixed(2)
                          : "0.00"
                        : updatedItem?.menu_price?.item_price
                        ? parseFloat(
                            updatedItem?.menu_price?.item_price
                          ).toFixed(2)
                        : "0.00"}
                    </p>
                    {isPromotion && (
                      <p
                        className="ml-2 promotion-price d-flex align-items-baseline"
                        style={{
                          color: outletDetails?.settings?.brand_highlight_color,
                        }}
                      >
                        <small>{`${currencySymbol ?? ""}`}</small>
                        {updatedItem?.menu_price?.item_price
                          ? parseFloat(
                              updatedItem?.menu_price?.item_price
                            ).toFixed(2)
                          : "0.00"}
                      </p>
                    )}
                    {!isLaptopScreen && getItemFilterTags(updatedItem)}
                  </div>
                </div>
                {isLaptopScreen && getItemFilterTags(updatedItem)}
              </div>
            </div>
          )}
        </IsVisible>
      );
    });
  };
  const HorizontalScroller = useCallback(() => {
    let horizonTabs = Categories?.filter(
      (SubCat) =>
        checkSubCatAvailable(SubCat, menuItems, deliveryDateTime) ||
        SubCat?.name === "Promotions"
    ).map((subCat, subCatIndex) => {
      const selected = selectedSubcat == subCat?.id;
      if (highLightSubcat == subCat?.id) {
        console.log(
          "selectedSubcattttyyyyy22222:",
          selectedSubcat,
          highLightSubcat
        );
      }
      return (
        <div
          className={`col link-box ${
            selected ||
            (subCatIndex === 0 &&
              selectedSubcat === null &&
              highLightSubcat == null) ||
            (selectedSubcat === null && highLightSubcat == subCat?.id)
              ? "active"
              : ""
          }${subCat?.name === "Promotions" ? " promo-text" : ""}`}
          id={`head_sub_${subCat?.id}`}
          key={subCat?.id}
        >
          <Motion
            defaultStyle={{ h: 65 }}
            style={{
              h: spring(hideHeader === false ? 65 : 35, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ h }) => {
              const backStyle =
                subCat?.images && !isLaptopScreen
                  ? `url(${getServerImgUrl(
                      subCat?.images
                    )})  no-repeat center center / cover`
                  : outletDetails?.settings?.brand_main_color;
              const backClass =
                subCat?.images && !isLaptopScreen ? "overlay-show" : "";
              return (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  onClick={() => {
                    subCatClickHandler(subCat?.id, true);
                    // setSelectedSubcatArr([subCat?.id]);
                  }}
                  className={`blue ${backClass + "test"}`}
                  style={{
                    height: `${h}px`,
                    backgroundImage: backStyle,
                    backgroundColor:
                      !hideHeader && !isLaptopScreen
                        ? outletDetails?.settings?.brand_main_color
                        : selected ||
                          (subCatIndex === 0 &&
                            selectedSubcat === null &&
                            highLightSubcat == null) ||
                          (selectedSubcat === null &&
                            highLightSubcat == subCat?.id)
                        ? outletDetails?.settings?.brand_main_color
                        : isLaptopScreen
                        ? "transparent"
                        : "#fff",
                  }}
                >
                  <span
                    style={{
                      color:
                        hideHeader || isLaptopScreen
                          ? selected ||
                            (subCatIndex === 0 &&
                              selectedSubcat === null &&
                              highLightSubcat == null) ||
                            (selectedSubcat === null &&
                              highLightSubcat == subCat?.id)
                            ? "#fff"
                            : outletDetails?.settings?.brand_main_color
                          : "#fff",
                    }}
                  >
                    {subCat?.name}
                  </span>
                </a>
              );
            }}
          </Motion>
        </div>
      );
    });
    return horizonTabs;
  }, [
    Categories,
    selectedSubcat,
    highLightSubcat,
    hideHeader,
    promotionDisplayArr,
    outletDetails?.settings?.brand_main_color,
  ]);

  const VerticallScroller = useCallback(
    () =>
      SubCategory.filter((SubCat) =>
        checkSubCatAvailable(SubCat, menuItems, deliveryDateTime)
      ).map((subCat, subIndex) => {
        return (
          <>
            <IntersectionVisible
              onShow={(e) => {
                if (!selectedSubcatArr.includes(subCat?.id)) {
                  setSelectedSubcatArr([...selectedSubcatArr, subCat?.id]);
                }
              }}
              onHide={(e) => {
                setSelectedSubcatArr(
                  selectedSubcatArr.filter((p) => p !== subCat?.id)
                );
              }}
            >
              <h3
                className={`title ${(subCat?.name).replaceAll(" ", "")}`}
                id={`sub_${subCat?.id}`}
                key={subCat?.id}
              >
                {" "}
                {subCat?.name}
                {subCat?.description && subCat?.description !== "" && (
                  <p className="bold mb-0 desSec">
                    {subCat?.description ?? ""}
                  </p>
                )}
              </h3>
            </IntersectionVisible>
            {isLaptopScreen ? (
              <div className="row plr-15">{getMenuCon(subCat)}</div>
            ) : (
              getMenuCon(subCat)
            )}
          </>
        );
      }),
    [
      SubCategory,
      arr,
      cartItems,
      currency,
      dispatch,
      getItemTotalQuantity,
      navigate,
      outletDetails?.settings?.brand_highlight_color,
      outletDetails?.logo,
      promotionArr,
      selectedSubcatArr,
    ]
  );
  const [menuState, setMenuState] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);

  function handleScroll(div, categoryId) {
    div = div.replaceAll(" ", "");
    const parentDiv = document.querySelector(".menu-row");
    const targetDiv = document.getElementById(`sub_${categoryId}`);
    if (isLaptopScreen) {
      setTimeout(() => {
        scrollToSubDiv(categoryId);
      }, 300);
    } else {
      const diff =
        targetDiv.getBoundingClientRect().top -
        parentDiv.getBoundingClientRect().bottom;

      document.querySelector(".menu-sections").scrollBy(0, diff);
      console.log(
        selectedSubcat,
        selectedSubcatArr,
        "aaa",
        document.querySelector(`#head_sub_${categoryId}`)
      );

      setSelectedSubcat(categoryId);
      document.querySelector(`#head_sub_${categoryId}`).scrollIntoView();
    }
  }
  const handleClose = () => {
    setAnchorEl(null);
  };

  const getMenuSection = () => {
    let res = (
      <div
        className={`menu-sections special-promotion`}
        id="menu-sections"
        onScroll={scrolledElement}
        {...handlers}
      >
        {checkArray(promotionDisplayArr) && filterPromoCatTableDetails() && (
          <Element
            name={"promotion_section"}
            className={`plr-150 ${isLaptopScreen ? "row plr-15" : ""}`}
          >
            <div
              className={`${
                isLaptopScreen ? "main-box-new promotion-heading " : ""
              }menu-box special-pr-0${isLaptopScreen ? " col-12 " : ""}`}
              id={"element_item_id_1"}
            >
              <h3 className="title promotions" id="sub_1">
                Promotions
              </h3>
            </div>

            {promotionDisplayArr.map(function (item, itemKey) {
              return getItemDiv(item, itemKey, "promotion");
            })}
          </Element>
        )}
        <InfiniteScroll
          dataLength={subCategoryLength || 0}
          next={() => fetchMoreData()}
          hasMore={hasMore}
          endMessage={
            <p style={{ textAlign: "center", margin: "10px 0px 30px" }}>
              {subCategoryLength ? <b></b> : null}
            </p>
          }
          scrollableTarget="menu-sections"
        >
          {SubCategory ? VerticallScroller() : null}
        </InfiniteScroll>
      </div>
    );
    return res;
  };

  return outletDetails?.settings?.brand_main_color ? (
    <>
      {console.log("reloaddddddd")}
      <div
        className={`wrapper custom_pb home-screen${
          hideHeader ? " scroll_active" : ""
        }${isLaptopScreen && showPaymentPage ? " new-class-cart" : ""}`}
        id="home-wrapper"
      >
        <Collapse in={!hideHeader}>
          <header>
            <div className="container">
              <div className="row mx-0 profile-row align-items-center justify-content-between mb-2 w-100">
                <div
                  className={`${
                    isLaptopScreen ? "col-6 col-xl-7" : "col-11"
                  } row align-items-center`}
                >
                  <div className="circle-img col col-md-1">
                    <figure className="">
                      <img src={outletDetails?.logo} alt="" />
                    </figure>
                  </div>
                  <div className="naming-sec col-9 col-md-9 px-0 lmr-65">
                    <p style={{ fontSize: "16px", color: "grey" }}>
                      WELCOME TO
                    </p>
                    <h4>{outletDetails?.account_name}</h4>
                  </div>
                </div>

                <div
                  onClick={() => {
                    if (cartItems?.length && !isLaptopScreen) {
                      dispatch(actions.slideDirection("horizontal-slide"));
                      // if (
                      //   deliveryType.key === "delivery" &&
                      //   outletDetails?.settings?.enable_delivery_partner ===
                      //     "yes" &&
                      //   !(
                      //     outletDetails?.settings
                      //       ?.preferred_delivery_partner === "0" ||
                      //     outletDetails?.settings
                      //       ?.preferred_delivery_partner === null
                      //   )
                      // )
                      //   dispatch(actions.getQuotations(test));
                      // return navigate(`/cart/${arr[arr.length - 1]}`);
                      toggleCartPopUp();
                    }
                  }}
                  className="col-1 col-xl-1 col-lg-1 col-md-1 mb-2 position-relative px-0 text-right d-med-screen"
                >
                  {/*side-card-width*/}
                  <StyledSVG
                    color={outletDetails?.settings?.brand_main_color}
                    src={cartImg}
                    width="30"
                  />
                  <div className="position-relative-m-0">
                    {cartItems && checkArray(cartItems) && (
                      <div
                        className="cartDiv"
                        style={{
                          backgroundColor:
                            outletDetails?.settings?.brand_highlight_color,
                        }}
                      >
                        <span className="cartSpan">
                          {" "}
                          {cartItems.reduce(
                            (acc, curr) => (acc += +curr.quantity),
                            0
                          )}
                        </span>
                      </div>
                    )}
                  </div>
                </div>

                <div className="col-xl-5 col-6 d-med-screen-hide">
                  <div className="row">
                    {isLaptopScreen && (
                      <div className="col-11 col-lg-10 col-xl-11">
                        {/* <div className="row justify-content-end align-items-center">
                      <div className="col-xl-1 col-lg-2 col-md-1 d-flex justify-content-lg-center px-0 col-style-1">
                        <div className="float-left">
                          <StyledSVG
                            color={outletDetails?.settings?.brand_main_color}
                            src={bellImg}
                            width="30"
                          />
                        </div>
                      </div>

                      <div className="col-xl-2 col-lg-2 col-md-3 px-0 col-style-flex">
                        <div className="breadcrumb new-bcrumb mb-0 justify-content-xl-end justify-content-md-end justify-content-lg-start px-0">
                          <ul>
                            <li>
                              {deliveryType.key === "delivery"
                                ? "Delivery"
                                : "Pickup"}
                            </li>
                          </ul>
                        </div>
                      </div>

                      <div className="col-xl-2 col-lg-3 col-md-3 px-0">
                        <div className="breadcrumb new-bcrumb mb-0 px-0 justify-content-lg-end justify-content-xl-start">
                          <ul>
                            <li className="before-border">
                              {moment(deliveryDateTime).format("hh:mma")}
                            </li>
                          </ul>
                        </div>
                      </div>

                      {selectedLocation && (
                        <div className="col-xl-2 col-lg-3 col-md-3 px-0 col-style-4">
                          <div className="breadcrumb new-bcrumb mb-0 px-0 justify-content-xl-end">
                            <ul>
                              <li className="before-border">
                                {selectedLocation?.nickname ?? ""}
                              </li>
                            </ul>
                          </div>
                        </div>
                      )}

                      {selectedLocation && (
                        <div className="col-xl-1 col-lg-1 col-md-2 px-0 mt-1">
                          <a
                            onClick={() => closeChangePropAddressModal(true)}
                            className="change-header text-right"
                            style={{
                              color:
                                outletDetails?.settings?.brand_main_color ??
                                "#39B5B4",
                            }}
                          >
                            Change
                          </a>
                        </div>
                      )}
                    </div>
                    */}

                        <div className="row align-items-center my-alignment-new">
                          <div className="col-11 col-md-9 col-lg-10 pr-0">
                            <ul className="breadcrumb mb-0 bcrum pr-0 pl-m-0">
                              <li className="breadcrumb-item">
                                <StyledSVG
                                  color={
                                    outletDetails?.settings?.brand_main_color
                                  }
                                  src={bellImg}
                                  width="30"
                                />
                              </li>
                              <li className="breadcrumb-item Delivery-icon">
                                {deliveryType.key === "delivery"
                                  ? "Delivery"
                                  : "Pickup"}
                              </li>

                              <li className="breadcrumb-item">
                                {moment(deliveryDateTime).format(
                                  "MM-DD-yyyy"
                                ) !== currentMechantTime.format("MM-DD-yyyy")
                                  ? moment(deliveryDateTime).format(
                                      "DD/MM/yyyy "
                                    )
                                  : ""}
                                {moment(deliveryDateTime).format("hh:mma")}
                              </li>

                              {selectedLocation && (
                                <li className="breadcrumb-item">
                                  {selectedLocation?.nickname ?? ""}
                                </li>
                              )}
                            </ul>
                          </div>

                          {selectedLocation && (
                            <div className="col-1 col-md-3 col-lg-1">
                              <a
                                onClick={() =>
                                  closeChangePropAddressModal(true)
                                }
                                className="change-yellow text-right"
                                style={{
                                  color:
                                    outletDetails?.settings?.brand_main_color ??
                                    "#39B5B4",
                                }}
                              >
                                Change
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    )}

                    <div
                      onClick={() => {
                        if (cartItems?.length && !isLaptopScreen) {
                          dispatch(actions.slideDirection("horizontal-slide"));
                          // if (
                          //   deliveryType.key === "delivery" &&
                          //   outletDetails?.settings?.enable_delivery_partner ===
                          //     "yes" &&
                          //   !(
                          //     outletDetails?.settings
                          //       ?.preferred_delivery_partner === "0" ||
                          //     outletDetails?.settings
                          //       ?.preferred_delivery_partner === null
                          //   )
                          // )
                          //   dispatch(actions.getQuotations(test));
                          // return navigate(`/cart/${arr[arr.length - 1]}`);
                          toggleCartPopUp();
                        }
                      }}
                      className="col-1 col-xl-1 col-lg-2 col-md-1 mb-2 position-relative px-0 text-right"
                    >
                      {/*side-card-width*/}
                      <StyledSVG
                        color={outletDetails?.settings?.brand_main_color}
                        src={cartImg}
                        width="30"
                      />
                      <div className="position-relative-m-0">
                        {cartItems && checkArray(cartItems) && (
                          <div
                            className="cartDiv"
                            style={{
                              backgroundColor:
                                outletDetails?.settings?.brand_highlight_color,
                            }}
                          >
                            <span className="cartSpan">
                              {" "}
                              {cartItems.reduce(
                                (acc, curr) => (acc += +curr.quantity),
                                0
                              )}
                            </span>
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 px-0 d-med-screen-hide">
                      <div className={`filter_row mobileOnly-f`}>
                        <span className="filter_icon">
                          <ImageTag
                            src={filterImg}
                            alt="filter"
                            onClick={() => {
                              setShowFilterCon(!showFilterCon);
                              setApplyFilters(filters);
                            }}
                          />{" "}
                          {countActiveFilter()}
                        </span>
                        <input
                          type="search"
                          className="search-bar input-text"
                          placeholder="Dishes, Ingredients, or Categories"
                          ref={searchInputContainer}
                          onKeyDown={(e) => {
                            const searchVal = e.target.value ?? "";
                            if (e.key === "Enter") {
                              searchInputContainer?.current.blur();
                              dispatch(actions.updateSearchText(searchVal));
                            }
                          }}
                          onChange={(e) => {
                            const searchVal = e.target.value ?? "";
                            if (e?.target?.value === "") {
                              searchInputContainer?.current.blur();
                              dispatch(actions.updateSearchText(""));
                              setSearchText("");
                            } else {
                              setSearchText(searchVal);
                            }
                          }}
                          value={searchText}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* <div className="row mx-0 welcome-sec d-none">
              <div className="circle-img-lg col-3">
                <figure className="">
                  <img src={userImgIcon} alt="" />
                </figure>
              </div>
              <div className="naming-sec col-9">
                <p> Welcome to </p>
                <h3> Harry's Boat Quay </h3>
                <p>
                  {" "}
                  Powered By{" "}
                  <span className="powered_logo">
                    {" "}
                    <img src={poweredLogo1PngIcon} />
                  </span>
                </p>
              </div>
              <div className="ratings-sec">
                <p> Tap to rate, slide for half star </p>
                <div className="ratings">
                  <span className="star"> </span>
                  <span className="star"> </span>
                  <span className="star"> </span>
                  <span className="star"> </span>
                  <span className="star"> </span>
                </div>
              </div>
            </div> */}

              {/*For Only Mobile Purpose Only*/}

              <div className={`row mx-0 filter_row mobileOnly-f d-med-screen`}>
                <span className="filter_icon">
                  <ImageTag
                    src={filterImg}
                    alt="filter"
                    onClick={() => {
                      setShowFilterCon(!showFilterCon);
                      setApplyFilters(filters);
                    }}
                  />{" "}
                  {countActiveFilter()}
                </span>
                <input
                  type="search"
                  className="search-bar input-text"
                  placeholder="Dishes, Ingredients, or Categories"
                  ref={searchInputContainer}
                  onKeyDown={(e) => {
                    const searchVal = e.target.value ?? "";
                    if (e.key === "Enter") {
                      searchInputContainer?.current.blur();
                      dispatch(actions.updateSearchText(searchVal));
                    }
                  }}
                  onChange={(e) => {
                    const searchVal = e.target.value ?? "";
                    if (e?.target?.value === "") {
                      searchInputContainer?.current.blur();
                      dispatch(actions.updateSearchText(""));
                      setSearchText("");
                    } else {
                      setSearchText(searchVal);
                    }
                  }}
                  value={searchText}
                />
              </div>

              {/*End For Only Mobile Purpose Only*/}
            </div>
          </header>
        </Collapse>
        <section className="middle-sec">
          <div className={`sticky-head`}>
            <div
              className={`menus_row${isLaptopScreen ? "" : " filter_row mm"}`}
            >
              <span className="filter_icon">
                <ImageTag
                  // src={filterImg}
                  src={searchIcon}
                  alt="search"
                  onClick={() => {
                    scrollToDiv("menu-sections", true);
                    console.log("tsdfasds", searchInputContainer?.current);
                    setTimeout(() => {
                      searchInputContainer?.current.focus();
                    }, 1000);
                    // setShowFilterCon(!showFilterCon);
                    // setApplyFilters(filters);
                  }}
                />{" "}
              </span>
              <div className="menu-row row mx-0">
                <div className="col-1 d-flex align-items-center filterWidth-F filterHeight">
                  <span className="filterIMG-F">
                    <ImageTag
                      src={filterImg}
                      alt="filter"
                      onClick={() => {
                        setShowFilterCon(!showFilterCon);
                        setApplyFilters(filters);
                      }}
                    />{" "}
                    {countActiveFilter()}
                  </span>
                </div>
                <div className="col d-flex overflow-auto px-0 mr-2">
                  {Categories ? HorizontalScroller() : null}
                </div>
                <div
                  className={`menu_sec col-1 px-0${
                    isLaptopScreen ? " pl-3" : ""
                  }`}
                >
                  <div
                    id="basic-button"
                    onClick={(event) => setAnchorEl(event.currentTarget)}
                  >
                    <MoreHorizIcon
                      sx={{ color: outletDetails?.settings?.brand_main_color }}
                    />
                  </div>
                  <Menu
                    id="basic-menu"
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                    MenuListProps={{
                      "aria-labelledby": "basic-button",
                    }}
                  >
                    <MenuItem>Jump to:</MenuItem>
                    {Categories?.filter((SubCat) =>
                      checkSubCatAvailable(SubCat, menuItems, deliveryDateTime)
                    ).map((category) => {
                      return (
                        <MenuItem
                          onClick={() => {
                            if (isLaptopScreen) {
                              subCatClickHandler(category?.id, true);
                            } else {
                              handleScroll(category.name, category.id);
                            }
                            handleClose();
                          }}
                          sx={{
                            color: {
                              color: outletDetails?.settings?.brand_main_color,
                            },
                          }}
                        >
                          {category.name}
                        </MenuItem>
                      );
                    })}
                  </Menu>
                </div>
              </div>
            </div>
          </div>

          {isLaptopScreen ? (
            <div className={`row mx-0 mt-new`}>
              <div className={`col-xl-9 col-lg-8 col-md-8`}>
                {getMenuSection()}
              </div>
              <div className={`col-xl-3 col-lg-4 col-md-4`}>
                <div className={`width-30 sticky`}>
                  <div className="white-bg">
                    <Cart
                      isLaptopScreen={isLaptopScreen}
                      changePropAddressModal={changePropAddressModal}
                      closeChangePropAddressModal={closeChangePropAddressModal}
                      isStickyHeader={isStickyHeader}
                      salesTaxPer={salesTaxPer}
                      totalCartAmount={totalCartAmount}
                      promoDiscount={promoDiscount}
                      showSubmittedItem={showSubmittedItem}
                      increaseItem={increaseItem}
                      decreaseItem={decreaseItem}
                      removeItemHandler={removeItemHandler}
                      totalTip={totalTip}
                      getBasketTotalAmount={getBasketCartTotalAmount}
                      getBasketTotalDisAmount={getBasketTotalDisAmount}
                      deliveryCharges={deliveryCharges}
                      getDeliveryFee={getDeliveryFee}
                      totalSaleTax={totalSaleTax}
                      totalServiceCharge={totalServiceCharge}
                      totalDisCartAmount={totalDisCartAmount}
                      getSubsidyCharge={getSubsidyCharge}
                      getSubsidyMoreText={getSubsidyMoreText}
                      enable_delivery_partner={enable_delivery_partner}
                      preferred_delivery_partner={preferred_delivery_partner}
                      setTotalTip={setTotalTip}
                      cart_detail={cart_detail}
                      deliveryFee={deliveryFee}
                      deliveryFeeWoSubsidy={deliveryFeeWoSubsidy}
                      orderPlaceHandler={orderPlaceHandler}
                    />
                  </div>
                </div>
              </div>
            </div>
          ) : (
            getMenuSection()
          )}
        </section>

        {!isLaptopScreen && (
          <section
            className={`bottom-sec m-0 ${
              cartItems?.length <= 0 ? "disabled" : ""
            }`}
          >
            <div className="sub-btn my-0 maxBtn">
              <Button
                type="button"
                className="d-flex justify-content-between font-16"
                onClick={() => {
                  if (cartItems?.length) {
                    dispatch(actions.slideDirection("horizontal-slide"));
                    // if (
                    //   deliveryType.key === "delivery" &&
                    //   outletDetails?.settings?.enable_delivery_partner ===
                    //     "yes" &&
                    //   !(
                    //     outletDetails?.settings?.preferred_delivery_partner ===
                    //       "0" ||
                    //     outletDetails?.settings?.preferred_delivery_partner ===
                    //       null
                    //   )
                    // )
                    //   dispatch(actions.getQuotations(test));
                    // return navigate(`/cart/${arr[arr.length - 1]}`);
                    toggleCartPopUp();
                  }
                }}
                style={{
                  backgroundColor: outletDetails?.settings?.brand_main_color,
                  "&:hover": {
                    backgroundColor: outletDetails?.settings?.brand_main_color,
                  },
                }}
              >
                <div className="col-3 col-md-2">
                  <div className="items-count float-left">
                    {" "}
                    {getBasketTotalQuantity()}{" "}
                  </div>
                </div>
                <div className="col-5 text-center col-md-8 phones-0">
                  <strong> View Basket </strong>
                </div>
                <div className="col-4 px-0 col-md-2">
                  <div className="total-price">
                    <strong>{getBasketTotalAmount()}</strong> Total{" "}
                  </div>
                </div>
              </Button>
            </div>
          </section>
        )}
        <div className={`filter_modal ${showFilterCon ? "show" : ""}`}>
          <div className="modal_header">
            <div className="filter_icon">
              <ImageTag src={filterImg} />
              {countActiveFilter()}
            </div>
            <h4>Filters</h4>
            <div className="close">
              <ImageTag
                src={close_dark}
                onClick={() => {
                  setApplyFilters(filters);
                  setShowFilterCon(false);
                }}
              />
            </div>
          </div>
          <div className="modal_body">
            <h5 className="title">By Tags</h5>
            {getFilterModalTags()}
            <button
              className="apply_btn"
              disabled={filters === applyFilters}
              onClick={() => {
                setShowFilterCon(false);
                dispatch(actions.updateFilterTags(applyFilters));
              }}
            >
              Apply
            </button>
          </div>
        </div>
        <PopupModal
          showDrawer={showAddItemPopUp}
          toggleDrawer={toggleAddItemPopUp}
          className="add-item-modal"
          height={"80%"}
          maxWidth={"500px"}
        >
          <AddItem
            isLaptopScreen={isLaptopScreen}
            toggleAddItemPopUp={toggleAddItemPopUp}
          />
        </PopupModal>
        <PopupModal
          showDrawer={showBundleItemPopUp}
          toggleDrawer={toggleBundleItemPopUp}
          height={"80%"}
          className="add-item-modal"
          maxWidth={"500px"}
        >
          <AddBundleItem
            isLaptopScreen={isLaptopScreen}
            toggleBundleItemPopUp={toggleBundleItemPopUp}
          />
        </PopupModal>
        {!isLaptopScreen && (
          <Motion
            defaultStyle={{ x: 100 }}
            style={{
              x: spring(slideCartPage === true ? 0 : 100, {
                precision: 1,
                stiffness: 300,
                damping: 44,
              }),
            }}
          >
            {({ x }) => {
              return showCartPage ? (
                <Cart
                  style={{
                    right: `-${x}%`,
                  }}
                  toggleCartPopUp={toggleCartPopUp}
                  isLaptopScreen={isLaptopScreen}
                  changePropAddressModal={changePropAddressModal}
                  closeChangePropAddressModal={closeChangePropAddressModal}
                  toggleAddItemPagePopUp={toggleAddItemPagePopUp}
                  toggleBundleItemPagePopUp={toggleBundleItemPagePopUp}
                  isStickyHeader={isStickyHeader}
                  salesTaxPer={salesTaxPer}
                  totalCartAmount={totalCartAmount}
                  promoDiscount={promoDiscount}
                  showSubmittedItem={showSubmittedItem}
                  increaseItem={increaseItem}
                  decreaseItem={decreaseItem}
                  removeItemHandler={removeItemHandler}
                  totalTip={totalTip}
                  getBasketTotalAmount={getBasketCartTotalAmount}
                  getBasketTotalDisAmount={getBasketTotalDisAmount}
                  deliveryCharges={deliveryCharges}
                  getDeliveryFee={getDeliveryFee}
                  totalSaleTax={totalSaleTax}
                  totalServiceCharge={totalServiceCharge}
                  totalDisCartAmount={totalDisCartAmount}
                  getSubsidyCharge={getSubsidyCharge}
                  getSubsidyMoreText={getSubsidyMoreText}
                  enable_delivery_partner={enable_delivery_partner}
                  preferred_delivery_partner={preferred_delivery_partner}
                  setTotalTip={setTotalTip}
                  cart_detail={cart_detail}
                  deliveryFee={deliveryFee}
                  deliveryFeeWoSubsidy={deliveryFeeWoSubsidy}
                  orderPlaceHandler={orderPlaceHandler}
                />
              ) : null;
            }}
          </Motion>
        )}
      </div>
      {!isLaptopScreen && (
        <Motion
          defaultStyle={{ x: 100 }}
          style={{
            x: spring(slideAddItemPage === true ? 0 : 100, {
              precision: 1,
              stiffness: 300,
              damping: 44,
            }),
          }}
        >
          {({ x }) => {
            return showAddItemPage ? (
              <AddItem
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  bottom: `-${x}%`,
                }}
                toggleAddItemPopUp={toggleAddItemPagePopUp}
                isLaptopScreen={isLaptopScreen}
              />
            ) : null;
          }}
        </Motion>
      )}
      {!isLaptopScreen && (
        <Motion
          defaultStyle={{ x: 100 }}
          style={{
            x: spring(slideBundleItemPage === true ? 0 : 100, {
              precision: 1,
              stiffness: 300,
              damping: 44,
            }),
          }}
        >
          {({ x }) => {
            return showBundleItemPage ? (
              <AddBundleItem
                style={{
                  display: "flex",
                  flexDirection: "column",
                  height: "100%",
                  bottom: `-${x}%`,
                }}
                toggleBundleItemPopUp={toggleBundleItemPagePopUp}
                isLaptopScreen={isLaptopScreen}
              />
            ) : null;
          }}
        </Motion>
      )}
      <Motion
        defaultStyle={{ x: 100 }}
        style={{
          x: spring(slidePaymentPage === true ? 0 : 100, {
            precision: 1,
            stiffness: 300,
            damping: 44,
          }),
        }}
      >
        {({ x }) => {
          return showPaymentPage ? (
            <DeliveryInfo
              style={{
                right: `-${x}%`,
              }}
              cartData={{
                togglePaymentPopUp,
                isLaptopScreen,
                changePropAddressModal,
                closeChangePropAddressModal,
                toggleAddItemPagePopUp,
                toggleBundleItemPagePopUp,
                isStickyHeader,
                salesTaxPer,
                totalCartAmount,
                promoDiscount,
                showSubmittedItem,
                increaseItem,
                decreaseItem,
                removeItemHandler,
                totalTip,
                getBasketCartTotalAmount,
                getBasketTotalDisAmount,
                deliveryCharges,
                getDeliveryFee,
                totalSaleTax,
                totalServiceCharge,
                totalDisCartAmount,
                getSubsidyCharge,
                getSubsidyMoreText,
                enable_delivery_partner,
                preferred_delivery_partner,
                setTotalTip,
                cart_detail,
                orderPlaceHandler,
                deliveryFee,
                deliveryFeeWoSubsidy,
              }}
            />
          ) : null;
        }}
      </Motion>
    </>
  ) : (
    ""
  );
};
export default Home;
