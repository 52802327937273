function encode(string) {
    return window.btoa(window.btoa(string));
}
function decode(string) {
    return window.atob(window.atob(string));
}

export {
    encode,
    decode
}
